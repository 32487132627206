import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, ToastController, ModalController, IonTabs, NavController, GestureController } from '@ionic/angular';

// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

// Services
import { NetworkService } from '../../services/network/network.service';
@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss'],
  providers: [ NetworkService ]
})
export class TabsPage implements OnInit {
  @ViewChild('tabs', { static: false }) tabs: IonTabs;
  @ViewChild('target',  { read: ElementRef }) targetElement: ElementRef;

  public networkStatus = 0;
  public homeTabActive: boolean = false;
  private lastOnStart: number = 0;
  private DOUBLE_CLICK_THRESHOLD: number = 500;

  constructor(
    private router: Router,
    private platform: Platform,
    public toastController: ToastController,
    readonly store: Store<AppState>,
    private networkService: NetworkService,
    public modal: ModalController,
    public nav: NavController,
    private gestureCtrl: GestureController
  ) { }

  ngOnInit() {
    this.platform.ready().then(() => {
      this.networkService.initializeNetworkEvents();
      const gesture = this.gestureCtrl.create({
        el: this.targetElement.nativeElement,
        gestureName: 'tap',
        threshold: 0,
        onStart: () => { this.onStart()}
      }, true);
      gesture.enable(true);
    });
    this.isAppOffline();
  }

  async onStart() {
    const now = Date.now();
    if (Math.abs(now - this.lastOnStart) <= this.DOUBLE_CLICK_THRESHOLD) {
      // let selectedTab = this.tabs.getSelected();
      // console.log('double tap: ', selectedTab);
      // TODO: Go back to root
      this.lastOnStart = 0;
    } else {
      this.lastOnStart = now;
    }
  }

  isAppOffline() {
    this.store.select(state => state.network)
    .subscribe((status) => {
      this.networkStatus = status.networkStatus;
      if (status.networkStatus) {
        this.showToaster();
      }
    });
  }

  async showToaster() {
    const toast = await this.toastController.create({
      header: 'You are offline',
      message: 'Listen to music offline in your library',
      position: 'top'
    });
    toast.present();
  }
}
