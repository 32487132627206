import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
// Ionic Native Controls
import { Platform } from '@ionic/angular';
import { Media, MediaObject } from '@awesome-cordova-plugins/media/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

import { AudioBaseProvider } from './audio.base';
import { AudioPlayProvider } from './play';
import { AudioNativeControls } from './controls';
import { isPlatformBrowser } from '@angular/common';
import { interval } from 'rxjs';

/**
 * Audio Provider -
 * audio provider is a service that maintains the
 * state of an audio file needed for the native device and components
 */
@Injectable({
    providedIn: 'root'
  })
export class AudioProvider extends AudioBaseProvider {
    audioInterval;
    constructor(
        public platform: Platform,
        public media: Media,
        readonly store: Store<AppState>,
        private audioPlayProvider: AudioPlayProvider,
        private audioNativeControls: AudioNativeControls,
        public file: File,
        @Inject(PLATFORM_ID) private platformId?: Object,
    ) {
        super(platform, media, store);

        this.store.select(state => state.shuffle)
            .subscribe((response: any) => {
                this.shuffle = response.shuffle;
            });

        this.store.select(state => state.player)
            .subscribe((response: any) => {
                if (response && response.queue !== null) {
                    this.album = response.queue;
                    this.trackId = response.currentSong.trackId;
                    this.albumPlayerType = response.currentSong.type;
                    this._actionIsPaused.next(false);
                    this.initialize(this.trackId);
                }
            });

        this.platform.ready().then(() => {
            this.audioNativeControls.$musicControlState.subscribe((actions: any) => {
                if (actions) {
                    this.musicActionEvents(actions);
                }
            });
        });
    }

    /**
     * initialize() - play audio type
     * @param trackId  - track id
     */
    initialize(trackId: string) {
        const albumTracks = this.album.tracks;
        const track = albumTracks.find(x => x._id === trackId);

        const trackUrl = (this.albumPlayerType === 'offline') ? this.getFilePath() + track.trackUrl : track.trackUrl;
        const mediaUrl = this.audioPlayProvider.getMediaUrl(trackUrl, this.albumPlayerType);

        this.currentTrackIndex = albumTracks.findIndex(t => t._id === trackId);
        this.audioPlayProvider.setAudioMedia(mediaUrl);

        this.platform.ready().then(() => {
            if (this.platform.is('cordova')) {
                this.updateNativeMusicControls();
            } else {
                this.updateWebMusicControls();
            }
        });
    }

    /**
     * play() - play audio file
     */
    public play() {
        this.audioPlayProvider.play();
        this._actionIsPaused.next(false);
    }

    /**
     * resumeTrack - resume audio file
     */
    public resumeTrack() {
       this.play();
    }

    /**
     * pauseTrack - pause audio file
     */
    public pauseTrack() {
        this.audioPlayProvider.pauseTrack();
        this.audioNativeControls.updateIsPlaying(false);
        this._actionIsPaused.next(true);
    }

    /**
     * repeatTrack
     * @param repeat - play track again after it's ended if set to
     */
    public repeatTrack(repeat: boolean) {
        this.repeat = repeat;
    }

    public release() {
        this.audioPlayProvider.release();
    }

    seek(e) {
        this.audioPlayProvider.seek(e);
    }

    /**
     * updateNativeMusicControls()
     * update native music controls for ios/android
     */
    private updateNativeMusicControls() {
        this.audioPlayProvider.$audioSource.subscribe((audio: MediaObject) => {
            if (audio) {
                // listen to status updates from audi
                this.nativeAudioStatusUpdate(audio);

                // // update elapsed time
                this.updateMusicControlElapsed(true);
                this.audioNativeControls.updateIsPlaying();
            } else {
                console.log('no media obj');
            }
        });
    }

    /**
     * nativeAudioStatusUpdate() -
     * listen for status updates on the audio obj
     */
    private nativeAudioStatusUpdate(audio: MediaObject) {
        audio.onStatusUpdate.subscribe(status => {
            const duration = audio.getDuration();

            if (status) {
                this.audioNativeControls.setNativeMusicControls(duration);
                this.setNativePlayerStatus(status);
            }

            if (status === 2) {
                this.audioNativeControls.updateIsPlaying();
                this.updateMusicControlElapsed(true);
            }

            if (status === 3) {
                this.audioNativeControls.updateIsPlaying(false);
                this.updateMusicControlElapsed(false);
                clearInterval(this.audioInterval);
            }

            if (status === 4) {
                this.audioNativeControls.updateIsPlaying(false);
                this.playNextTrack(audio);
                clearInterval(this.audioInterval);
            }
        });
    }

    playNextTrack(audio: MediaObject) {
        if (this.platform.is('android')) {
            // check if the current audio is finished (-0.001)
            audio.getCurrentPosition().then(position => {
                if (position === -0.001)  {
                    this.nextTrack();
                }
            });
        } else {
            this.nextTrack();
        }
    }

    /**
     * [NATIVE] updateMusicControlElapsed() - listen for changes for native media
     * @param audio - Media Object
     * @param playing - is playing
     */
    private updateMusicControlElapsed(playing: boolean = true) {
        interval(1000)
        .subscribe(() => {
            this.getCurrentPosition().then(position => {
                if (position > 0) {
                    const current = position.toString();
                    this.audioNativeControls.updateElapsed(current, playing);

                    // get current file position playing
                    const duration = this.getDuration();
                    this.timeRemaining.next(duration.toString());

                    this.setTimeElapsed(current);
                    this.setPercentElapsed(duration, current);
                    this.setTimeRemaining(duration, current);
                }
            });
        });
    }

    getDuration() {
        return this.audioPlayProvider.audioFile.getDuration();
    }

    getCurrentPosition():Promise<any>{
        return this.audioPlayProvider.audioFile.getCurrentPosition();
    }

    /**
     * [WEB] - updateWebMusicControls()
     * add listeners to current audio file for HTML audio
     */
    private updateWebMusicControls() {
        if (isPlatformBrowser(this.platformId)) {
            this.audioPlayProvider.$audioWebSource.subscribe((audio = Audio) => {

                audio.addEventListener('timeupdate', this.getCurrentTime, false);
                audio.addEventListener('playing', this.setPlayerStatus, false);
                audio.addEventListener('pause', this.setPlayerStatus, false);
                // audio.addEventListener('progress', this.calculatePercentLoaded, false);
                audio.addEventListener('waiting', this.setPlayerStatus, false);
                audio.addEventListener('ended', this.setPlayerStatus, false);

                audio.addEventListener('error', () => {
                    // this.toaster.pop('error', 'Sorry, there was a problem playing this track');
                });

                audio.addEventListener('ended', () => {
                    // check if repeat is enable
                    if (this.repeat) {
                        this.play();
                    } else {
                        this.nextTrack();
                    }
                });
            });
        }
    }


    /**
     * [NATIVE] musicActionEvents - listen for music control actions from device
     * @param action - MusicControls action
     */
    private musicActionEvents(action) {
        const message = JSON.parse(action).message;
        // console.log(message);
        if (message) {
            switch (message) {
                case 'music-controls-next':
                    this.nextTrack();
                    break;
                case 'music-controls-previous':
                    this.prevTrack();
                    break;
                case 'music-controls-pause':
                    this.pauseTrack();
                    break;
                case 'music-controls-play':
                    this.resumeTrack();
                    break;
                // External controls (iOS only)
                case 'music-controls-toggle-play-pause':
                    // this.toggle();
                    break;
                case 'music-controls-destroy':
                    // MusicControls.destroy(); // it's the same with or without the destroy
                    break;
                case 'music-controls-headset-unplugged':
                    this.pauseTrack();
                    break;
                case 'music-controls-headset-plugged':
                    this.resumeTrack();
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * [WEB] -  getCurrentTime()
     * get current player time for Web HTML
     */
    private getCurrentTime = () => {
        this.audioPlayProvider.$audioWebSource.subscribe((audio = Audio) => {
            const ct = audio.currentTime;
            const d = audio.duration;
            this.setTimeElapsed(ct);
            this.setPercentElapsed(d, ct);
            this.setTimeRemaining(d, ct);
        });
    }

    getFilePath() {
        if (this.platform.is('ios')) {
            return this.file.documentsDirectory;
        } else {
            return this.file.applicationStorageDirectory;
        }
    }
}
