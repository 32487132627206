import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';

// Services
import { AlbumService, UserDetailService } from '@vuulm/core';

@Component({
  selector: 'video-dialog',
  templateUrl: './video-dialog.html',
  styleUrls: ['./video-dialog.scss'],
  providers: [ UserDetailService, AlbumService]
})
export class VideoDialogComponent implements OnInit {
    @Input() videoId: string = null;
    @Input() videoInfo: any;
    videoUrl = null;
    profile: any;
    albums: any;
    constructor(
        public modal: ModalController,
        private sanitizer: DomSanitizer,
        private userDetailService: UserDetailService,
        private albumService: AlbumService
    ) {}

    ngOnInit() {
        if (this.videoInfo) {
            if (this.videoInfo.artistProfileId) {
                this.getProfile(this.videoInfo.artistProfileId);
                this.getAlbums(this.videoInfo.artistProfileId);
            }
        }
    }

    getVideoUrl(videoId) {
        const url = `https://www.youtube.com/embed/${videoId}`;
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return this.videoUrl;
    }

    dismiss() {
        this.modal.dismiss({
          dismissed: true
        });
    }

    getProfile(userid) {
        if (userid) {
          this.userDetailService.get(userid)
            .subscribe((r: any) => {
                if (r) {
                    this.profile = r;
                }
            });
        }
    }

    getAlbums(userId) {
        this.albumService
        .get({uid: userId, count: 10,  enablePaging: 1})
        .subscribe((r: any) => {
            if (r) {
                this.albums = r;
            }
        });
    }
}
