import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PipeModule } from '@vuulm/core';
import { SharedActionsModule } from '../actions/actions.module';

// Components
import { AlbumListComponent } from './album-list/album-list';
import { BannerComponent } from './banner/banner';
import { UserCategoryComponent } from './category/category';
import { LoadingComponent } from './loading/loading';
import { AlbumScrollComponent } from './scroll/scroll';
import { VideoDialogComponent } from './video-dialog/video-dialog';
import { EmptyComponent } from './empty/empty';
import { AlbumGridComponent } from './album-grid/album-grid.component';
import { ArtistsListComponent } from './artist-list/artist-list.component';

// Directives
import { ImageFadeDirective } from './image-fade/image-fade.directive';
@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    PipeModule,
    RouterModule,
    SharedActionsModule,
  ],
  declarations: [
    AlbumListComponent,
    AlbumGridComponent,
    ArtistsListComponent,
    BannerComponent,
    UserCategoryComponent,
    LoadingComponent,
    AlbumScrollComponent,
    VideoDialogComponent,
    EmptyComponent,
    ImageFadeDirective
  ],
  entryComponents: [
    VideoDialogComponent,
  ],
  exports: [
    PipeModule,
    SharedActionsModule,

    AlbumListComponent,
    AlbumGridComponent,
    ArtistsListComponent,
    BannerComponent,
    UserCategoryComponent,
    LoadingComponent,
    AlbumScrollComponent,
    VideoDialogComponent,
    EmptyComponent,
    ImageFadeDirective
  ],
  providers: [ ]
})

export class SharedCommonModule {	}
