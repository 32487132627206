import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
  } from '@angular/common/http';
import { Observable } from 'rxjs';

// Service
import { AuthService } from '@vuulm/core';

@Injectable()
export class UserTokenInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // add authorization header with token if available
      const authUser = this.authService.User.jwt;

      // set the token for the user
      if (request.url.includes('/users')) {
        // request.headers.delete('Authorization', authReq);
        if (authUser !== null) {
          request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${authUser}`
            }
          });
        }
      }

      return next.handle(request);
  }
}
