<ion-header>
    <ion-toolbar>
        <ion-title *ngIf="videoInfo">{{videoInfo.artistName}}</ion-title>
        <ion-buttons slot="end">
        </ion-buttons>
    </ion-toolbar>
  </ion-header>
   
    
<ion-content>
    <iframe width="100%" height="315" [src]="getVideoUrl(videoId)" frameborder="0" allowfullscreen></iframe>
        <div class="video-description" *ngIf="videoInfo">
            <div *ngIf="videoInfo.exclusivefeatured" class="featured">
                <span class="label">Featured</span>
            </div>
            <h3>{{ videoInfo.title }}</h3>
            <span>{{ videoInfo.publishedDate | date: 'MMMM dd, yyyy' }}</span>
            <p>{{ videoInfo.description }}</p>
        </div>


        <ng-container *ngIf="albums">
            <div class="item-title">Related music</div>
            <scroll-albums [albums]="albums" [type]="'album'"></scroll-albums>
        </ng-container>
</ion-content>
<ion-footer>
    <ion-toolbar class="ion-text-center">
        <span class="text-white" (click)="dismiss()">Go Back</span>
    </ion-toolbar>
</ion-footer>