import { Component, OnInit } from '@angular/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

// Providers
import { AudioProvider } from '../../../../providers/audio/audio';

@Component({
  selector: 'play-button',
  templateUrl: 'play-button.html'
})
export class PlayerPlayButtonComponent implements OnInit {
  public isPlaying = true;
  public isLoading = false;
  constructor(
    readonly store: Store<AppState>,
    private audioProvider: AudioProvider
  ) {  }

  ngOnInit() {

    this.audioProvider.getPlayerStatus()
      .subscribe((status) => {
          if (status === 'playing') {
              this.isPlaying = true;
              this.isLoading = false;
          } else {
              this.isPlaying = false;
              this.isLoading = false;
          }

          if (status === 'loading') {
              this.isLoading = true;
          } else {
              this.isLoading = false;
          }
      });

  }

  play() {
    this.audioProvider.play();
  }

  pause() {
      this.audioProvider.pauseTrack();
  }
}
