<ion-tabs #tabs>
  <app-player-card></app-player-card>
  <ion-tab-bar #target>
    <ion-tab-button routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <ion-icon name="flame"></ion-icon>
      <ion-label>Discover</ion-label>
    </ion-tab-button>

    <ion-tab-button routerLink="/playlists" routerLinkActive="active">
      <ion-icon name="list"></ion-icon>
      <ion-label>Playlists</ion-label>
    </ion-tab-button>

    <ion-tab-button routerLink="/search" routerLinkActive="active">
      <ion-icon name="search"></ion-icon>
      <ion-label>Explore</ion-label>
    </ion-tab-button>

    <ion-tab-button routerLink="/account/" routerLinkActive="active">
        <ion-icon name="heart"></ion-icon>
        <ion-label>My Library</ion-label>
      </ion-tab-button>
  </ion-tab-bar>
  <div *ngIf="networkStatus" class="banner-offline">Vuulm is offline</div>
</ion-tabs>

