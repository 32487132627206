import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';


// Ionic Native Controls
import { Platform } from '@ionic/angular';
import { Media, MediaObject } from '@awesome-cordova-plugins/media/ngx';

import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

import { AudioBaseProvider } from './audio.base';
/**
 * AudioPlayProvider - play/pause/resume/stop
 * manage the state of Audio Element or Media Object
 */
@Injectable({
    providedIn: 'root'
  })
export class AudioPlayProvider extends AudioBaseProvider {
    // play details
    trackId: string;
    album: any;
    albumId: string;
    albumPlayerType: string;

    public audioFile: MediaObject;
    public $audioSource = new BehaviorSubject<any>(MediaObject);
    public $audioWebSource = new BehaviorSubject<any>(null);
    public audioWebFile = null;

    constructor(
        public platform: Platform,
        public media: Media,
        readonly store: Store<AppState>,
        @Inject(PLATFORM_ID) private platformId?: Object,
    ) {
        super(platform, media, store);

        this.platform.ready().then(() => {
            if (!this.platform.is('cordova') && isPlatformBrowser(this.platformId)) {
                this.$audioWebSource = new BehaviorSubject<any>(Audio);
                this.audioWebFile = new Audio();
            }
        });
    }

    setAudioMedia(mediaUrl) {
        this.platform.ready().then(() => {
            if (this.platform.is('cordova')) {

                if (this.platform.is('android') || this.albumPlayerType === 'offline') {
                    if (this.audioFile && this.audioFile !== null) { // check if null for android
                        this.audioFile.release();
                    }
                }

                this.audioFile = this.media.create(mediaUrl);
                this.$audioSource.next(this.audioFile);
                this.play();
            } else {
                // fall back to web
                this.audioWebFile.src = mediaUrl;
                this.$audioWebSource.next(this.audioWebFile);
                this.play();
            }
        });
    }

    /**
     * play() - play audio file
     */
    public play() {
        this._actionIsPaused.next(false);
        if (this.platform.is('cordova')) {
            this.audioFile.play();
        } else {
            this.audioWebFile.play();
        }
    }

    /**
     * resumeTrack - resume audio file
     */
    public resumeTrack() {
        if (this.platform.is('cordova')) {
            this.play();
        } else {
            this.audioWebFile.play();
        }
    }

    /**
     * pauseTrack - pause audio file
     */
    public pauseTrack() {
        this._actionIsPaused.next(true);
        if (this.platform.is('cordova')) {
            if (this.audioFile !== null) {
                this.audioFile.pause();
            }
        } else {
            this.audioWebFile.pause();
        }
    }

    /**
     * repeatTrack
     * @param repeat - play track again after it's ended if set to
     */
    public repeatTrack(repeat: boolean) {
        this.repeat = repeat;
    }


    release() {
        if (this.platform.is('android') || this.albumPlayerType === 'offline') {
            if (this.audioFile && this.audioFile !== null) { // check if null for android
                this.audioFile.release();
            }
        }
    }

    stop() {
        if (this.platform.is('cordova')) {
            if (this.audioFile && this.audioFile !== null) { // check if null for android
                this.audioFile.stop();
                this._actionIsPaused.next(false);
            }
        }
    }

    seek(e) {
        if (this.platform.is('cordova')) {
            const duration = this.audioFile.getDuration();
            const elapsedToPercentatage = e / 100;

            const seekValue = Math.abs(duration * elapsedToPercentatage);

            // set value in milliseconds ( x * 1000)
            this.audioFile.seekTo(seekValue * 1000);
        } else {
            this.audioWebFile.currentTime = e;
        }
    }
}
