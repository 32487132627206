<ng-container *ngIf="album">
    <div class="embedded-post-wrapper">
        <div class="album-cover">
            <div *ngIf="album && album.artwork" class="bg-image" [ngStyle]="setBackgroundImage()"></div>
        </div>

        <div class="album-info">
            <div>
                <img class="artwork" [src]="cleanAlbumArtwork(album.artwork)"/>
                <h2>{{album.name}}</h2>
                <p>{{album.title}}</p>
                <div>
                    <small *ngIf="album.published_date">{{album.published_date | date: 'yyyy' }}</small>
                    <small *ngIf="album.tracks"> &middot; {{album.tracks?.length }} songs</small>
                </div>
            </div>
            <div class="action">
                <ion-icon name="play-circle-outline" class="play-icon" (click)="playSong(album, album.tracks[0]._id)"></ion-icon>
            </div>
        </div>
    </div>
</ng-container>
