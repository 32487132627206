import { Injectable } from '@angular/core';
import { APIService} from '@vuulm/core';
import { Platform } from '@ionic/angular';

@Injectable()
export class ApiPlatformService {

    constructor(
        public platform: Platform,
        public api: APIService
    ) {
        // Check is platform is native running cordova,
        // then set proxy to server to bypass cors configuration
        this.platform.ready().then(() => {
            if (!this.platform.is('cordova')) {
                this.api.setProxyPath('/api');
            }
        });
    }

    get(url, params?) {
        const options = {
          params: this.api.objectToParams(params)
        };
        return this.api.get(`${url}`, {}, options);
    }

    put(url, body) {
        return this.api.put(`${url}`, body);
    }

    post(url, body) {
        return this.api.post(`${url}`, body);
    }

    delete(url) {
        return this.api.delete(`${url}`);
    }
}
