import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IonRouterOutlet } from '@ionic/angular';

@Component({
  selector: 'btn-back',
  templateUrl: 'back.html',
//  styleUrls: ['./back.scss']
})
export class BackComponent implements OnInit, OnDestroy {
    @Input() defaultHref?: string;
    @Input() alwaysShow?: boolean = false;

    public routerEvents: any;
    public previousUrl = null;
    public currentUrl: string;
    public canGoBack: boolean;

    constructor(
        private router: Router,
        private ionRouterOutlet: IonRouterOutlet
    ) {}

    ngOnInit() {
        this.canGoBack    = this.ionRouterOutlet.canGoBack();
        this.currentUrl   = this.router.url;
        this.routerEvents = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl  = event.url;
            }
        });
    }

    ngOnDestroy() {
        this.routerEvents.unsubscribe();
    }
}
