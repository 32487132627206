import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { SeoService } from '@vuulm/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { ColoredBrowserTabs } from '@vuulm/plugins/colored-browser-tabs/ngx';
import { DOCUMENT } from '@angular/common';

// pages
import { DetailComponent } from '../../albums/detail/detail.component';
import { ProfileComponent } from '../../profile/profile.component';

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

// Services
import { ContentContentfulService } from '../../../services/content';
import { NavParams } from '@ionic/angular';
import { JsonLdService } from '../../../services/jsonld.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class BlogPostComponent implements OnInit {

  public rootPage: any;
  public profilePage = ProfileComponent;
  public albumDetailPage = DetailComponent;

  public pageContent: any;
  public pageContentRelated: any;
  public loading = true;
  public sanitizedContent: any;
  constructor( 
    @Inject(DOCUMENT) private _document: Document,
    private _renderer2: Renderer2,
    public navParams : NavParams,
    private route: ActivatedRoute,
    private seo: SeoService,
    private socialSharing: SocialSharing,
    private content: ContentContentfulService,
    private sanitizer: DomSanitizer,
    private platform: Platform,
    private browserTab: ColoredBrowserTabs,
    private jsonLdService: JsonLdService
    ) { }

  ngOnInit(): void {
    // get params from nav stack or router
    if (this.navParams.get('articleid')) {
      this.pageContent = null;
      const slug = this.navParams.get('articleid');
      this.getPageContent(slug);
    } else {
      this.route.params.subscribe((params) => {
        this.pageContent = null;
        const slug = params['articleid'];
        this.getPageContent(slug);
      });
    }
  }


  getPageContent(slug) {
    this.content.getBlogPost({'fields.slug[in]': `${slug}`})
      .subscribe((data) => {
        this.loading = false;
        if (data && data[0]) {
          this.pageContent = data[0];
          this.generateSeoTags(this.pageContent);
          this.getRelatedEntries(slug, this.pageContent?.fields?.tags);
          this.loadComments();
        }
      });
  }
  
  getRelatedEntries(slug, tags) {
    this.content.getBlogPost({'fields.slug[ne]': slug, 'fields.tags[in]': tags.toString()})
    .subscribe((data) => {
      if (data) {
        this.pageContentRelated = data;
      }
    });
  }

  loadComments() {
    if (!this.isNative()) {
      let script = this._renderer2.createElement('script');
      script.type = `text/javascript`;
      script.text = `var disqus_config = function () {
        this.page.url = 'https://www.vuulm.com/blog/article/${this.pageContent?.fields?.slug}';  
        this.page.identifier = '${this.pageContent?.sys?.id}'; 
        };
        
        (function() { // DON'T EDIT BELOW THIS LINE
        var d = document, s = d.createElement('script');
        s.src = 'https://vuulm.disqus.com/embed.js';
        s.setAttribute('data-timestamp', +new Date());
        (d.head || d.body).appendChild(s);
        })();`;

      this._renderer2.appendChild(this._document.head, script);
    }
  }

  sanitize(text) {
    return this.sanitizer.bypassSecurityTrustHtml(text)
  }

  setBackgroundImage() {
    if (!this.pageContent.fields.heroImage?.fields.file.url) {
      return false;
    }

    const artwork = this.pageContent.fields.heroImage?.fields.file.url;

    const style = {
      'background-image': `linear-gradient(0deg, rgb(20, 20, 21), rgba(0, 0, 0, 0.2) 100%), url("https:${encodeURI(artwork)}")`,
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
    };

    return style;
  }

  setPostVideoThumbnail(entityId) {
    if(!entityId) {
      return null;
    }
    const youtubeUrl = `//img.youtube.com/vi/${entityId}/maxresdefault.jpg`
    const style = {
      'background-image': `linear-gradient(0deg, rgb(20, 20, 21), rgba(0, 0, 0, 0) 100%), url("https:${encodeURI(youtubeUrl)}")`,
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
    };
    return style;
  }

  setVideoThumb(url) {
    if(!url) {
      return null;
    }
    const style = {
      'background-image': `linear-gradient(0deg, rgb(20, 20, 21), rgba(0, 0, 0, 0) 100%), url("https:${encodeURI(url)}")`,
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
    };
    return style;
  }

  isNative() {
    if (this.platform.is('cordova')) {
      return true;
    } else {
      return false;
    }
  }

  getVideoUrl(videoId) {
    const url = `https://www.youtube.com/embed/${videoId}`;
    const videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return videoUrl;
  }

  goToUrl(videoId) {
    const videoUrl = `https://www.youtube.com/watch?v=${videoId}`;
    const url = encodeURI(videoUrl);
    if (this.platform.is('cordova')) {
      this.browserTab.openTab(`${url}`, '#000000').subscribe();
    } else {
      window.open(`${url}`);
    }
  }

  async share(post) {
    const options = {
      message: `${post.fields.title} | Vuulm`,
      subject:   `Vuulm`,
      files:     [`https:${encodeURI(post.fields.heroImage.fields.file.url)}`],
      url:   encodeURI(`https://www.vuulm.com/blog/article/${encodeURI(post.fields.slug)}`)
    };

    if (this.platform.is('cordova')) {
      this.socialSharing.shareWithOptions(options)
          .then(() => { })
          .catch(() => { });
    } else {
      const navigator = window.navigator as any;
        if (navigator.share) {
          await navigator.share({title: options.message, url: options.url});
        }
    }   
  }

  private generateSeoTags(post) {
    this.seo.generateTags({
      title: `${post.fields.title} | Vuulm`,
      description: `${post.fields.description}`,
      image: `https:${encodeURI(post.fields.heroImage.fields.file.url)}`,
      keywords: `${post.fields.tags}`,
      ogType: 'article',
      type: 'article',
      page: `blog/article/${encodeURI(post.fields.slug)}`,
    });

    this.seo.generateArticleTags({
      publishDate: `${post.fields.publishDate}`,
      category: `${post.fields.category[0]}`,
      author: `${post.fields.author?.fields.name}`,
      tags: `${post.fields.tags}`
    });

    this.setCanonicalUrl(post);
    const ldPost = this.jsonLdService.getBlogPostObject(post.fields);
    this.setJsonLd(ldPost);
  }

  private setJsonLd(data: any): void {
    if (!data) {
      return null;
    }

    let script = this._renderer2.createElement('script');
    script.type = 'application/ld+json';
    script.text = `${JSON.stringify(data)}`;
    this._renderer2.appendChild(this._document.head, script);
  }

  private setCanonicalUrl(post) {
    var link = !!this._document.querySelector("link[rel='canonical']") ? this._document.querySelector("link[rel='canonical']") : this._document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', `https://www.vuulm.com/blog/article/${encodeURI(post.fields.slug)}`);
    this._document.head.appendChild(link);
  }

}
