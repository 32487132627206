import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { Storage } from '@ionic/storage-angular';
import { UserOfflineLibraryService } from '@vuulm/core';

@Injectable({
    providedIn: 'root'
})
export class OfflineManagerDbService {

  public storageAlbums: any;
  constructor(
    public platform: Platform,
    public userOfflineLibraryService: UserOfflineLibraryService
  ) {
    this.storageAlbums = new Storage(
      {
        name: 'vuulm.db', 
        storeName: '_albums', 
        dbKey: '_albums',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage] 
      });
      this.storageAlbums.create();
    // this.storageAlbums.clear();
  }

  addAlbumsToLocal(albumId, albumDetails) {
    this.storageAlbums.set(albumId, albumDetails);
    this.syncAlbum(albumId);
  }

  async getAlbums() {
    const albums = [];
    const promise = await new Promise((resolve, reject) => {
      this.storageAlbums.forEach((value, key, index) => {
        albums.push(value);
      }).then((d) => {
        resolve(albums);
      });
    });
    return promise;
  }

  async getAlbumById(albumId): Promise<any> {
    let album = [];
    await this.storageAlbums.get(albumId).then((results: any) => {
      album = results;
    });
    return album;
  }


  removeAlbum(albumId) {
    this.storageAlbums.remove(albumId).then((data) => {
      console.log(`${albumId} removed from local db`);
    });
  }

  private syncAlbum(albumId) {
    this.userOfflineLibraryService.addAlbum({albumId: `${albumId}`})
      .subscribe(status => { /* done */});
  }

  private syncAlbums(albums) {
    // TODO: Sync albums when network is connected
    const results = [];
    albums.forEach( (element) => {
      results.push({ albumId: element._id});
    });
    this.userOfflineLibraryService.resync({albums: results})
      .subscribe((status) => { /*  done */ });
  }

}

