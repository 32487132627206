import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-podcasts-detailInfo',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class PodcastsInfoComponent implements OnInit {

    @Input() episode: any;
    constructor(
        private modal: ModalController,
    ) {}

    ngOnInit() {}

    dismiss() {
        this.modal.dismiss({
            dismissed: true
        }).then(() => {
        });
    }
}
