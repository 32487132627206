import { Component, Input } from '@angular/core';
import { ToastController, Platform } from '@ionic/angular';
import { AudioProvider } from '../../../../providers/audio/audio';
@Component({
  selector: 'player-info',
  templateUrl: 'info.html',
  styleUrls: ['./info.scss'],
})
export class PlayerInfoComponent {
  @Input('album') album: any;
  @Input('trackId') trackId: string;
  @Input('albumPlayerType') albumPlayerType: string; // album, playlist, offline

  currentTrackInfo: any;
  repeat = false;
  constructor(
    private toaster: ToastController,
    private audioProvider: AudioProvider,
    private platform: Platform
  ) {}

  getTrackTitle(album, trackId) {
    if (trackId !== null) {
      const trackListing = album.tracks.filter(x => x._id === trackId);
      return trackListing[0].title;
    } else {
      return album.tracks[0].title;
    }
  }

  getAlbumName(album) {
    const trackName = album.tracks.find(x => x._id === this.trackId);
    return (trackName.artistName) ? trackName.artistName : this.album.name;
  }

  goToPurchaseLink(album) {
    this.platform.ready().then(() => {
      if (album.purchase_link) {
        const url = encodeURI(album.purchase_link);
        window.open(`${url}`, '_system', 'location=no');
      }
    });
  }
}
