import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Network } from '@awesome-cordova-plugins/network/ngx';

// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

export enum ConnectionStatus {
    Online,
    Offline
  }

@Injectable({
    providedIn: 'root'
})
export class NetworkService {

    public networkStatus;
    constructor(
        private network: Network,
        public platform: Platform,
        readonly store: Store<AppState>,
    ) {
        this.platform.ready().then(() => {
            this.networkStatus =  this.network.type !== 'none' ? ConnectionStatus.Online : ConnectionStatus.Offline;
            this.store.dispatch({ type: 'network/STATUS', payload: { networkStatus: this.networkStatus} });
        });
    }

    initializeNetworkEvents() {
        this.networkStatus =  this.network.type !== 'none' ? ConnectionStatus.Online : ConnectionStatus.Offline;
        console.log('initializeNetworkEvents', this.networkStatus);

        this.network.onDisconnect().subscribe(() => {
            console.log('offline');
            this.store.dispatch({ type: 'network/STATUS', payload: { networkStatus: ConnectionStatus.Offline} });
        });


        this.network.onConnect().subscribe(() => {
            console.log('online');
            this.store.dispatch({ type: 'network/STATUS', payload: { networkStatus: ConnectionStatus.Online} });
        });
    }
}
