import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
declare var cordova: any
@Injectable({
    providedIn: 'root'
})
export class IdfaService {

    constructor(
        private platform: Platform,
    ) { }

    askTrackingPermission() {

        if (this.platform.is('ios')) {
            const idfaPlugin = cordova.plugins.idfa;

            idfaPlugin.getInfo()
                .then(info => {
                    if (!info.trackingLimited) {
                        return info.idfa || info.aaid;
                    } else if (info.trackingPermission === idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED) {
                        return idfaPlugin.requestPermission().then(result => {
                            if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {
                                return idfaPlugin.getInfo().then(info => {
                                    return info.idfa || info.aaid;
                                });
                            }
                        });
                    }
                })
                .then(idfaOrAaid => {
                    if (idfaOrAaid) {
                        console.log('idfa: ' +idfaOrAaid);
                    }
                });
        }
    }
}