<ng-container *ngIf="category">
    <small *ngIf="category == 'artist'" class="v-artist-block">
        <ion-icon name="checkmark-circle" class="text-blue"></ion-icon>&nbsp;
        <span *ngIf="!iconOnly" class="verified">Artist</span>
    </small>
    <small *ngIf="category == 'curator'" class="v-artist-block">
        <ion-icon name="checkmark-circle" class="text-blue"></ion-icon>&nbsp;
        <span *ngIf="!iconOnly" class="verified">Curator</span>
    </small>
    <small *ngIf="category == 'label'" class="v-artist-block">
        <ion-icon name="checkmark-circle" class="text-blue"></ion-icon>&nbsp;
        <span *ngIf="!iconOnly" class="verified">Label</span>
    </small>
    <small *ngIf="category == 'producer'" class="v-artist-block">
        <ion-icon name="checkmark-circle" class="text-blue"></ion-icon>&nbsp;
        <span *ngIf="!iconOnly" class="verified">Producer</span>
    </small>
    <small *ngIf="category == 'dj'" class="v-artist-block">
        <ion-icon name="checkmark-circle" class="text-blue"></ion-icon>&nbsp;
        <span *ngIf="!iconOnly" class="verified">DJ</span>
    </small>
    <small *ngIf="category == 'podcast'" class="v-artist-block">
        <ion-icon name="checkmark-circle" class="text-blue"></ion-icon>&nbsp;
        <span *ngIf="!iconOnly" class="verified">Podcast</span>
    </small>
</ng-container>