import { Component, OnInit, Input } from '@angular/core';
import { IonNav } from '@ionic/angular';

// Components
import { ProfileComponent } from '../../../pages/profile/profile.component';

// Routing
import { Router } from '@angular/router';
@Component({
    selector: 'artist-list',
    templateUrl: './artist-list.component.html',
    styleUrls: ['./artist-list.component.scss']
})
export class ArtistsListComponent implements OnInit {
    @Input('artists') artists: any;
    @Input('pushToStack') pushToStack: boolean = false;

    public profilePage = ProfileComponent;

    constructor(private router: Router, public ionNavLink: IonNav) { }
    ngOnInit() { }

    goToArtist(userId) {
        if (this.pushToStack) {
            this.ionNavLink.push(this.profilePage, { userid: userId});
        } else {
            this.router.navigate([`/${userId}`]);
        } 
    }
}
