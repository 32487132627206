<ion-header translucent no-border>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button style="font-size: 12px;margin-left: 10px"></ion-back-button>
        </ion-buttons>
        <ion-title *ngIf="profile">{{(profile.displayName) ? profile.displayName : profile.username}}</ion-title>
        <ion-buttons slot="end">
            <btn-follow *ngIf="profile" [user]="profile"></btn-follow>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
      
<ion-content fullscreen>
    <ng-container *ngIf="profile">
        <div class="profile" [class.none]="!profile.bannerUrl">
            <div class="banner" *ngIf="profile.bannerUrl" [style.backgroundImage]="'url('+ profile.bannerUrl +'?w=1320&h=320&quality=90)'"></div>
            <div class="main">
                <div class="profile-header">
                    <img class="avatar" *ngIf="!profile.avatar" src="https://via.placeholder.com/150/1B1B1C/CCCCCC?text={{ trimName(profile.username) }}"/>
                    <img class="avatar" *ngIf="profile.avatar" src="{{profile.avatar.thumbnail}}?resize=150,150"/>
                </div>
                <div>
                    <h3>{{profile.username}}  <user-category class="category" *ngIf="profile.verified" [iconOnly]="true" [category]="profile.category"></user-category></h3>
                </div>
                <div class="profile-info">
                    <div class="stats">
                        <strong>{{this.albumStats.played | numberAbbr }}</strong>
                        <small class="muted">Plays</small>
                    </div>
                    <div class="stats" *ngIf="this.albumStats?.monthly > 100">
                        <strong>{{this.albumStats?.monthly | numberAbbr }}</strong>
                        <small class="muted">Monthly</small>
                    </div>
                    <div>
                        <ng-container *ngIf="profile && profile.social">
                            <ion-button size="small" fill="clear" color="light" *ngIf="profile.social.instagram" (click)="goToUrl('https://www.instagram.com/' + profile.social.instagram)">
                                <ion-icon name="logo-instagram"></ion-icon>
                            </ion-button>
                            <ion-button size="small" fill="clear" color="light" *ngIf="profile.social.facebook_url" (click)="goToUrl('https://www.facebook.com/' + profile.social.facebook_url)">
                                <ion-icon name="logo-facebook"></ion-icon>
                            </ion-button>
                            <ion-button size="small" fill="clear" color="light" *ngIf="profile.social.twitter" (click)="goToUrl('https://www.twitter.com/' + profile.social.twitter)">
                                <ion-icon name="logo-twitter"></ion-icon>
                            </ion-button>
                            <ion-button size="small" fill="clear" color="light" *ngIf="profile.social.youtube" (click)="goToUrl('https://www.youtube.com/' + profile.social.youtube)">
                                <ion-icon name="logo-youtube"></ion-icon>
                            </ion-button>
                            <ion-button size="small" fill="clear" color="light" *ngIf="profile.social.snapchat" (click)="goToUrl('https://www.snapchat.com/add/' + profile.social.snapchat)">
                                <ion-icon name="logo-snapchat"></ion-icon>
                            </ion-button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrapper ion-padding">
            <div class="latest-release" *ngIf="albums && latestRelease" (click)="goToAlbum(latestRelease._id)">
                <div>
                    <span class="label">Latest Release</span><br/><br/>
                    <strong>{{latestRelease.title}}</strong><br/>
                    <small class="muted" *ngIf="latestRelease.type">{{latestRelease.type[0] | titlecase }}</small>
                    <small class="muted" *ngIf="latestRelease.published_date">&middot; {{latestRelease.published_date | date: 'yyyy' }}</small>
                    <small class="muted" *ngIf="latestRelease.tracks">&middot; {{latestRelease.tracks?.length }} songs</small>

                    <div class="stats">
                        <div *ngIf="latestRelease.stats && latestRelease.stats.streams"><ion-icon name="play" class="muted"></ion-icon> {{latestRelease.stats.streams | numberAbbr}}</div>
                        <div *ngIf="latestRelease.stats && latestRelease.stats.streams"><ion-icon name="heart" class="muted"></ion-icon> {{latestRelease.stats.favorites | numberAbbr}}</div>
                        <div *ngIf="latestRelease.stats && latestRelease.stats.streams"><ion-icon name="arrow-redo" class="muted"></ion-icon> {{latestRelease.stats.reposts | numberAbbr}}</div>
                    </div>
                </div>
                <div>
                    <img src="{{latestRelease.artwork}}?resize=150,150" class="artwork"/>
                </div>
            </div>

            <div>
                <ng-container *ngIf="topAlbums && topAlbums.length > 0">
                    <div class="item-title">Top songs</div>
                    <album-list [albums]="topAlbums" [type]="'album'" [showTrackTitle]="true" [pushToStack]="true"></album-list>
                </ng-container>

                <ng-container *ngIf="albums && albums.length > 0">
                    <div class="item-title">Albums <ion-button (click)="goToUserAlbums()" *ngIf="albums.length > 4" class="action" size="small" color="dark"> See all</ion-button></div>
                    <scroll-albums [albums]="albums" [type]="'album'" [pushToStack]="true"></scroll-albums>
                </ng-container>

                <ng-container *ngIf="relatedAlbums && relatedAlbums.length > 0">
                    <div class="item-title">Related music <ion-button (click)="goToUserAlbums('related')" *ngIf="relatedAlbums.length > 3" class="action" size="small" color="dark"> See all</ion-button></div>
                    <scroll-albums [albums]="relatedAlbums" [type]="'album'" [pushToStack]="true"></scroll-albums>
                </ng-container>

                <ng-container *ngIf="podcasts && podcasts.length > 0">
                    <div class="item-title">Podcasts</div>
                    <scroll-albums [albums]="podcasts" [type]="'podcast'" [pushToStack]="true"></scroll-albums>
                </ng-container>

                <ng-container *ngIf="playlists && playlists.length > 0">
                    <div class="item-title">Featured on</div>
                    <scroll-albums [albums]="playlists" [type]="'playlist'" [showStatsIcon]="false" [pushToStack]="true"></scroll-albums>
                </ng-container>

                <ng-container *ngIf="userPlaylists && userPlaylists.length > 0">
                    <div class="item-title">{{profile.username | titlecase}}'s playlists</div>
                    <scroll-albums [albums]="userPlaylists" [type]="'playlist'" [showStatsIcon]="false" [pushToStack]="true"></scroll-albums>
                </ng-container>

                <ng-container *ngIf="pageContentRelated && pageContentRelated.length > 0">
                    <div class="item-title">Editorials</div>
                    <app-content-blog-posts [posts]="pageContentRelated"></app-content-blog-posts>
                </ng-container>

                <ng-container *ngIf="profile.bio">
                    <div class="item-title">Artist bio</div>
                    <div (click)="presentModal()" class="bio" [innerHtml]="profile.bio | ellipsis: 130"></div>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <div class="offset"></div>
</ion-content>