// Actions
import * as UserAlbumDetailAction from '../actions/album.action';


// Define initial state
const initialState = {};

export function albumDetailReducer(state = initialState, action: UserAlbumDetailAction.Actions) {
    switch (action.type) {
        case UserAlbumDetailAction.GET_CURRENT_ALBUM:
            return { ...state, details: action.payload };
        default:
            return state;
    }
}
