import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

@Component({
  selector: 'app-player-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss']
})
export class PlayerQueueComponent implements OnInit {

  @ViewChild(IonContent, { static: false }) content: IonContent;

  public album: any;
  public trackPlaying: string;
  public albumPlayerType: string;
  constructor(
    readonly store: Store<AppState>,
  ) {
    this.store.select(state => state.player)
      .subscribe((response: any) => {
        if (response && response.queue !== null) {
          this.album = response.queue;
          this.trackPlaying = response.currentSong.trackId;
          this.albumPlayerType = response.currentSong.type;
        }
      });
  }

  ngOnInit() { }

  ionViewDidEnter() {
    this.scrollTo(`${this.trackPlaying}`);
  }

  scrollTo(element) {
    let y = document.getElementById(element).offsetTop;
    this.content.scrollToPoint(0, y - 7, 0);
  }

  playSong(album, trackid, type = 'album') {
    const audioSrc = {
      queue: album,
      currentSong: {
        albumId: album._id,
        trackId: trackid,
        type: `${type}`
      }
    };
    this.play(audioSrc);
  }

  play(audioSrc) {
    this.store.dispatch({ type: 'player/QUEUE', payload: audioSrc });
  }
}