import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonLdService {

    getAlbumLdObject(album: any, user: any) {
        if (!album) {
            return null;
        }

        if (!user) {
            return null;
        }

        let object = {
            '@context': 'http://schema.org',
            '@type': 'MusicRecording',
            'url': `https://www.vuulm.com/albums/${encodeURI(album.permalink)}`,
            'thumbnailUrl': encodeURI(album.artwork),
            'description': `Listen to ${album.name} latest ${album.type[0]} ${album.title}`,
            'name': `${album.name} ${album.title} on Vuulm`,
            'dateModified': `${album.updated_date}`
        };

        let author = {
            'author': {
                'type': 'Person',
                'name': `${album.name}`,
                'url': `https://www.vuulm.com/${encodeURI(user.permalink)}`
            }
        };

        let interaction = {
            'interactionStatistic': {
                '@type': 'InteractionCounter',
                'interactionType': 'https://schema.org/ListenAction',
                'userInteractionCount': album.stats.streams
            },
        };

        let audio = {
            "audio": {
                "@type": "AudioObject",
                "embedUrl": `https://www.vuulm.com/embed/album/${encodeURI(album.permalink)}`,
                "uploadDate": `${album.Created_date}`,
                "playerType": "HTML5"
            }
        };

        let keywords = {
            'keywords': [ album.name, album.title, album.type[0]]
        };

        let provider = {
            'provider': {
                '@type': 'Organization',
                'name': 'Vuulm',
                'image': 'https://cdn.vuulm.com/assets/avatar.png'
            }
        };

        let itemListElement = {
            '@type': 'BreadcrumbList',
            '@context': 'https://schema.org',
            'itemListElement' : [
                {
                    '@type': 'ListItem',
                    'position': 1,
                    'item': {
                        '@id': `https://www.vuulm.com/${encodeURI(user.permalink)}`,
                        'name': `${user.username}`
                    }
                }
            ]
        };

        const musicRecording = Object.assign({}, object, author, interaction, audio, keywords, provider);
        
        const ld = [ musicRecording, itemListElement];

        return ld;
    }


    getBlogPostObject(post: any) {
        if (!post) {
            return null;
        }

        let object = {
            '@type': 'NewsArticle',
            '@context': 'https://schema.org',
            'name': post.title,
            'url': `https://www.vuulm.com/blog/article/${encodeURI(post.slug)}`,
            'image': `https:${encodeURI(post.heroImage.fields.file.url)}`,
            'thumbnailUrl': `https:${encodeURI(post.heroImage.fields.file.url)}`,
            'headline': post.title,
            'dateCreated': post.publishDate,
            'datePublished': post.publishDate,
            'dateModified': post.publishDate
        };

        let organization =  {
            "publisher": {
                "@type": "Organization",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://cdn.vuulm.com/assets/avatar.png"
                }
            }
        };

        let blog = {
            'mainEntityOfPage': {
                '@type': 'WebPage',
                '@id': `https://www.vuulm.com/blog/article/${encodeURI(post.slug)}`
            }
        };

        let person = {
            "author": {
                "@type": "Person",
                "name": "Vuulm",
                "url": "https://www.vuulm.com"
            }
        };

        let creator = {
            "creator": [ "Vuulm"]
        };

        const blogPost = Object.assign({}, object, organization, blog, person, creator);
        
        return [ blogPost ];
    }
}