import { Component, Input } from '@angular/core';

@Component({
  selector: 'user-category',
  templateUrl: 'category.html',
})
export class UserCategoryComponent {
    @Input('category') category: any;
    @Input('iconOnly') iconOnly = false;
}
