import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { PipeModule } from '@vuulm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedActionsModule } from '../actions/actions.module';
import { PodcastService, AlbumService } from '@vuulm/core';

// Components
import { PlayerComponent } from './player.component';
import { PlayerCardComponent } from './card/card.component';
import { PlayerQueueComponent } from './queue/queue.component';
import {
    PlayerAudioControlsComponent,
    PlayerControlsComponent,
    PlayerDurationComponent,
    PlayerInfoComponent,
    PlayerPlayButtonComponent,
    PlayerSlidesComponent
} from './player-controls';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    PipeModule,
    FormsModule,
    ReactiveFormsModule,
    SharedActionsModule
  ],
  declarations: [
    PlayerComponent,
    PlayerCardComponent,
    PlayerQueueComponent,
    PlayerAudioControlsComponent,
    PlayerControlsComponent,
    PlayerDurationComponent,
    PlayerInfoComponent,
    PlayerPlayButtonComponent,
    PlayerSlidesComponent
  ],
  entryComponents: [
    PlayerComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    SharedActionsModule,
    PipeModule,
    PlayerComponent,
    PlayerCardComponent,
    PlayerQueueComponent,
    PlayerAudioControlsComponent,
    PlayerControlsComponent,
    PlayerDurationComponent,
    PlayerInfoComponent,
    PlayerPlayButtonComponent,
    PlayerSlidesComponent
  ],
  providers: [ PodcastService, AlbumService ]
})

export class SharedPlayerModule {	}
