import { Action } from '@ngrx/store';

// Actions
import * as NetworkActions from '../actions/network.action';


// Define initial state
const initialState = {
    networkStatus: null
};

export function networkReducer(state = initialState, action: NetworkActions.Actions) {
    switch (action.type) {
        case NetworkActions.NETWORK_STATUS:
            return {
                ...state,
                networkStatus: action.payload.networkStatus
            };

        default:
            return state;

    }
}
