import { Component, OnInit, Input, TemplateRef, } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { UserDownloadService } from '../../../services/users/download.service';

// Router
import { Router, ActivatedRoute } from '@angular/router';

declare var window: any;
@Component({
  selector: 'album-grid',
  templateUrl: './album-grid.component.html',
  styleUrls: ['./album-grid.component.scss'],
})
export class AlbumGridComponent implements OnInit {
  @Input() album: any = [];
  @Input() offlineLibrary = false;
  @Input() actions: TemplateRef<any>;
  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private webview: WebView,
    private userDownloadService: UserDownloadService
  ) {}

  ngOnInit(): void {}

  goToAlbum(albumId, type = 'album') {
    if (type === 'playlist') {
      this.router.navigate([`/playlists/${albumId}`]);
    } else if (type === 'offline') {
      this.router.navigate([`/account/downloads/${albumId}`]);
    } else {
      this.router.navigate([`/albums/${albumId}`]);
    }
  }

  getImageUrl(imageURI) {
    if (imageURI.includes('https') || imageURI.includes('http')) {
      const clean = encodeURI(imageURI);
      return `${clean}?resize=250,250`;
    } else {
      const filePath = this.userDownloadService.getFilePath();
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        window.Ionic.WebView.convertFileSrc(filePath + imageURI)
      );
    }
  }
}
