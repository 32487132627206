import { Injectable } from '@angular/core';
import { AuthService } from '@vuulm/core';
import { Platform } from '@ionic/angular';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file';

import * as config from '../../config';

@Injectable()
export class UserUploadService {

  private apiUrl = config.APPCONFIG.apiEndpoint;

  constructor(
     private auth: AuthService,
     public platform: Platform,
     private transfer: FileTransfer,
     // tslint:disable-next-line: deprecation
  ) {}

  uploadFile(imageURI) {
    const fileTransfer: FileTransferObject = this.transfer.create();

    const options: FileUploadOptions = {
        fileKey: 'file',
        httpMethod: 'POST',
        fileName: `${Date.now()}.jpg`,
        chunkedMode: false,
        mimeType: `image/jpeg`,
        headers: { Authorization: `Bearer ${this.auth.User.jwt}` }
    };

    return fileTransfer.upload(imageURI, `${this.apiUrl}/users/profile/${this.auth.User.uid}/avatar`, options);
  }
}
