<ion-content fullscreen></ion-content>
<ion-footer>
    <div *ngIf="getAlbumDetails()" class="album-list">
        <div class="artwork">
            <img src="{{getAlbumDetails().artwork}}?resize=85,85" class="cover">
        </div>
        <div class="info">
            <div *ngIf="getAlbumDetails().featured" class="featured">
                <span class="label">Featured</span>
            </div>
            <strong class="title">{{getAlbumDetails().title}}</strong><br/>
            <small class="name muted">{{ getAlbumDetails().name | ellipsis:24 }}</small>
        </div>
    </div>

    <ion-grid class="actions" *ngIf="getAlbumDetails().purchase_link">
        <ion-row (click)="goToUrl()">
            <ion-col size="2"><ion-icon name="push-outline"></ion-icon></ion-col>
            <ion-col size="10">Go to store</ion-col>
        </ion-row>
    </ion-grid>

    <ion-row (click)="dismiss()">
        <ion-col size="12" class="ion-text-center">
            <ion-icon class="closePanel" name="arrow-down"></ion-icon>
        </ion-col>
    </ion-row>
    <br/>
</ion-footer>