import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

// Store
import { Store } from '@ngrx/store';

// Services
import { AppState, UserFavoritesService, UserFollowingService, UserDetailService } from '@vuulm/core';
import { OfflineManagerDbService } from './services/offline-manager/offline-db.service';
import { StorageService } from './services/storage/storage.service';
import { FcmProvider } from './providers/fcm/fcm';
import { IdfaService } from './services/idfa';

// Logging
import * as Sentry from '@sentry/browser';

// Config
import { APPCONFIG } from './config';
import { AudioProvider } from './providers/audio';
import { AdAppLovinService } from './services/ads';
import { AnalyticsTrackingService } from './services/analytics.service';
import { DeepLinkingService } from './services/deeplinks.service';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  // tslint:disable-next-line: member-ordering
  public queueList: any;
  // tslint:disable-next-line: member-ordering
  public queueType = 'album';
  public showBackdrop = false;
  public enableBackdropDismiss = true;
  public shouldPropagate = true;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    readonly store: Store<AppState>,
    private userDetailService: UserDetailService,
    private userFavoritesService: UserFavoritesService,
    private userFollowingService: UserFollowingService,
    private offlineManager: OfflineManagerDbService,
    private storage: StorageService,
    private idfa: IdfaService,
    private audioProvider: AudioProvider,
    private adAppLovinService: AdAppLovinService,
    private fcm: FcmProvider,
    private analytics: AnalyticsTrackingService,
    private deepLinkingService: DeepLinkingService,
    private auth: AuthenticationService, 
    
  ) { 
    this.store.select(state => state.player)
    .subscribe((response: any) => {
      if (response && response.queue !== null) {
        this.getPlayingStatus();
      }
    });
  }

  async ngOnInit() {
    this.initializeApp();
    await this.deepLinkingService.init();
    this.adAppLovinService.init();
    this.analytics.init();
    if(!this.showBackdrop) {
      this.adAppLovinService.destroyMRec();
    }
  }

  async ngAfterViewInit() {
    
    // check for existing storage
    await this.storage.init();
    const auth: any = await this.storage.get('auth0Vuu');
    if (auth && auth.jwt && auth.uid) {
      this.addUserSession(auth);
    }

    // check if there are auth changes to the store
    this.store.select(state => state.auth)
      .subscribe(auth => {
        if (auth && auth.jwt && auth.uid) {
          this.getUserProfile();
          this.getUserFavorites();
          this.getUserFavoritesAlbum();
          this.getUserFollowing(auth);
          this.getUserDownloads();
        }
      });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      if (this.platform.is('cordova')) {
        this.statusBar.backgroundColorByHexString('#000000');
        this.statusBar.styleBlackTranslucent();
        this.splashScreen.hide();
        let platform = 'ios'
        // idfa for ios
        if (this.platform.is('ios')) {
          this.idfa.askTrackingPermission();
        } else {
          platform = 'android';
        }
        // enable fcm push
        this.fcm.requestPermission(platform);
      } else {
        // handle auth
        if (window.location.hash) {    
          await this.auth.callback(window.location.href);
        }
      }
    });
  }

  addUserSession(session) {
    this.store.dispatch({ type: 'auth/LOGIN', payload: { jwt: session.jwt, uid: session.uid } });
  }

  getUserProfile() {
    this.userDetailService.getProfile().subscribe((user: any) => {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: `${user._id}`,
          username: `${user.username}`
        });
      });

      this.store.dispatch({ type: 'user/PROFILE', payload: user });
    });
  }

  getUserFavorites() {
    this.userFavoritesService.get()
      .subscribe((r: any) => {
        if (r) {
          this.store.dispatch({ type: 'user/FAVORITES', payload: r.metadata[0]?.tracks });
        }
      });
  }

  getUserFavoritesAlbum() {
    this.userFavoritesService.get({type: 'album'})
      .subscribe((r: any) => {
        if (r && r.metadata) {
          this.store.dispatch({ type: 'user/FAVORITES_ALBUMS', payload: r.metadata[0]?.tracks});
        }
      });
  }

  getUserFollowing(session) {
    this.userFollowingService.getUserFollowing(session.uid)
      .subscribe((r: any) => {
        if (r) {
          this.store.dispatch({ type: 'user/FOLLOW', payload: r });
        }
      });
  }

  async getUserDownloads() {
    const albums = await this.offlineManager.getAlbums()
    .then((data: any) => {
      if (data && data.length > 0) {
        return data;
      }
    });
    // console.log(`Return ${albums?.length || 0} albums from local device`);
    this.store.dispatch({ type: 'downloads/ALBUMS', payload: albums});
  }

  getPlayingStatus() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.showBackdrop = false;
        this.audioProvider.didUserPauseAudio()
          .subscribe((isPaused: boolean) => {
            this.showBackdrop = true;
              if (isPaused) {
                this.showBackdrop = true;
                setTimeout(() => {
                  this.adAppLovinService.createMRec();
                }, 100);
              } else {
                this.destroyBackdrop();
              }
          });
      }
    });
  }

  destroyBackdrop() {
    this.showBackdrop = false;
    this.adAppLovinService.destroyMRec();
  }

}