<ion-app>
    <ion-router-outlet></ion-router-outlet>
</ion-app>
<ng-container *ngIf="showBackdrop">
    <ion-backdrop
        *ngIf="showBackdrop"
        tappable="enableBackdropDismiss"
        visible="showBackdrop"
        (ionBackdropTap)="destroyBackdrop()">
    </ion-backdrop>
</ng-container>
  