import { Component, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';

// NGRX
import { Store } from '@ngrx/store';

// Pages
import { LoginComponent } from '../../login/login.component';

// Services
import { AppState } from '@vuulm/core';
import { UserDownloadService } from '../../../services/users/download.service';

@Component({
  selector: 'btn-download',
  templateUrl: 'download.html',
  styleUrls: ['./download.scss'],
  providers: [ UserDownloadService ]
})
export class DownloadButtonComponent {

  @Input() album;

  public isLoggedIn = false;
  public offlineAlbums = [];
  constructor(
    private userDownloadService: UserDownloadService,
    private modal: ModalController,
    private toastCtrl: ToastController,
    readonly store: Store<AppState>
  ) {
    this.store.select(state => state.auth)
    .subscribe((session: any) => {
        if (session.jwt !== null && session.uid !== null) {
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = false;
        }
    });

    this.store.select(state => state.offlineManager)
      .subscribe((albums: any) => {
        this.offlineAlbums = albums.downloads;
      });
  }

  downloadAlbum(album) {
    if (this.isLoggedIn) {
      this.userDownloadService.downloadFile(album).then(() => {
        this.album = album;
      });
      this.showToaster('\<b>Starting download.\</b> Album will be available in library once complete.');
    } else {
      this.loginModal();
    }
  }

  isOffline(albumId) {
    if (this.offlineAlbums) {
      const albums = this.offlineAlbums.filter(x => x._id === albumId);
      if (albums && albums.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  async loginModal() {
    const modal = await this.modal.create({
      component: LoginComponent
    });
    return await modal.present();
  }

  async showToaster(message) {
     // Success!
     const toast = await this.toastCtrl.create({
      message: `${message}`,
      duration: 3000,
      color: 'dark',
      position: 'top'
    });

     toast.present();
  }
}
