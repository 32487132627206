import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-profile-bio',
  templateUrl: './profile-bio.component.html',
  styleUrls: ['./profile-bio.component.scss']
})
export class ProfileBioComponent implements OnInit {
    @Input() profile: any;
    constructor(
        private modal: ModalController,
    ) {}

    ngOnInit() {}

    dismiss() {
        this.modal.dismiss({
            dismissed: true
        }).then(() => {
        });
    }
}
