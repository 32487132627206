import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';

import { environment } from '../../../environments/environment';

import {
  authReducer,
  userProfileReducer,
  userNotificationsReducer,
  playerReducer,
  playerShuffleReducer,
  userFollowingReducer,
  userGeoLocationReducer,
  userFavoritesReducer,
  userHistoryReducer
} from '@vuulm/core';

import { albumDetailReducer } from './albumDetail.reducer';
import { networkReducer } from './network.reducer';
import { offlineManagerReducer } from './offline.reducer';
import { queueAutoPlayReducer } from './autoplay.reducer';

// tslint:disable-next-line: no-empty-interface
export interface State {}

export const reducers: ActionReducerMap<State> = {
  auth: authReducer,
  user: userProfileReducer,
  notifications: userNotificationsReducer,
  player: playerReducer,
  album: albumDetailReducer,
  favorites: userFavoritesReducer,
  geo: userGeoLocationReducer,
  following: userFollowingReducer,
  history: userHistoryReducer,
  shuffle: playerShuffleReducer,
  network: networkReducer,
  offlineManager: offlineManagerReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
