import { Component, OnInit } from '@angular/core';
import { Platform, ModalController, IonNav, NavParams } from '@ionic/angular';

// Services
import { AlbumService } from '@vuulm/core';

// Router
import { Router, ActivatedRoute } from '@angular/router';

// Components
import { DetailComponent } from '../../albums/detail/detail.component';

@Component({
  selector: 'app-profile-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.scss']
})
export class ProfileAlbumsComponent implements OnInit {
    public userId: string;
    public userAlbums: any[] = [];
    public artistName: string;
    public pageNumber = 1;
    public pageType = 'albums';
    public relatedAlbumId: string;
    constructor(
        public navParams : NavParams,
        public ionNavLink: IonNav,
        private modal: ModalController,
        private route: ActivatedRoute,
        private router: Router,
        private albumService: AlbumService,
    ) {
        this.route.params.subscribe((params) => {
            // tslint:disable-next-line: no-string-literal
            const pageType = params['pageType'];
            if (pageType) {
                this.pageType = pageType;
            }

            // tslint:disable-next-line: no-string-literal
            const artistName = params['artistName'];
            if (artistName) {
                this.artistName = artistName;
            }
            // tslint:disable-next-line: no-string-literal
            this.userId = params['userid'];
        });
    }

    ngOnInit() {
        if (this.navParams.get('userid')) {
            const userId = this.navParams.get('userid');
            this.userId = userId;
        } 

        if (this.navParams.get('pageType')) {
            const pageType = this.navParams.get('pageType');
            this.pageType = pageType;
        } 

        if (this.navParams.get('artistName')) {
            const artistName = this.navParams.get('artistName');
            this.artistName = artistName;
        } 

        if (this.navParams.get('relatedAlbumId')) {
            const relatedAlbumId = this.navParams.get('relatedAlbumId');
            this.relatedAlbumId = relatedAlbumId;
            this.getRelatedAlbumsByAlbumId();
        } 

        if (this.userId) {
            this.getAlbums();
        }
    }

    getAlbums() {
        if (this.pageType === 'albums') {
            this.getUserAlbums();
        }

        if (this.pageType === 'related') {
            this.getRelatedAlbumsByTag();
        }
    }

    getUserAlbums() {
        // top albums
        this.albumService.get({ count: '10', uid: this.userId, enablePaging: '1', pageNumber: this.pageNumber })
          .subscribe((r: any) => {
            if (r) {
                this.userAlbums = this.userAlbums.concat(r);
            } else {
                // no albums found;
            }
          });
    }

    getRelatedAlbumsByTag() {
        this.albumService
            .get({tag: `${this.artistName}`, count: 10, enablePaging: '1', pageNumber: this.pageNumber  })
            .subscribe((r: any) => {
              if (r) {
                this.userAlbums = this.userAlbums.concat(r);
              }
          });
    }

    getRelatedAlbumsByAlbumId() {
        this.albumService.getRelatedAlbums(this.relatedAlbumId)
            .subscribe((r: any) => {
                if (r) {
                    this.userAlbums = r;
                }
            });
    }
    

    goToAlbum(albumId, type = 'album') {
        if (type === 'playlist') {
          this.router.navigate([`/playlists/${albumId}`]);
        } else {
            this.ionNavLink.push(DetailComponent, { albumid: albumId});
        }
    }

    doInfinite(event) {
        setTimeout(() => {
            event.target.complete();
            this.pageNumber++;
            this.getAlbums();
        }, 500);
    }

    dismiss() {
        this.modal.dismiss({
            dismissed: true
        }).then(() => {
        });
    }
}
