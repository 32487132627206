import { Component, Input } from '@angular/core';
import { DetailComponent } from '../../../pages/albums/detail/detail.component';
import { PodcastDetailComponent } from '../../../pages/podcasts/detail/detail.component';

// Router
import { Router } from '@angular/router';
import { IonNav } from '@ionic/angular';
@Component({
  selector: 'scroll-albums',
  templateUrl: 'scroll.html',
  styleUrls: ['./scroll.scss'],
})
export class AlbumScrollComponent {
  @Input() albums: any;
  @Input() type: any;
  @Input() size = 'small';
  @Input() showStatsIcon = true;
  @Input() pushToStack?: boolean = false;
  @Input() showCategory?: boolean = false;

  albumDetailPage = DetailComponent;
  podcastDetailPage = PodcastDetailComponent;
  constructor(
    private router: Router,
    public ionNavLink: IonNav
  ) { }

  // tslint:disable-next-line: variable-name
  isAlbumRecent(published_date) {
    const d = new Date();
    const p = new Date(published_date);
    p.setDate(p.getDate());
    d.setDate(d.getDate() - 2); // check if less than 2 days
    if (p >= d) {
        return true;
    } else {
        return false;
    }
  }

  goToAlbum(albumId) {
    // check if we should push to stack or navigate to a url
    if (this.pushToStack) {
      this.ionNavLink.push(this.albumDetailPage, { albumid: albumId});
    } else {
      this.router.navigate([`/albums/${albumId}`]);
    }    
  }

  goToPlaylist(playlistId) {
    this.router.navigate([`/playlists/${playlistId}`]);
  }

  goToPodcast(podcastId) {
    if (this.pushToStack) {
      this.ionNavLink.push(this.podcastDetailPage, { podcastid: podcastId});
    } else {
      this.router.navigate([`/podcasts/${podcastId}`]);
    }
  }
}
