import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

// Components
import { PlayerComponent } from '../../player/player.component';

// Service
import { AdAppLovinService } from '../../../services/ads';

@Component({
  selector: 'tracklisting',
  templateUrl: 'tracklisting.html',
  styleUrls: ['./tracklisting.scss']
})
export class TracklistComponent {
  @Input('album') album: any;
  @Input('type') type: string;
  @Input('user') user?: any;
  @Input('openModal') openModal?: boolean = false;
  @Input('size') size?: string = 'large';
  @Input('view') view?: string = 'list';
  public trackPlaying: string;
  constructor(
    private modalCtrl: ModalController,
    private adAppLovinService: AdAppLovinService,
    readonly store: Store<AppState>
  ) {
    this.store.select(state => state.player)
        .subscribe((response: any) => {
            if (response) {
              this.trackPlaying = response.currentSong.trackId;
            }
        });
  }

  cleanUrl(url) {
    const clean = encodeURI(url);
    return clean;
  }

  playSong(album, trackid, type = 'album') {
    const audioSrc = {
      queue: album,
      currentSong: {
        albumId: album._id,
        trackId: trackid,
        type: `${type}`
      }
    };

    if (type !== 'offline') {
      this.adAppLovinService.showInterstitial();
    }
    this.play(audioSrc);
  }

  play(audioSrc) {
    this.store.dispatch({type: 'player/QUEUE', payload: audioSrc});

    if (this.openModal) {
      this.presentModal();
    }
  }

  formatPlaylistArtists(tracks) {
    let artistNames = '';

    tracks.forEach((track, index) => {
      if (index < 11) {
        artistNames += `<span>${track.artistName}</span> <span class="muted">${track.title}</span> &middot; `
      }
    });
    return artistNames.trim() + ' and more';
  }


  getTotalDuration() {
    const total = this.album.tracks
      .map(track => track.duration || 0)
      .reduce((sum, current) => sum + current);
    return total || 0;
  }

  getPlayerType(album, type = 'album') {
    if (type == "album") {
      return album.type.toString();
    } else {
      return type;
    }
  }

  async presentModal() {
    const modal = await this.modalCtrl.create({
      component: PlayerComponent
    });
    return await modal.present();
  }

}
