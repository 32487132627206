import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private _storage: Storage;
    constructor(
        private platform: Platform,
        private storage: Storage,
    ) { }

    async init() {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        const storage = await this.storage.create();
        this._storage = storage;
        console.log('storage db created');
    }

    public set(key: string, value: any) {
        this._storage?.set(key, value);
    }

    public async get(key: string) {
       return await this._storage?.get(key);
    }

    public remove(key: string) {
        this._storage?.remove(key);
    }

    public clear() {
        this._storage?.clear();
    }
}