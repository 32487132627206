<ion-header translucent no-border>
    <ion-toolbar>
      <ion-buttons slot="start">
        &nbsp;&nbsp; <ion-back-button icon="arrow-back" style="font-size: 12px;"></ion-back-button>
      </ion-buttons>
      <ion-title>More music</ion-title>
    </ion-toolbar>
  </ion-header>
    
<ion-content>
    <div class="ion-padding">
        <ng-container *ngIf="userAlbums">
            <div class="square-container">
                <div class="square" *ngFor="let album of userAlbums" (click)="goToAlbum(album._id)">
                      <img *ngIf="!album.artwork" src="https://via.placeholder.com/150/1B1B1C/030303" />
                      <img *ngIf="album.artwork" src="{{album.artwork}}?resize=300,300" />
                      <strong class="title">{{album.title | ellipsis:15 }}</strong><br/>
                      <small class="muted" *ngIf="album.name">{{ album.name | ellipsis:15 }}</small>
                </div>
            </div>
        </ng-container>
        <ion-infinite-scroll *ngIf="!relatedAlbumId" threshold="100px" (ionInfinite)="doInfinite($event)">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </div>
</ion-content>