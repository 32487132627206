import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { IonNav } from '@ionic/angular';

// Routing
import { Router, ActivatedRoute } from '@angular/router';

// Services
import {  AppState, UserFavoritesService, UserPlaylistsService, UserDetailService } from '@vuulm/core';

// NGRX
import { Store } from '@ngrx/store';

// Pages
import { LoginComponent } from '../../../login/login.component';
import { AddPlaylistPage } from '../../add-playlist/add-playlist.component';
@Component({
  selector: 'album-actionsheet',
  templateUrl: './album-actionsheet.html',
  styleUrls: ['./album-actionsheet.scss'],
  providers: [ UserFavoritesService, UserPlaylistsService, UserDetailService ]
})
export class AlbumDetailActionSheetComponent implements OnInit{
    @Input() album: any;
    @Input() type: string;
    @Input() user?: string;
    @Input() trackId?: string;
    @Input() showAlbumHistory ? = false;
    @Input() showPlaylist ? = true;
    @Input() showFavorite ? = true;
    @Input() pushToStack? = false;

    private isLoggedIn = false;
    public userFavoritesList: any = [];
    public albumDetails: any;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private modalController: ModalController,
        private socialSharing: SocialSharing,
        private userFavoritesService: UserFavoritesService,
        private userPlaylistsService: UserPlaylistsService,
        private userDetailService: UserDetailService,
        readonly store: Store<AppState>,
    ) { }

    ngOnInit() {
        this.store.select(state => state.auth)
            .subscribe((session: any) => {
                if (session.jwt !== null && session.uid !== null) {
                    this.isLoggedIn = true;
                } else {
                    this.isLoggedIn = false;
                }
            });
        // check if user favorites exists
        // get favorites from store
        this.store.select(state => state.favorites)
        .subscribe(favorite => {
            this.userFavoritesList = favorite.list;
        });
    }

    cleanUrl(url) {
        const clean = encodeURI(url);
        return clean;
    }

    getAlbumDetails() {
        if (this.type === 'playlist') {
            const trackInfo = this.album.tracks.filter(x => x._id === this.trackId);
            return trackInfo[0];
        } else {
            return this.album;
        }
    }

    goToArtist() {
        if (this.type === 'playlist') {
            const trackInfo = this.album.tracks.filter(x => x._id === this.trackId);
            const userId = trackInfo[0].uid;

            if (this.pushToStack) {
                this.modalController.dismiss({userid: this.album.uid});
            } else {
                this.router.navigate([`/${userId}`]);
            }
        } else {
            if (this.pushToStack) {
                this.modalController.dismiss({userid: this.album.uid});
            } else {
                this.router.navigate([`/${this.album.uid}`]);
            }
        }
    }

    goToAlbum() {
        if (this.type === 'playlist') {
            const trackInfo = this.album.tracks.filter(x => x._id === this.trackId);
            const albumId = trackInfo[0].albumId;

            if (this.pushToStack) {
                this.modalController.dismiss({albumid: albumId});
            } else {
                this.router.navigate([`/albums/${albumId}`]);
            }
        } else {
            if (this.pushToStack) {
                this.modalController.dismiss({albumid: this.album._id});
            } else {
                this.router.navigate([`/albums/${this.album._id}`]);
            }
        }
        this.dismiss();
    }

    addToPlaylist() {
        if (this.isLoggedIn) {
            if (this.type === 'playlist') {
                const trackInfo = this.album.tracks.filter(x => x._id === this.trackId);
                const albumId = trackInfo[0].albumId;
                this.addPlaylistModal(albumId, this.trackId);
            } else {
                this.addPlaylistModal(this.album._id, this.trackId);
            }
        } else {
            this.loginModal();
        }
    }

    createFavorite() {
        if (!this.isLoggedIn) {
          this.loginModal();
          return false;
        }

        const trackInfo = {
            trackId: `${this.trackId}`,
            albumId: `${this.album._id}`
        };
        if (this.type === 'playlist') {
            const track = this.album.tracks.filter(x => x._id === this.trackId);
            const albumId = track[0].albumId;
            trackInfo.albumId = albumId;
        }

        // create new list
        if (!this.userFavoritesList) {
          const newList = {  title: 'Likes', type: 'favorite'};
          this.userPlaylistsService.post(newList)
            .subscribe((r: any) => {
              this.addToFavoriteList(trackInfo);
            });
        } else {
          this.addToFavoriteList(trackInfo);
        }
    }

    async addPlaylistModal(albumId, trackId) {
        const modal = await this.modalController.create({
            component: AddPlaylistPage,
            componentProps: {
                albumId: `${albumId}`,
                trackId: `${trackId}`
            }
        });
        modal.present();
    }

    async loginModal() {
        const m = await this.modalController.create({
            component: LoginComponent
        });
        m.present();
    }

    addToFavoriteList(trackInfo) {
        this.userFavoritesService.put(trackInfo)
          .subscribe((r: any) => {
            this.store.dispatch({type: 'user/FAVORITES', payload: r.tracks});
          });
    }

    removeFavorite(trackId) {
        if (this.userFavoritesList) {
          const fav = this.userFavoritesList.find(t => t.trackId === trackId);
          if (fav && fav._id) {
            this.userFavoritesService.deleteTrack(fav._id)
            .subscribe((r: any) => {
              this.store.dispatch({type: 'user/FAVORITES', payload: r.tracks});
            });
          }
        }
    }

    isFavorite(trackId) {
        if (this.userFavoritesList) {
          const fav = this.userFavoritesList.find(t => t.trackId === trackId);
          if (fav && fav._id) {
            return true;
          } else {
            return false;
          }
        }
      }

    share(album) {
        const options = {
          message: `#nowplaying ${album.title} - ${album.name} on Vuulm music app #vuulm #newmusic`,
          subject:   `Now playing ${album.title} ${album.name}`,
          files:     [encodeURI(album.artwork)],
          url:   encodeURI(`https://www.vuulm.com/albums/${album.permalink}`)
        };
        this.socialSharing.shareWithOptions(options)
            .then(() => {
              // this.showToaster();
            }).catch(() => {
              // Error!
            });
    }

    removeFromHistory(trackId) {
        this.userDetailService.removeUserListeningHistory(this.album._id, trackId)
            .subscribe((r: any) => {
                this.store.dispatch({type: 'user/HISTORY', payload: true});
                this.dismiss();
            });
    }

    dismiss() {
        this.modalController.dismiss({
            dismissed: true
        }).then(() => { });
    }

}
