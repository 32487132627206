import { Component, OnInit } from '@angular/core';
import { IonNav, NavParams } from '@ionic/angular';

// Routing
import { ActivatedRoute } from '@angular/router';

// Services
import { AlbumService, PlaylistService, UserDetailService } from '@vuulm/core';
import { ContentContentfulService } from '../../../services/content';

// Components
import { ProfileComponent } from '../../profile/profile.component';

@Component({
  selector: 'app-landing-playlist-detail',
  template: '<ion-nav [root]="rootPage" [swipeGesture]="true"></ion-nav>'
})
export class LandingPlaylistDetailComponent {
  public rootPage = PlaylistsDetailComponent;
}

@Component({
  selector: 'app-playlists-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class PlaylistsDetailComponent implements OnInit {
    public playlist: any;
    public showHeader = true;
    public playlistCollections: any = [];
    public profile: any;
    public artworkColors: any = "rgba(0, 0, 0, 50%)";
    constructor(
      private route: ActivatedRoute,
      private albumService: AlbumService,
      private playlistService: PlaylistService,
      private ionNavLink: IonNav,
      public navParams: NavParams,
      private content: ContentContentfulService,
      private userDetailService: UserDetailService,
    ) {}
    ngOnInit() {
      if (this.navParams.get('playlistid')) {
        const playlistid = this.navParams.get('playlistid');
        this.getPlaylistDetails(playlistid);
      } else {
        this.route.params.subscribe((params) => {
          // tslint:disable-next-line: no-string-literal
          const playlistid = params['playlistid'];
          this.getPlaylistDetails(playlistid);
        });
      }
    }

    getPlaylistDetails(playlistid) {
      this.playlistService.getById(playlistid)
        .subscribe((r: any) => {
          if (r) {
            this.playlist = r;
            // get the first album artwork
            this.getArtworkColors(this.playlist.tracks[0].albumId);
            this.showPlaylistHeader();
            this.getUserDetails(r.uid);
          }
        });

      this.content.getPlaylistCollection({limit: 5})
        .then((data) => {
          this.playlistCollections = data;
        })
    }

    /**
     * getArtworkColors - get color scheme for album
     * @param albumId
     */
    async getArtworkColors(albumId) {
      await this.albumService.colors(albumId)
        .subscribe((r: any) => {
          if (r) {
            this.artworkColors = `rgba(${r.value[0]}, ${r.value[1]}, ${r.value[2]}, 50%)`;
            console.log(this.artworkColors);
          }
        })
    }

    showPlaylistHeader() {
      if (this.playlist && this.playlist.type[0] === 'favorite') {
        this.showHeader = false;
      }
    }

    goToArtist(value) {
      this.ionNavLink.push(ProfileComponent, { userid: value.userid});
    }

    getUserDetails(userid) {
      if (!userid) { 
        return false;
      }
      this.userDetailService
        .get(userid)
        .subscribe((r: any) => {
            if (r) {
                this.profile = r;
            }
        });
    }

}
