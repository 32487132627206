import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

// Components
import { PlayerQueueComponent } from './queue/queue.component';

// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

// Providers
import { AudioProvider } from '../../providers/audio/audio';

import { PodcastService, AlbumService} from '@vuulm/core';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {

  @Output() closeDrawer = new EventEmitter;
  public isBrowser: boolean;
  public album: any;
  public trackId: string;
  public trackInfo: any;
  public playerType: string = 'playlist' || 'list';
  public showPlayer: boolean;
  public isPlaying: boolean;
  public isLoading: boolean;
  public artworkColors: any;

  constructor(
    private _modal: ModalController,
    readonly store: Store<AppState>,
    private audioProvider: AudioProvider,
    private podcastService: PodcastService,
    private albumService: AlbumService
  ) { }

  ngOnInit() {
    this.store.select(state => state.player)
        .subscribe((response: any) => {
        if (response && response.queue !== null) {
            this.album = response.queue;
            this.trackId = response.currentSong.trackId;
            this.playerType = response.currentSong.type;
            this.showPlayer = true;
            this.getArtworkColors();
        }
    });

    this.audioProvider.getPlayerStatus()
      .subscribe((status) => {
          if (status === 'playing') {
              this.isPlaying = true;
          } else {
              this.isPlaying = false;
          }

          if (status === 'loading') {
              this.isLoading = true;
          } else {
              this.isLoading = false;
          }
      });
  }

  async getArtworkColors() {
    if (this.playerType === 'album') {
      await this.albumService.colors(this.album._id)
        .subscribe((r: any) => {
          if (r) {
            this.artworkColors = `rgba(${r.value[0]}, ${r.value[1]}, ${r.value[2]}, 25%)`;
          }
        });
    }

    if (this.playerType === 'playlist') {
      let id = this.album.tracks.find(x => x._id === this.trackId).albumId;
      await this.albumService.colors(id)
        .subscribe((r: any) => {
          if (r) {
            this.artworkColors = `rgba(${r.value[0]}, ${r.value[1]}, ${r.value[2]}, 25%)`;
          }
        });
    };

    if (this.playerType === 'podcast') {
      await this.podcastService.colors(this.album._id)
        .subscribe((r: any) => {
          if (r) {
            this.artworkColors = `rgba(${r.value[0]}, ${r.value[1]}, ${r.value[2]}, 25%)`;
          }
        });
    }
  }

  cleanUrl(url) {
    const clean = encodeURI(url);
    return clean;
  }

  dismiss() {
    this._modal.dismiss();
  }

  queue() {

  }

  async presentModal() {
    const modal = await this._modal.create({
      component: PlayerQueueComponent,
      animated: true,
      initialBreakpoint: 1,
      breakpoints: [0, 0.8, 1],
      handle: true,
      swipeToClose: true
    });
    return await modal.present();
  }
}
