<div class="video-container" *ngIf="videos">
    <ng-container *ngFor="let category of videos">
        <div class="video-wrapper" *ngIf="category && category.fields?.youtubeId" (click)="openBrowserUrl(category.fields.youtubeId, category.fields)">
            <div class="video" [style.backgroundImage]="'url('+ getVideoThumb(category.fields.youtubeId) +')'">
                <div class="overlay"></div>
            </div>
            <strong class="title">{{category.fields.title | ellipsis:35 }}</strong><br/>
            <small class="muted">{{ category.fields.artistName | ellipsis:35}}</small>
        </div>
    </ng-container>
</div>