<ion-header translucent="true" no-border [style.--custombg]="artworkColors">
    <ion-toolbar>
      <ion-buttons slot="start" (click)="dismiss()"><ion-icon class="closePanel" name="chevron-down"></ion-icon></ion-buttons>
      <ion-title (click)="dismiss()"><small>Playing from</small><br/>{{album.title}}</ion-title>
      <ion-buttons slot="end" (click)="presentModal()"><ion-icon class="closePanel" name="list-outline"></ion-icon></ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content *ngIf="album" [style.--custombg]="artworkColors">
    <player-slides [album]="album" [trackId]="trackId" [albumPlayerType]="playerType"></player-slides>
    <player [album]="album" [trackId]="trackId" [albumPlayerType]="playerType"></player>
</ion-content>


