import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';

import { IonSlides } from '@ionic/angular';
import { SLIDE_OPTIONS } from './slide-options';

// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';
import { UserDownloadService } from '../../../../services/users/download.service';

declare var window: any;
@Component({
    selector: 'player-slides',
    templateUrl: 'slides.component.html',
    styleUrls: ['./slides.component.scss'],
})
export class PlayerSlidesComponent implements OnInit {
    @Input() album: any; // album model
    @Input() trackId: string; // current track id
    @Input() albumPlayerType: string; // album, playlist
    @ViewChild(IonSlides, {static: false}) slides: IonSlides;
    slideOpts = SLIDE_OPTIONS;
    update = true;

    constructor(
        readonly store: Store<AppState>,
        private sanitizer: DomSanitizer,
        private userDownloadService: UserDownloadService
    ) { }

    ngOnInit() {
        this.store.select(state => state.player)
        .subscribe((response: any) => {
            if (response && response.queue !== null) {
                const currentTrackId = response.currentSong.trackId;
                const currentAlbum = response.queue;

                const currentIndex = currentAlbum.tracks.findIndex(x => x._id === currentTrackId);
                this.goToSlideIndex(currentIndex);
            }
        });
    }


    swipePlayTrack() {
        this.slides.getActiveIndex().then(index => {
            const trackId = this.album.tracks[index]._id;

            // check if current track playing is active slide
            if (trackId !== this.trackId) {
                this.playSong(trackId);
            }
        });
    }

    playSong(trackid) {
        const audioSrc = {
            queue: this.album,
            currentSong: {
                albumId: this.album._id,
                trackId: trackid,
                type: `${this.albumPlayerType}`
            }
        };
        this.store.dispatch({type: 'player/QUEUE', payload: audioSrc});
    }

    goToSlideIndex(index) {
        setTimeout(() => {
            this.slides.slideTo(index, 500);
        }, 100);
    }

    cleanUrl(url) {
        const clean = encodeURI(url);
        return `${clean}?resize=350,350`;
    }

    getImageUrl(imageURI) {
        const filePath = this.userDownloadService.getFilePath();
        return this.sanitizer.bypassSecurityTrustResourceUrl(
            window.Ionic.WebView.convertFileSrc(filePath + imageURI)
        );
    }

}
