import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';

export const GET_CURRENT_ALBUM = 'album/DETAIL';

export class AlbumDetail implements Action {
    readonly type = GET_CURRENT_ALBUM;

    constructor(public payload: any) {}
}

// Export actions
export type Actions = AlbumDetail;
