/* Content modules for contentful services */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipeModule } from '@vuulm/core';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';


// Components
import { ContentPlaylistsComponent } from './playlists/playlists';
import { ContentVideosComponent } from './videos/videos';
import { ContentBlogPostsComponent } from './blog-posts/blog-posts';
@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    PipeModule,
    RouterModule
  ],
  declarations: [
    ContentPlaylistsComponent,
    ContentVideosComponent,
    ContentBlogPostsComponent
  ],
  entryComponents: [ ],
  exports: [
    PipeModule,
    ContentPlaylistsComponent,
    ContentVideosComponent,
    ContentBlogPostsComponent
  ],
  providers: [ ]
})

export class SharedContentModule {	}
