import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';

import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, NavParams, IonNav } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { AppLovinMax } from '@vuulm/plugins/applovin/ngx';
import { Idfa } from '@vuulm/plugins/idfa/ngx';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { FirebaseMessaging } from '@vuulm/plugins/firebase/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';

// Env Config
import { APPCONFIG } from './config';

// Vuulm Shared services
import { AuthService, APIService, UserFavoritesService, UserFollowingService, StatsReportingService, GeoLocateService, UserDetailService,
  AlbumStatisticsService, UserOfflineLibraryService } from '@vuulm/core';

// App Providers
import { AudioProvider, AudioNativeControls, AudioPlayProvider, AudioBaseProvider } from './providers/audio';
import { FcmProvider } from './providers/fcm/fcm';
import { AdAppLovinService } from './services/ads';
import { IdfaService } from './services/idfa';

// Shared
import { SharedModule } from './shared';
import { ServicesModule } from './services';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';

import { AuthGuard } from './services/guards/auth.guard';
import { OfflineGuard } from './services/guards/offline.guard';

import { UserTokenInterceptor } from './services/interceptor/userToken.interceptor';

// NGRX
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';

export function startupServiceFactory(apiService: APIService) {
  return () => apiService.setProxyPath(APPCONFIG.apiEndpoint);
}

export function startupStatServiceFactory(statReporting: StatsReportingService) {
  return () => statReporting.load();
}

// Logging
import * as Sentry from '@sentry/browser';
import { Drivers } from '@ionic/storage';
import { TabsPage } from './pages/tabs/tabs.page';
Sentry.init({
  dsn: APPCONFIG.sentry.key,
  environment: APPCONFIG.sentry.environment,
  ignoreErrors: ['cordova_not_available']
});
export class SentryIonicErrorHandler extends ErrorHandler {
  handleError(error) {
    super.handleError(error);
    try {
      // Sentry.captureException(error.originalError || error);
    } catch (e) {
     // console.error(e);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    TabsPage
  ],
  entryComponents: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'app-root' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserTransferStateModule,
    IonicModule.forRoot(APPCONFIG.ionicConfig),
    IonicStorageModule.forRoot({ 
      name: 'vuulm.db', 
      storeName: '_local', 
      dbKey: '_local',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage] 
    }),
    ServicesModule.forRoot(),
    AppRoutingModule,
    SharedModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }), !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [
    // Core
    NavParams,
    IonNav,

    // Services
    AdAppLovinService,
    AuthService,
    AuthGuard,
    OfflineGuard,
    APIService,
    IdfaService,
    UserFavoritesService,
    UserFollowingService,
    UserOfflineLibraryService,
    AlbumStatisticsService,
    UserDetailService,
    GeoLocateService,
    StatsReportingService,

    // Plugins
    StatusBar,
    SplashScreen,
    Media,
    File,
    Camera,
    MusicControls,
    SocialSharing,
    AppLovinMax,
    Idfa,
    FileTransfer,
    BackgroundMode,
    WebView,
    Network,
    FirebaseMessaging,
    AppVersion,
    Deeplinks,

    // Providers
    AudioProvider,
    AudioNativeControls,
    AudioPlayProvider,
    AudioBaseProvider,
    FcmProvider,
    {
      // Provider for APP_INITIALIZER
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [APIService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: startupStatServiceFactory,
      deps: [StatsReportingService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserTokenInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    { provide: ErrorHandler, useClass: SentryIonicErrorHandler }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
