<div class="album-header-wrapper">
    <div class="album-info">
        <ion-grid>
            <ion-row class="text-center">
                <ion-col *ngIf="showHeader" size="12">
                    <ng-container *ngIf="type == 'playlist'; else defaultArtwork">
                        <div class="grid-playlist">
                            <ul>
                                <li *ngFor="let track of album.tracks | slice:0:4; let i=index">
                                    <img [src]="cleanUrl(track.artwork, type)"/>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                    <ng-template #defaultArtwork>
                        <div class="artwork-cover" [class.explicit]="album.explicit">
                            <img [src]="cleanUrl(album.artwork, type)" *ngIf="album.artwork"/>
                        </div>
                    </ng-template>
                </ion-col>
                <ion-col size="12">
                    <div class="info">
                        <span class="title">{{album.title}}</span><br/>
                        <ng-container *ngIf="type == 'album' || type == 'podcast'">
                            <span class="name" *ngIf="user" (click)="goToUserProfile(user.username)">
                                {{user.username}}
                                <user-category *ngIf="user.verified" [iconOnly]="true" [category]="user.category"></user-category>
                            </span>
                            <br/>
                            <span class="label" *ngIf="type">{{getPlayerType(album, type) | titlecase}}</span>
                            <ng-container *ngIf="type == 'album'">
                                <span class="label" *ngIf="album.published_date"> &middot; {{album.published_date | date: 'yyyy' }}</span>
                                <span class="label" *ngIf="album.tracks && album.tracks.length > 1"> &middot; {{album.tracks?.length }} songs</span>
                                <span class="label" *ngIf="album.stats && album.stats.streams"> &middot; {{album.stats.streams | numberAbbr }} plays</span>
                                <span class="label" *ngIf="getTotalDuration()"> &middot; {{getTotalDuration() | totalDuration}}</span>
                            </ng-container>    
                        </ng-container>
                        <ng-container *ngIf="type == 'album' || type == 'playlist' ">
                            <div class="stats">
                                <div>
                                    <share-album [album]="album" [type]="type"></share-album>
                                </div>
                                <div>
                                    <add-to-favorite class="actions" *ngIf="album" [album]="album" [favorite_type]="'album'"></add-to-favorite>
                                </div>
                                <div>
                                    <ion-icon (click)="goToUserProfile(album.uid)" name="person-outline"></ion-icon>
                                </div>
                                <ng-container *ngIf="album.purchase_link; else default">
                                    <div>
                                        <ion-icon name="link-outline" (click)="presentPopover($event)"></ion-icon>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="album && album.video_link">
                                    <div (click)="goToVideoUrl(album.video_link)">
                                        <ion-icon name="logo-youtube"></ion-icon>
                                    </div>
                                </ng-container>
                                <ng-template #default>
                                    <div *ngIf="isNativePlatform()">
                                        <btn-download [album]="album"></btn-download>
                                    </div>
                                </ng-template>
                            </div>
                        </ng-container>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="isNativePlatform() || album.status == 'private' || type == 'playlist'; else defaultPlay">
                <ion-col size="6">
                    <ion-button *ngIf="!isCurrentlyPlayingAlbum(albumPlaying, album._id)" expand="block" (click)="playSong(album, album.tracks[0]._id, type)" color="dark"><ion-icon name="play" class="text-red"></ion-icon> Play</ion-button>

                    <ng-container *ngIf="isCurrentlyPlayingAlbum(albumPlaying, album._id)">
                        <ion-button expand="block" *ngIf="!isPlaying" (click)="resume()" color="dark"><ion-icon name="play" class="text-red"></ion-icon> Resume</ion-button>
                        <ion-button expand="block" *ngIf="isPlaying" (click)="pause()" color="dark"><ion-icon name="pause" class="text-red"></ion-icon> Pause</ion-button>
                    </ng-container>
                   
                </ion-col>
                <ion-col size="6">
                    <ion-button expand="block" *ngIf="!isShuffle" (click)="shuffle(true)" color="dark"><ion-icon name="shuffle"></ion-icon> Shuffle</ion-button>
                    <ion-button expand="block" *ngIf="isShuffle" (click)="shuffle(false)" color="dark"><ion-icon name="shuffle"  class="text-red"></ion-icon> Shuffle</ion-button>
                </ion-col>
            </ion-row>
            <ng-template #defaultPlay>
                <ion-row>
                    <ion-col size="12" class="ion-text-center">
                        <ion-button (click)="goToApp(type)" color="dark"><ion-icon name="play" class="text-red"></ion-icon> Listen on the app</ion-button>
                        <ion-label color="light" (click)="playSong(album, album.tracks[0]._id, type)">Maybe later</ion-label>
                    </ion-col>
                </ion-row>
            </ng-template>
        </ion-grid>
    </div>
</div>