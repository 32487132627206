<ion-header translucent="true">
    <ion-toolbar>
        <ion-title>Artist Bio</ion-title>
        <ion-buttons slot="end" (click)="dismiss()">
            <ion-icon name="close" class="close-btn"></ion-icon>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
     <div class="bio" [innerHtml]="profile.bio"></div>
     <div class="source" *ngIf="profile.bioSource">
        <em>Artist bio provided by {{ profile.bioSource }}</em>
     </div>
</ion-content>
<ion-footer>&nbsp;</ion-footer>