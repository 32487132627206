import { Injectable } from '@angular/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

//expose analytics variable
declare var analytics;
@Injectable({
  providedIn: 'root'
})
export class AnalyticsTrackingService {
    constructor(readonly store: Store<AppState>) {
        this.store.select(state => state)
            .subscribe((response: any) => {
                const player = response.player;
                const auth = response.auth;

                if (player && player.queue !== null) {
                    const album = player.queue;
                    const albumId = player.currentSong.albumId;
                    const trackId = player.currentSong.trackId;
                    const type = player.currentSong.type;
                    let userId = null;

                    if (auth && auth.uid) {
                        userId = auth.uid;
                    }
                    this._trackAlbumAnalytics(album, `play ${type}`, trackId, userId, type);
            }
        });
    }

    init() {
        this.store.select(state => state.user)
        .subscribe((user: any) => {
          if (user && user.profile) {
            this.identify(user.profile);
          }
        });
    }

    identify(user) {
        const traits = {
            userId: `${user._id}`,
            email: user.emailAddress,
            traits: {
                username: user.username,
                email: user.emailAddress,
                firstName: user.firstName,
                lastName: user.lastName,
                createDate: user.created_date,
                category: user.category[0]
            },
        };
        analytics.identify(`${user._id}`, traits);
    }

    _trackAlbumAnalytics(album, eventname, trackId, userId, type?) {
        let trackName = album.tracks.filter(x => x._id == trackId);
        let trackIndex = album.tracks.findIndex(x => x._id == trackId);
        const event = {
          type: 'track',
          event: eventname,
          timestamp: new Date(),
          userId: userId,
          properties: {
            category: (album.type && album.type[0]) ? album.type[0] : type,
            albumName: album.name,
            albumTitle: album.title,
            albumId: album._id,
            type: (album.type && album.type[0]) ? album.type[0] : type,
            trackId: trackId,
            trackName: `${trackName[0].title}`,
            currentTrackId: trackIndex + 1,
            numberOfTracks: album.tracks.length
          }
        };    
        analytics.track(event);
      }
}