import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

// NGRX
import { Store } from '@ngrx/store';

// Services
import {  AppState, UserFavoritesService, UserPlaylistsService } from '@vuulm/core';

// Pages
import { LoginComponent } from '../../login/login.component';

@Component({
  selector: 'add-to-favorite',
  templateUrl: 'favorite.html',
  providers: [ UserFavoritesService, UserPlaylistsService ]
})
export class AddToFavoriteComponent {
  @Input() album: any;
  @Input() trackId?: string;
  // tslint:disable-next-line: variable-name
  @Input() favorite_type?: string;

  private isLoggedIn = false;
  public userFavoritesList: any = [];
  public userFavoritesAlbumList: any;

  constructor(
      private modal: ModalController,
      private userFavoritesService: UserFavoritesService,
      private userPlaylistsService: UserPlaylistsService,
      readonly store: Store<AppState>
  ) {
    this.store.select(state => state.auth)
        .subscribe((session: any) => {
            if (session.jwt !== null && session.uid !== null) {
                this.isLoggedIn = true;
            } else {
                this.isLoggedIn = false;
            }
        });

    // check if user favorites exists
    // get favorites from store
    this.store.select(state => state.favorites)
      .subscribe(favorite => {
        this.userFavoritesList = favorite.list;
        this.userFavoritesAlbumList = favorite.albums;
      });
  }

  addToFavorite(albumId, trackId) {
    if (this.isLoggedIn) {
      this.createFavorite(albumId, trackId);
    } else {
      this.loginModal();
    }
  }

  addAlbumFavorite(album) {
    if (this.isLoggedIn) {
      this.createFavoriteAlbum(album);
    } else {
      this.loginModal();
    }
  }


  createFavorite(albumId, trackId) {
    if (!this.isLoggedIn) {
      this.loginModal();
      return false;
    }
    const trackInfo = {
        trackId: `${trackId}`,
        albumId: `${albumId}`
    };

    // create new list
    if (!this.userFavoritesList) {
      const newList = {  title: 'Likes', type: 'favorite'};
      this.userPlaylistsService.post(newList)
        .subscribe((r: any) => {
          this.addToFavoriteList(trackInfo);
        });
    } else {
      this.addToFavoriteList(trackInfo);
    }
  }

  createFavoriteAlbum(album) {
    if (!this.isLoggedIn) {
      this.loginModal();
      return false;
    }

    const albumInfo = {
      albumId: album._id
    };

    // create new list
    if (!this.userFavoritesAlbumList) {
      const newList = {  title: 'Albums', type: 'album'};
      this.userPlaylistsService.post(newList)
        .subscribe((r: any) => {
          this.addAlbumToFavoriteList(albumInfo);
        });
    } else {
      this.addAlbumToFavoriteList(albumInfo);
    }
  }

  isFavorite(trackId) {
    if (this.userFavoritesList) {
      const fav = this.userFavoritesList.filter(t => t.trackId === trackId);
      if (fav.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  isAlbumFavorite(albumId) {
    if (this.userFavoritesAlbumList) {
      const fav = this.userFavoritesAlbumList.filter(t => t.albumId === albumId);
      if (fav.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  addToFavoriteList(trackInfo) {
    this.userFavoritesService.put(trackInfo)
      .subscribe((r: any) => {
        this.store.dispatch({type: 'user/FAVORITES', payload: r.tracks});
      });
  }

  removeFavorite(trackId) {
    if (this.userFavoritesList) {
      const fav = this.userFavoritesList.filter(t => t.trackId === trackId);
      if (fav.length > 0) {
        this.userFavoritesService.deleteTrack(fav[0]._id)
        .subscribe((r: any) => {
          this.store.dispatch({type: 'user/FAVORITES', payload: r.tracks});
        });
      }
    }
  }


  addAlbumToFavoriteList(albumInfo) {
    this.userFavoritesService.addFavoriteAlbum(albumInfo)
      .subscribe((r: any) => {
        this.store.dispatch({type: 'user/FAVORITES_ALBUMS', payload: r.tracks});
      });
  }

  removeFavoriteAlbum(album) {
    this.userFavoritesService.removeFavoriteAlbum(album._id)
      .subscribe((r: any) => {
        this.store.dispatch({type: 'user/FAVORITES_ALBUMS', payload: r.tracks});
      });
  }

  async loginModal() {
    const modal = await this.modal.create({
      component: LoginComponent
    });
    return await modal.present();
  }
}
