import { NgModule, ModuleWithProviders } from '@angular/core';
import { ApiPlatformService } from './platform.service';
import { JsonLdService } from './jsonld.service';

import { ContentContentfulService } from './content';
import { UserUploadService, UserDownloadService } from './users';
import { EmailService } from './email';
import { OfflineManagerDbService } from './offline-manager';
import { NetworkService } from './network/network.service';
import { StorageService } from './storage/storage.service';
import { AnalyticsTrackingService } from './analytics.service';
import { DeepLinkingService } from './deeplinks.service';
import { AuthenticationService } from './authentication.service';
@NgModule({
  imports: [ ],
  declarations: []
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [
        ApiPlatformService,
        ContentContentfulService,
        EmailService,
        UserUploadService,
        UserDownloadService,
        OfflineManagerDbService,
        NetworkService,
        StorageService,
        JsonLdService,
        AnalyticsTrackingService,
        DeepLinkingService,
        AuthenticationService
      ]
    };
  }
}
