<ion-header>
    <ion-toolbar>
        <ion-title>Queue</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" [scrollEvents]="true">
    <ng-container *ngIf="album">
        <ng-container *ngFor="let track of album.tracks">
            <ng-container *ngIf="album.tracks">
                <div class="album-tracklist" [id]="track._id">
                    <div class="artwork" [class.playing]="trackPlaying == track._id">
                        <img *ngIf="albumPlayerType == 'album'" src="{{album.artwork}}?resize=45,45" class="cover">
                        <img *ngIf="albumPlayerType == 'podcast'" src="{{album.artwork}}?resize=45,45" class="cover">
                        <img *ngIf="albumPlayerType == 'playlist'" src="{{track.artwork}}?resize=45,45" class="cover">
                        <ion-icon *ngIf="trackPlaying == track._id" class="icon" name="volume-high"></ion-icon>
                    </div>
                    <div class="info" (click)="playSong(album, track._id, album.type)">
                        <small *ngIf="trackPlaying == track._id" class="playing-label">NOW PLAYING</small>
                        <strong class="title">{{track.title}}</strong><br/>
                        <ng-container *ngIf="albumPlayerType == 'album'">
                            <small class="name muted">{{ album.name | ellipsis:24 }}
                                <ng-container *ngIf="track.tags && track.tags.length > 0">
                                    <ng-container *ngFor="let tag of track.tags">
                                        {{tag.name}}
                                    </ng-container>
                                </ng-container>
                            </small>
                        </ng-container>
                        <ng-container *ngIf="albumPlayerType == 'playlist'">
                            <small class="name muted">{{ track.artistName | ellipsis:24 }}</small>
                        </ng-container>
                    </div>
                    <div class="action">
                        <album-details [album]="album" [type]="albumPlayerType" [trackId]="track._id" [showFavorite]="true"></album-details>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</ion-content>