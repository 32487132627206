import { Component, Input } from '@angular/core';
import { AudioProvider } from '../../../../providers/audio/audio';

// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

@Component({
  selector: 'player-controls',
  templateUrl: 'controls.html',
  styleUrls: ['./controls.scss']
})
export class PlayerAudioControlsComponent {
  public isBrowser: boolean;
  public repeat = false;
  public isShuffle = false;

  @Input() album: any;
  @Input() albumPlayerType: string;

  constructor(
        private audioProvider: AudioProvider,
        readonly store: Store<AppState>
    ) {
    this.store.select(state => state.shuffle)
        .subscribe((response: any) => {
          this.isShuffle = response.shuffle;
        });
  }

  previous() {
      this.audioProvider.prevTrack();
  }

  play() {
      this.audioProvider.play();
  }

  pause() {
      this.audioProvider.pauseTrack();
  }

  next() {
      this.audioProvider.nextTrack();
  }

  repeatTrack() {
      this.repeat = !this.repeat;
      this.audioProvider.repeatTrack(this.repeat);
  }

  enableShuffle(toggle) {
    this.isShuffle = toggle;
    if (toggle) {
        this.store.dispatch({type: 'player/SHUFFLE_ENABLED'});
    } else {
        this.store.dispatch({type: 'player/SHUFFLE_DISABLED'});
    }
  }
}
