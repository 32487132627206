import { Component, OnInit, Input } from '@angular/core';

// Routing
import { Router } from '@angular/router';
@Component({
  selector: 'app-content-playlists',
  templateUrl: './playlists.html',
  styleUrls: ['./playlists.scss']
})
export class ContentPlaylistsComponent implements OnInit {
    @Input() playlists: any[] = [];

    constructor(private router: Router) {}
    ngOnInit() {}

    cleanUrl(url) {
        const clean = encodeURI(url);
        return clean;
    }

    goToPage(id) {
        this.router.navigate([`/playlists/${id}`]);
    }

}
