import { Component, OnInit } from '@angular/core';
import { AudioProvider } from '../../../../providers/audio/audio';

@Component({
  selector: 'player-duration',
  templateUrl: 'duration.html',
  styleUrls: ['./duration.scss'],
})
export class PlayerDurationComponent implements OnInit {
  public current = '0:00';
  public duration = '0:00';
  public elapsed = 0;

  constructor(private audioProvider: AudioProvider) {}

  ngOnInit() {
    this.audioProvider.getTimeElapsed()
      .subscribe((time: any) => {
          this.current = time;
      });

    this.audioProvider.getTimeRemaining()
      .subscribe((duration) => {
          this.duration = duration;
      });

    this.audioProvider.getPercentElapsed()
      .subscribe((time: any) => {
        this.elapsed = time;
      });

  }

  seekTo(e) { 
    this.audioProvider.seek(e);
  }
}

