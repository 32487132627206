import { Component, Input } from '@angular/core';
import { IonNav } from '@ionic/angular';

// Router
import { Router } from '@angular/router';

// Component
import { DetailComponent } from '../../../pages/albums/detail/detail.component';
@Component({
  selector: 'album-list',
  templateUrl: 'album-list.html',
  styleUrls: ['./album-list.scss']
})
export class AlbumListComponent {
  @Input() albums: any;
  @Input() type?: any;
  @Input() title?: string;
  @Input() showArtwork ? = true;
  @Input() showTrackTitle ? = false;
  @Input() showFavorite ? = true;
  @Input() layout = 'list';
  @Input() pushToStack: boolean = false;

  public albumDetailPage = DetailComponent;
  constructor(
    private router: Router,
    public ionNavLink: IonNav
  ) { }

  cleanUrl(url) {
    const clean = encodeURI(url);
    return clean;
  }

  isAlbumRecent(publishedDate) {
    const d = new Date();
    const p = new Date(publishedDate);
    p.setDate(p.getDate());
    d.setDate(d.getDate() -  2); // check if less than 2 days
    if (p >= d) {
        return true;
    } else {
        return false;
    }
  }

  goToAlbum(albumId) {
    if (this.pushToStack) {
      this.ionNavLink.push(this.albumDetailPage, { albumid: albumId});
    } else {
      this.router.navigate([`/albums/${albumId}`]);
    } 
   
  }
}
