<ng-container *ngIf="albums && albums.length > 0">
    <ng-container *ngIf="layout == 'grid'; else default">
        <div class="square-container">
            <div class="square" [class.explicit]="a.explicit" (click)="goToAlbum(a.permalink)" *ngFor="let a of albums">
                <img *ngIf="!a.artwork" src="https://via.placeholder.com/150/1B1B1C/030303" />
                <img *ngIf="a.artwork" src="{{a.artwork}}?resize=300,300" />
                <strong class="title">{{a.title}}</strong><br/>
                <small class="muted" *ngIf="a.name">{{ a.name | ellipsis:24}}</small>
            </div>
        </div>
    </ng-container>
    <ng-template #default>
        <div *ngFor="let a of albums" class="album-list">
            <div class="artwork" (click)="goToAlbum(a.permalink)" [class.explicit]="a.explicit">
                <img src="{{a.artwork}}?resize=85,85" class="cover" [class.blur]="a.explicit">
            </div>
            <div class="info" (click)="goToAlbum(a.permalink)">
                <ng-container *ngIf="showTrackTitle">
                    <strong class="title">{{a.tracks.title}}</strong><br/>
                    <small class="name muted">{{ a.name | ellipsis:24 }}</small>
                    <div class="stats">
                        <div *ngIf="a.tracks.stats && a.tracks.stats.streams"><ion-icon name="play" class="muted"></ion-icon> {{a.tracks.stats.streams | numberAbbr}}</div>
                        <div *ngIf="a.tracks.stats && a.tracks.stats.streams"><ion-icon name="heart" class="muted"></ion-icon> {{a.tracks.stats.favorites | numberAbbr}}</div>
                        <div *ngIf="a.tracks.stats && a.tracks.stats.streams"><ion-icon name="arrow-redo" class="muted"></ion-icon> {{a.tracks.stats.reposts | numberAbbr}}</div>
                    </div>
                </ng-container>
                
                <ng-container *ngIf="!showTrackTitle">
                    <div *ngIf="a.featured" class="featured">
                        <span class="label">Featured</span>
                    </div>
                    <strong class="title">{{a.title}}</strong><br/>
                    <small class="name muted">{{ a.name | ellipsis:24 }}</small>
                    <div class="stats">
                        <div *ngIf="a.stats && a.stats.streams"><ion-icon name="play" class="muted"></ion-icon> {{a.stats.streams | numberAbbr}}</div>
                        <div *ngIf="a.stats && a.stats.streams"><ion-icon name="heart" class="muted"></ion-icon> {{a.stats.favorites | numberAbbr}}</div>
                        <div *ngIf="a.stats && a.stats.streams"><ion-icon name="arrow-redo" class="muted"></ion-icon> {{a.stats.reposts | numberAbbr}}</div>
                    </div>
                </ng-container>
            </div>
            <div class="action">
                <album-details [album]="a" [type]="type" [showFavorite]="showFavorite" [pushToStack]="pushToStack"></album-details>
            </div>
        </div>
    </ng-template>
</ng-container>