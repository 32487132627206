import { Injectable } from '@angular/core';
import { createClient, Entry } from 'contentful';
import { ApiPlatformService } from '../platform.service';

const CONFIG = {
    space: '5a6v4iuw2rds',
    accessToken: '0hGpw_Fa4WeAiEQHAtZ1a8drFCeVknYJsQgQio4kjnQ',
    contentTypeIds: {
      collections: 'collections',
      collectionItems: 'collectionItems',
      featured: 'featured',
      banner: 'alertMessage',
      originals: 'orginialSeries'
    }
};

@Injectable()
export class ContentContentfulService {
    private cdaClient = createClient({
        space: CONFIG.space,
        accessToken: CONFIG.accessToken
    });
    constructor(private api: ApiPlatformService) {}

    getFeaturedCollection(query?: object): Promise<Entry<any>[]> {
        return this.cdaClient.getEntries(Object.assign({
          content_type: CONFIG.contentTypeIds.featured
        }, query))
        .then(res => res.items);
    }

    getBannerAlerts(query?: object): Promise<Entry<any>[]> {
      return this.cdaClient.getEntries(Object.assign({
        content_type: CONFIG.contentTypeIds.banner
      }, query))
      .then(res => res.items);
    }

    getOriginals(query?: object): Promise<Entry<any>[]> {
      return this.cdaClient.getEntries(Object.assign({
        content_type: CONFIG.contentTypeIds.originals
      }, query))
      .then(res => res.items);
    }

    getCollections(query?: object): Promise<Entry<any>[]> {
        return this.cdaClient.getEntries(Object.assign({
          content_type: CONFIG.contentTypeIds.collections
        }, query))
        .then(res => res.items);
    }

    getEntry(assetId) {
        return this.cdaClient.getEntry(assetId)
        .then(res => res);
    }

    async getSeries(query?: object): Promise<any> {
      return this.cdaClient
        .getEntries(
          Object.assign(
            {
              content_type: CONFIG.contentTypeIds.originals,
            },
            query,
          ),
        )
        .then((res) => res.items);
    }

    async getPlaylistCollection(query?: object): Promise<any> {
      return this.cdaClient
        .getEntries(
          Object.assign(
            {
              content_type: CONFIG.contentTypeIds.collectionItems,
            },
            query,
          ),
        )
        .then((res) => res.items);
    }

    getBlogPost(query?: object) {
      return this.api.get('/content/posts', query);
    }

    getPage(query?: object) {
      return this.api.get('/content/pages', query);
    }
}
