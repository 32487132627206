import { Injectable } from '@angular/core';
import { Router, CanActivateChild } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';
import { map } from 'rxjs/operators';

// Pages
import { LoginComponent } from '../../shared/login/login.component';

@Injectable()
export class AuthGuard implements CanActivateChild {
    constructor(
        private router: Router,
        readonly store: Store<AppState>,
        public modal: ModalController,
    ) { }

    canActivateChild() {
        return this.store.select(state => state.auth)
            .pipe(map(auth => {
                if (auth && auth.jwt && auth.uid) {
                    return true;
                } else {
                    this.loginModal();
                    return false;
                }
            }));
    }

    async loginModal() {
        const modal = await this.modal.create({
            component: LoginComponent
        });
        return await modal.present();
    }
}
