import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { IonNav, Platform } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';

// NGRX
import { Store } from '@ngrx/store';

// Routing
import { ActivatedRoute } from '@angular/router';

// Services
import {  AppState, SeoService, AlbumService, UserDetailService } from '@vuulm/core';
import { JsonLdService } from '../../../services/jsonld.service';

// Component
import { ProfileComponent } from '../../profile/profile.component';
import { ProfileAlbumsComponent } from '../../profile/albums/albums.component';
import { ContentContentfulService } from '../../../services/content';

@Component({
  selector: 'app-landing-album-detail',
  template: '<ion-nav [root]="rootPage" [swipeGesture]="true"></ion-nav>'
})
export class LandingAlbumDetailComponent {
  public rootPage = DetailComponent;
}
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  public albumId: string;
  public albumDetails: any;
  public albumBackground: string;
  public relatedAlbums: any;
  public userAlbums: any;
  public profile: any;
  public pageContentRelated: any;
  public loading = true;
  public privateKey: null;
  public adjustedHeight = '100px';
  public artworkColors: any = "rgba(0, 0, 0, 50%)";
  public albumFound = {
    status: true,
    icon: 'alert',
    title: 'Sorry this album is no longer availble.',
    description: 'This album may be removed or no longer exists.'
  }

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _renderer2: Renderer2,
    public navParams : NavParams,
    private platform: Platform,
    private route: ActivatedRoute,
    private albumService: AlbumService,
    private userDetailService: UserDetailService,
    private seo: SeoService,
    readonly store: Store<AppState>,
    private ionNavLink: IonNav,
    private jsonLdService: JsonLdService,
    private content: ContentContentfulService,
  ) { }

  async ngOnInit() {
    // get params from nav stack or router
    if (this.navParams.get('albumid')) {
      const albumId = this.navParams.get('albumid');
      this.getAlbumDetails(albumId);
    } else {
      this.route.params.subscribe(async (params) => {
        // tslint:disable-next-line: no-string-literal
        const albumId = params['albumid'];
        await this.route.queryParams.subscribe((p) => {
          this.privateKey = p['k'];
        });
        this.getAlbumDetails(albumId);
      });
    }
  }

  getAlbumDetails(albumId: string) {
    let q: any;
    if (this.privateKey) {
      q = { key: this.privateKey }
    }
    this.albumService.getById(albumId, q).subscribe(async (r: any) => {
      if (r) {
        this.loading = false;
        this.albumDetails = r[0];
        this.getArtworkColors();
        // add current album details to store to track pageviews
        this.store.dispatch({type: 'album/DETAIL', payload: this.albumDetails});

        this.albumBackground = encodeURI(this.albumDetails.artwork);
        this.getUserDetails(this.albumDetails.uid);
        this.getUserAlbums(this.albumDetails.uid);
        const artistName = this.getArtistTag();
        if (this.albumDetails.status == 'published' && artistName) {
          this.getRelatedAlbums();
        }
      }
    }, (error: any) => {
      if (error && error.status === 404) {
        this.loading = false;
        this.albumFound.status = false;
        if (this.privateKey) {
          this.albumFound.icon = 'lock-closed';
          this.albumFound.title = 'This album is private';
          this.albumFound.description = 'Contact owner to grant you access';
        }
      }
    });
  }

/**
 * getArtworkColors - get color scheme for album
 * @param albumId
 */
 async getArtworkColors() {
    await this.albumService.colors(this.albumDetails._id)
      .subscribe((r: any) => {
        if (r) {
          this.artworkColors = `rgba(${r.value[0]}, ${r.value[1]}, ${r.value[2]}, 50%)`;
        }
      })
  }

  /**
   * imageLoaded - check if main image is loaded 
   * @param evt 
   */
  imageLoaded(evt) {
    this.adjustedHeight = evt.target.height + 20 + 'px';
  }

  getUserDetails(userid) {
    if (userid) {
      this.userDetailService
        .get(userid)
        .subscribe((r: any) => {
            if (r) {
                this.profile = r;
                this.generateSeoTags();
                this.getRelatedEntries(this.profile);
            }
        });
    }
  }

  getUserAlbums(userId) {
    this.albumService.get({uid: userId, count: 8})
      .subscribe((r: any) => {
        if (r) {
          this.userAlbums = r;
        }
      });
  }

  getArtistTag() {
    if (this.albumDetails.tags && this.albumDetails.tags.length > 0 && this.albumDetails.tags[0].name) {
      return this.albumDetails.tags[0].name;
    } else {
      return this.albumDetails.name;
    }
  }

  getRelatedEntries(profile) {
    this.content.getBlogPost({'fields.profileIds[in]': profile._id})
    .subscribe((data) => {
      if (data) {
        this.pageContentRelated = data;
      }
    });
  }

  generateSeoTags() {
      this.seo.generateTags({
        title: `${this.albumDetails.name} - ${this.albumDetails.title} on Vuulm`,
        description: `${this.albumDetails.name} latest album "${this.albumDetails.title}" on Vuulm`,
        image: encodeURI(this.albumDetails.artwork),
        slug: encodeURI(this.albumDetails.permalink),
        keywords: `${this.albumDetails.name}, ${this.albumDetails.title}, mixtapes, music, vuulm, hip-hop music`
      });

      this.seo.generateAlbumTags(this.albumDetails, this.profile);

      this.setCanonicalUrl();

      const ldAlbum = this.jsonLdService.getAlbumLdObject(this.albumDetails, this.profile);
      this.setJsonLd(ldAlbum);
  }

  goToPage(value) {
    if (value.userid) {
      this.ionNavLink.push(ProfileComponent, { userid: value.userid});
    }
  }

  goToProfile() {
    if (!this.profile) {
      return false;
    }
    this.ionNavLink.push(ProfileComponent, { userid: this.profile.username});
  }

  goToUserRelatedAlbums() {
    this.ionNavLink.push(ProfileAlbumsComponent, { relatedAlbumId: this.albumDetails._id, userid: this.profile._id });
  }

  getRelatedAlbums() {
    this.albumService.getRelatedAlbums(this.albumDetails._id)
      .subscribe((r: any) => {
        if (r) {
          this.relatedAlbums = r.filter(x => x._id !== this.albumDetails._id);
        }
      });
  }

  private setJsonLd(data: any): void {
    if (!data) {
      return null;
    }

    let script = this._renderer2.createElement('script');
    script.type = 'application/ld+json';
    script.text = `${JSON.stringify(data)}`;
    this._renderer2.appendChild(this._document.head, script);
  }

  private setCanonicalUrl() {
    var link = !!this._document.querySelector("link[rel='canonical']") ? this._document.querySelector("link[rel='canonical']") : this._document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', `https://www.vuulm.com/albums/${encodeURI(this.albumDetails.permalink)}`);
    this._document.head.appendChild(link);
  }
}
