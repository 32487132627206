import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Ionic Native Controls
// import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import * as MusicControls from 'cordova-plugin-music-controls2/www/MusicControls';

import { Platform } from '@ionic/angular';
import { Media } from '@awesome-cordova-plugins/media/ngx';

import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

import { AudioPlayProvider } from './play';

/**
 * Audio Provider -
 * audio provider is a service that maintains the
 * state of an audio file needed for the native device and components
 */
@Injectable({
    providedIn: 'root'
  })
export class AudioNativeControls extends AudioPlayProvider {
    trackId: string;
    album: any;
    albumId: string;
    albumPlayerType: string;
    currentTrackIndex: number;
    shuffle = false;
    repeat = false;
    public $musicControlState = new BehaviorSubject<any>(null);
    constructor(
        public platform: Platform,
        public media: Media,
        readonly store: Store<AppState>,
        // public musicControls: MusicControls
    ) {
        super(platform, media, store);
    }

    setNativeMusicControls(duration = 0) {
        if (this.platform.is('cordova')) {
            const track = this.album.tracks.find(x => x._id === this.trackId);
            const albumName = this.getAlbumName(this.album, track._id);
            if (albumName) {
                this.nativeControls(this.album, track.title, albumName, duration);
            }
        }
    }

    updateElapsed(current, playing) {
        if (this.platform.is('cordova')) {
            MusicControls.updateElapsed({
                elapsed: current,
                isPlaying: playing
            });
        }
    }

    /**
     * nativeControls - set native audio controls on device
     * @param album - album object
     * @param trackTitle - the album title or album title of playlist
     * @param albumName - the album name or song title of playlist
     */
    nativeControls(album, trackTitle, albumName, dur: number = 0) {
        MusicControls.create({
            track: `${trackTitle}`,
            artist: `${albumName}`,
            cover: `${album.artwork}`,
            isPlaying: true,
            dismissable: false,

            // hide previous/next/close buttons:
            hasPrev: true,      // show previous button, optional, default: true
            hasNext: true,      // show next button, optional, default: true
            hasClose: true,       // show close button, optional, default: false

            // iOS only, optional
            album: `${album.title}`,     // optional, default: ''
            duration: dur, // optional, default: 0
            elapsed: 0,
            hasScrubbing: false, // enable scrubbing from control center and lockscreen progress bar, optional

            // Android only, optional
            // text displayed in the status bar when the notification (and the ticker) are updated, optional
            ticker: `${trackTitle}`
        },
        (success) => console.log(success),
        (error) => console.log(error));

        MusicControls.subscribe(x => this.$musicControlState.next(x));
        MusicControls.listen();
        MusicControls.updateIsPlaying(true);

    }

    public updateIsPlaying(isPlaying: boolean = true) {
        if (this.platform.is('cordova')) {
            MusicControls.updateIsPlaying(isPlaying);
        }
    }

    public listen() {
        MusicControls.listen();
    }
}
