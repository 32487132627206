import { Injectable } from '@angular/core';
import { AuthService } from '@vuulm/core';
import { Platform } from '@ionic/angular';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

import * as config from '../../config';

// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

// Services
import { OfflineManagerDbService } from '../offline-manager';

@Injectable({
    providedIn: 'root',
})
export class UserDownloadService {
    fileTransfer: FileTransferObject;
    constructor(
        private auth: AuthService,
        public platform: Platform,
        private transfer: FileTransfer,
        private file: File,
        private offlineManagerDbService: OfflineManagerDbService,
        readonly store: Store<AppState>
    ) {
        this.fileTransfer = this.transfer.create();
    }

    async downloadFile(albumDetails): Promise<any> {
        // Store the album object
        const offlineAlbum = albumDetails;

        const tracks = await this.downloadTracks(albumDetails).then((r) => r);
        if (tracks && tracks.length > 0) {
            offlineAlbum.tracks = tracks;
        }

        const artwork = await this.downloadArtwork(albumDetails).then((r) => r);
        if (artwork) {
            offlineAlbum.artwork = artwork;
        }

        // Store album object offline
        this.offlineManagerDbService.addAlbumsToLocal(
            albumDetails._id,
            offlineAlbum
        );

        this.updateDownloadState();

        return true;
    }


    public async getDownloadPath() {
        if (this.platform.is('ios')) {
            return this.file.documentsDirectory;
        } else {
            return this.file.applicationStorageDirectory;
        }
    }


    async updateDownloadState() {
        const albums = await this.offlineManagerDbService.getAlbums()
            .then((data: any) => {
                if (data && data.length > 0) {
                    return data;
                } else {
                    return [];
                }
            });
        this.store.dispatch({ type: 'downloads/ALBUMS', payload: albums});
    }

    getFilePath() {
        if (this.platform.is('ios')) {
            return this.file.documentsDirectory;
        } else {
            return this.file.applicationStorageDirectory;
        }
    }

    /**
     * downloadTracks() - download tracks from album
     * @param albumDetails - album
     * @returns tracks array as Promise
     */
    async downloadTracks(albumDetails): Promise<any> {
        // download tracks
        const offlineTracks = [];
        const path = this.getFilePath();
        if (albumDetails && albumDetails.tracks.length > 0) {
            let i = 0;
            for (const track of albumDetails.tracks) {
                // Initalize track details
                const offlineTrackDetails = track;

                const mediaDirectoryPath = `vuulm/albums/${albumDetails._id}/tracks/${track._id}.mp3`;
                await this.fileTransfer
                    .download(
                        encodeURI(track.trackUrl),
                        path + mediaDirectoryPath,
                        false // trustAllHosts
                    )
                    .then(
                        (entry) => {
                            // console.log('download complete: ' + entry.toURL());
                            offlineTrackDetails.trackUrl = mediaDirectoryPath; // change path to local path directory
                            offlineTracks.push(offlineTrackDetails);
                        },
                        (error) => {
                            console.log(error);
                            offlineTrackDetails.trackUrl = null; // set to null if unavailable
                            offlineTracks.push(offlineTrackDetails);
                        }
                    );
                i++;
            }

            if (i === albumDetails.tracks.length) {
                return offlineTracks;
            }
        } else {
            return offlineTracks;
        }
    }

    /**
     * downloadArtwork() - download artwork from album
     * @param albumDetails - album
     * @returns string as Promise
     */
    async downloadArtwork(albumDetails): Promise<any> {
        // download artwork
        let artwork = null;
        const path = this.getFilePath();
        const artworkDirectoryPath = `vuulm/albums/${albumDetails._id}/artwork/${albumDetails._id}.jpg`;
        await this.fileTransfer
            .download(
                `${encodeURI(albumDetails.artwork)}`,
                path + artworkDirectoryPath,
                false
            )
            .then(
                (entry) => {
                    artwork = artworkDirectoryPath;
                },
                (error) => {
                    console.log(error);
                }
            )
            .catch((e) => {
                console.log(e);
            });

        return artwork;
    }

    async removeDirectory(albumId): Promise<void> {
        const path = this.getFilePath();
        await this.file
            .removeRecursively(path + 'vuulm/albums', `${albumId}`)
            .then((res) => {
                // remove from local storage
                this.offlineManagerDbService.removeAlbum(albumId);

                // update album state
                this.updateDownloadState();
                return res;
            });
    }

    async doesFileDirectoryExist(albumId): Promise<void> {
        let directoryExists;
        await this.file
            .checkDir(this.getFilePath()	, `vuulm/albums/${albumId}`)
            .then((_) => {
                console.log(`Directory ${albumId} exists`, _);
                directoryExists = true;
            })
            .catch((err) => {
                console.log(`Directory ${albumId} doesn\'t exist`, err);
                directoryExists = false;
            });

        return directoryExists;
    }
}
