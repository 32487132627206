<div>
    <div lines="none">
        <div scrollX="true" class="{{size}}" scroll-avatar>
            <ng-container *ngIf="type == 'album'">
                <div class="scroll-item" *ngFor="let t of albums" [class.explicit]="t.explicit">
                    <img *ngIf="t.artwork" src="{{t.artwork}}?resize=250,250" (click)="goToAlbum(t.permalink)"/>
                    <img *ngIf="!t.artwork" src="https://via.placeholder.com/150/000000/FFFFFF?text={{t.title | ellipsis:15 }}" />
                    <strong class="title">{{t.title | ellipsis:18}}</strong>
                    <small *ngIf="t.name">{{t.name  | ellipsis:15}}</small>
                    <ng-container *ngIf="showCategory && t.category">
                        <ion-label color="warning">{{t.category[0]  | ellipsis:15}}</ion-label>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="type == 'podcast'">
                <div class="scroll-item" *ngFor="let t of albums">
                    <img *ngIf="t.artwork" src="{{t.artwork}}?resize=250,250" (click)="goToPodcast(t.permalink)"/>
                    <img *ngIf="!t.artwork" src="https://via.placeholder.com/150/000000/FFFFFF?text={{t.title | ellipsis:15 }}" />
                    <strong class="title">{{t.title | ellipsis:18}}</strong>
                    <small *ngIf="t.name">{{t.name  | ellipsis:15}}</small>
                    <ng-container *ngIf="showCategory && t.category">
                        <ion-label color="warning">{{t.category[0]  | ellipsis:15}}</ion-label>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="type == 'topTracks'">
                <div class="scroll-item" *ngFor="let t of albums" [class.explicit]="t.explicit">
                    <img *ngIf="t.artwork" src="{{t.artwork}}?resize=250,250" (click)="goToAlbum(t.permalink)"/>
                    <img *ngIf="!t.artwork" src="https://via.placeholder.com/150/000000/FFFFFF?text={{t.tracks.title | ellipsis:15 }}" />
                    <ng-container *ngIf="t.tracks">
                        <strong class="title">{{t.tracks.title | ellipsis:20}}</strong>
                        <small *ngIf="t.name">{{t.name  | ellipsis:15}}</small>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="type == 'playlist'">
                <div class="scroll-item" *ngFor="let t of albums" (click)="goToPlaylist(t.permalink)" [class.explicit]="t.explicit">
                    <img *ngIf="t.artwork" src="{{t.artwork}}?resize=250,250"/>
                    <img *ngIf="!t.artwork" src="https://via.placeholder.com/150/000000/FFFFFF?text={{t.title | ellipsis:15 }}" />
                    <strong class="title">{{t.title | ellipsis:15}}</strong>
                    <small *ngIf="t.tracks">{{t.tracks.length }} songs</small>
                </div>
            </ng-container>
        </div>
    </div>
</div>