<ion-row>
    <ion-col size="12" class="ion-text-center ion-no-padding">
        <ion-range min="0" max="100" [(ngModel)]="elapsed" (ngModelChange)="seekTo($event)"></ion-range>
    </ion-col>
    <ion-col size="6" class="ion-no-padding">
        <span class="time">{{ current }}</span>
    </ion-col>
    <ion-col size="6" class="ion-text-right ion-no-padding">
        <span class="time">{{ duration }}</span>
    </ion-col>
</ion-row>

