import { Component, OnInit } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { AuthenticationService } from '../../services/authentication.service';

// Store
import { Store } from '@ngrx/store';

// Services
import { AppState } from '@vuulm/core';
@Component({
    selector: 'page-login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    constructor(
        public loadingController: LoadingController,
        readonly store: Store<AppState>,
        public auth: AuthenticationService, 
        private platform: Platform,
        public modal: ModalController,
    ) {
    }

    async ngOnInit() {
        await this.login();
    }

    async login() {
        this.platform.ready().then(async () => {
            this.auth.login();
        });
    }
}
