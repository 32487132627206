import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';
import { ColoredBrowserTabs } from '@vuulm/plugins/colored-browser-tabs/ngx';

// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

// Components
import { PlayerComponent } from '../../player/player.component';
import { AlbumLinksComponent } from '../links/links.component';

// Providers
import { AudioProvider } from '../../../providers/audio/audio';
import { AdAppLovinService } from '../../../services/ads';

// Router
import { Router } from '@angular/router';

import { File } from '@awesome-cordova-plugins/file/ngx';

declare var window: any;
@Component({
  selector: 'album-header',
  templateUrl: 'album-header.html',
  styleUrls: ['./album-header.scss']
})
export class AlbumHeaderComponent {
  public adId: string;
  public userProfile: any;
  public isPlaying = false;
  public audioSrc: any;
  public isShuffle = false;
  public repeat = false;
  public albumPlaying: any;


  @Input() album: any;
  @Input() type: string;
  @Input() user?: any;
  @Input() showHeader = true;
  @Output() navigateTo = new EventEmitter;

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private adAppLovinService: AdAppLovinService,
    private audioProvider: AudioProvider,
    readonly store: Store<AppState>,
    private router: Router,
    private sanitizer: DomSanitizer,
    private file: File,
    public popoverController: PopoverController,
    private browserTab: ColoredBrowserTabs
  ) {
    this.store.select(state => state.shuffle)
      .subscribe((response: any) => {
        this.isShuffle = response.shuffle;
      });

    this.store.select(state => state.player)
      .subscribe((response: any) => {
        if (response && response.queue !== null) {
          this.albumPlaying = response.queue;
          this.getPlayingStatus();
        }
      });
  }

  getPlayingStatus() {
    this.audioProvider.getPlayerStatus()
      .subscribe((status) => {
        if (status === 'playing') {
          this.isPlaying = true;
        } else {
          this.isPlaying = false;
        }
      });
  }

  playSong(album, trackid, type = 'album') {
    this.audioSrc = {
      queue: album,
      currentSong: {
        albumId: album._id,
        trackId: trackid,
        type: `${type}`
      }
    };

    if (type !== 'offline') {
      this.adAppLovinService.showInterstitial();
    }

    this.play();
  }

  play() {
    this.store.dispatch({ type: 'player/QUEUE', payload: this.audioSrc });
  }

  pause() {
    this.audioProvider.pauseTrack();
  }

  resume() {
    this.audioProvider.resumeTrack();
  }

  isCurrentlyPlayingAlbum(currentAlbum, albumId) {
    if (currentAlbum && currentAlbum._id === albumId) {
      return true;
    } else {
      return false;
    }
  }

  getTotalDuration() {
    const total = this.album.tracks
      .map(track => track.duration || 0)
      .reduce((sum, current) => sum + current);

      return total|| 0;
  }


  shuffle(toggle) {
    this.isShuffle = toggle;
    if (toggle) {
      this.store.dispatch({type: 'player/SHUFFLE_ENABLED'});
    } else {
      this.store.dispatch({type: 'player/SHUFFLE_DISABLED'});
    }
  }

  getPlayerType(album, type = 'album') {
    if (type == "album") {
      return album.type.toString();
    } else {
      return type;
    }
  }

  setBackgroundImage() {
    if (!this.album && this.album.artwork) {
      return false;
    }

    const artwork = this.album.artwork;

    const style = {
      'background-image': `linear-gradient(0deg, rgb(20 20 21 / 33%), rgba(0, 0, 0, 0.2) 100%), url(${this.encodeUrl(artwork)})`,
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
    };

    return style;
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: PlayerComponent
    });
    return await modal.present();
  }

  goToUserProfile(username) {
    if (!username || this.type === 'offline') {
      return false;
    }
    this.navigateTo.emit({userid: username});
  }

  cleanUrl(url, type = 'album') {
    if (url.includes('https') || url.includes('http')) {
      return this.encodeUrl(url);
    } else {
      const filePath = this.getFilePath();
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        window.Ionic.WebView.convertFileSrc(filePath + url)
      );
    }
  }

  encodeUrl(url) {
    if (url.includes('https') || url.includes('http')) {
      const clean = encodeURI(url);
      return `${clean}?resize=300,300`;
    } else {
      const filePath = this.getFilePath();
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        window.Ionic.WebView.convertFileSrc(filePath + url)
      );
    }
  }

  isAlbumRecent(publishedDate) {
    const d = new Date();
    const p = new Date(publishedDate);
    p.setDate(p.getDate());
    d.setDate(d.getDate() - 2); // check if less than 2 days
    if (p >= d) {
      return true;
    } else {
      return false;
    }
  }

  goToVideoUrl(id) {
    if (this.platform.is('cordova')) {
        this.browserTab.openTab(`https://www.youtube.com/watch?v=${encodeURI(id)}`, '#000000')
          .subscribe(() => {});
    } else {
        window.open(`https://www.youtube.com/watch?v=${encodeURI(id)}`);
    }
  }

  goToApp(type = 'album') {
    if (type == 'podcast') {
      window.open(`vuulm://app/podcasts/${encodeURI(this.album.permalink)}`);
    } else if (type == 'playlist') {
      window.open(`vuulm://app/playlists/${encodeURI(this.album.permalink)}`);
    } else {
      window.open(`vuulm://app/albums/${encodeURI(this.album.permalink)}`);
    }
  }

  getFilePath() {
    if (this.platform.is('ios')) {
      return this.file.documentsDirectory;
    } else {
        return this.file.applicationStorageDirectory;
    }
  }

  isNativePlatform() {
    if (this.platform.is('cordova')) {
      return true;
    } else {
      return false;
    }
  }

  async presentPopover(ev: any) {
    const modal = await this.modalController.create({
      component: AlbumLinksComponent,
      animated: true,
      cssClass: 'album-actionsheet-modal',
      componentProps: {
        album: this.album      }
    });
    return await modal.present();
  }
}
