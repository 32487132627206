import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

// NGRX
import { Store } from '@ngrx/store';

// Services
import { AppState } from '@vuulm/core';

// Pages
import { LoginComponent } from '../../login/login.component';
import { AddPlaylistPage } from '../add-playlist/add-playlist.component';
@Component({
  selector: 'add-to-playlist',
  templateUrl: 'add-to-playlist.html'
})
export class AddToPlaylistComponent {
  @Input('album') album: any;
  @Input('trackId') trackId: string;
  private isLoggedIn: boolean = false;
  constructor(
    public modal: ModalController,
    readonly store: Store<AppState>
  ) {
    this.store.select(state => state.auth)
      .subscribe((session: any) => {
        if (session.jwt !== null && session.uid !== null) {
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      });
  }

  addToPlaylist(albumId, trackId) {
    if (this.isLoggedIn) {
      this.addPlaylistModal(albumId, trackId);
    } else {
      this.loginModal();
    }
  }

  async addPlaylistModal(albumId, trackId) {
    const modal = await this.modal.create({
      component: AddPlaylistPage,
      componentProps: {
        albumId: albumId,
        trackId: trackId
      }
    });
    modal.present();
  }

  async loginModal() {
    const modal = await this.modal.create({
      component: LoginComponent
    });
    return await modal.present();
  }
}
