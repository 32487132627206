<ng-container *ngIf="showPlayer">
    <div class="drawer">
        <ion-grid style="padding: 0">
            <ion-progress-bar value="{{elapsed / 100}}"></ion-progress-bar>
            <ion-row>
                <ion-col size="2" tappable (click)="goToAlbum()">
                    <img [src]="getPlayerTypeArtwork()" class="card-album-artwork"/>
                </ion-col>
                <ion-col size="4" tappable (click)="presentModal()">
                    <strong *ngIf="currentTrackTitle">{{ currentTrackTitle | ellipsis:11}}</strong>
                    <ng-container *ngIf="currentTrackName">
                        <br/>
                        <small>{{ currentTrackName | ellipsis:11}}</small>
                    </ng-container>
                </ion-col>
                <ion-col size="6" class="ion-text-right actions">
                    <ion-button *ngIf="!isPlaying && !isLoading" (click)="play()" color="light" fill="clear"><ion-icon name="play"></ion-icon></ion-button>
                    <ion-button *ngIf="isPlaying && !isLoading" (click)="pause()" color="light" fill="clear"><ion-icon name="pause"></ion-icon></ion-button>
                    <ion-button (click)="next()" color="light" fill="clear"><ion-icon name="play-skip-forward-sharp"></ion-icon></ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</ng-container>