import { Component, Input } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';

// Components
import { AlbumDetailActionSheetComponent } from './album-actionsheet/album-actionsheet';

// Pages
import { ProfileComponent } from '../../../pages/profile/profile.component';
import { DetailComponent } from '../../../pages/albums/detail/detail.component';
@Component({
  selector: 'album-details',
  templateUrl: './album-details.html'
})
export class AlbumDetailSheetComponent {
  @Input() album: any;
  @Input() type: any = 'album';
  @Input() trackId?: any;
  @Input() icon = 'ellipsis-vertical-outline';
  @Input() showListeningHistory ? = false;
  @Input() showPlaylist ? = true;
  @Input() showFavorite ? = true;
  @Input() pushToStack? = false;

  constructor(
    public modal: ModalController,
    public ionNavLink: IonNav
  ) { }

  async presentModal() {
    const modal = await this.modal.create({
      component: AlbumDetailActionSheetComponent,
      animated: true,
      cssClass: 'album-actionsheet-modal',
      componentProps: {
        album: this.album,
        type: this.type,
        trackId: this.trackId,
        showAlbumHistory: this.showListeningHistory,
        showPlaylist: this.showPlaylist,
        showFavorite: this.showFavorite,
        pushToStack: this.pushToStack
      }
    });

    // wait for events that come from child component in modal
    modal.onDidDismiss()
      .then((response: any) => {
        if (response && response.data?.userid) {
          this.goToProfilePage(response.data.userid);
        }

        if (response && response.data?.albumid) { 
          this.goToAlbumPage(response.data.albumid);
        }
    });
    return await modal.present();
  }

  /**
   * goToProfilePage - push profile page to stack
   * @param userId 
   */
  private goToProfilePage(userId) {
    this.ionNavLink.push(ProfileComponent, { userid: userId});
    this.modal.dismiss();
  }

  /**
   * goToAlbumPage - push album page to stack
   * @param albumId 
   */
  private goToAlbumPage(albumId) {
    this.ionNavLink.push(DetailComponent, { albumid: albumId});
    this.modal.dismiss();
  }

}
