import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { PipeModule } from '@vuulm/core';
import { SharedActionsModule } from '../actions/actions.module';
import { SharedCommonModule } from '../common/common.module';
import { ColoredBrowserTabs } from '@vuulm/plugins/colored-browser-tabs/ngx';

// Components
import { AlbumHeaderComponent } from './album-header/album-header';
import { TracklistComponent } from './tracklisting/tracklisting';
import { AlbumLinksComponent } from './links/links.component';
@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    PipeModule,
    SharedActionsModule,
    SharedCommonModule
  ],
  declarations: [
    AlbumHeaderComponent,
    TracklistComponent,
    AlbumLinksComponent,
  ],
  entryComponents: [
    AlbumLinksComponent,
   ],
  exports: [
    SharedActionsModule,
    PipeModule,
    AlbumHeaderComponent,
    TracklistComponent,
    AlbumLinksComponent,
  ],
  providers: [ 
    ColoredBrowserTabs
  ]
})

export class SharedAlbumsModule {	}
