import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AppLovinMax } from '@vuulm/plugins/applovin/ngx';

// Config
import { APPCONFIG } from '../../config';

// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';
@Injectable()
export class AdAppLovinService {
    constructor(
        private platform: Platform,
        private appLovin: AppLovinMax,
        readonly store: Store<AppState>,
    ) { }

    init() {
        this.platform.ready().then(() => {
            if (this.platform.is('cordova')) {
                // SDK is initialized, start loading ads
                this.appLovin
                .initialize(APPCONFIG.applovin.sdkKey)
                .then(() => {
                    console.log('AppLovinMAX is ready');
                    this.appLovin.setMuted(true);
                    if (this.platform.is('ios')) {
                        this.appLovin.loadInterstitial(APPCONFIG.applovin.interstitial.ios);
                        this.appLovin.createMRec('a663d2c5defea5c8', 'centered');
                    } else {
                        this.appLovin.loadInterstitial(APPCONFIG.applovin.interstitial.android);
                    }
                }).catch((e) => {
                    console.log('AppLovin Error: ', e);
                });
            }
        });
    }

    showInterstitial() {
        if (this.platform.is('cordova')) {
            if (this.platform.is('ios') && this.appLovin.isInterstitialReady(APPCONFIG.applovin.interstitial.ios)) {
                this.appLovin.showInterstitial(APPCONFIG.applovin.interstitial.ios);
            } else {
                this.appLovin.showInterstitial(APPCONFIG.applovin.interstitial.android);
            }
        }
    }

    createMRec() {
        if (this.platform.is('cordova')) {
            if (this.platform.is('ios')) {
                this.appLovin.showMRec('a663d2c5defea5c8');
            }
        }
    }

    destroyMRec() {
        if (this.platform.is('cordova')) {
            if (this.platform.is('ios')) {
                this.appLovin.hideMRec('a663d2c5defea5c8');
                // this.appLovin.destroyMRec();
            }
        }
    }
}