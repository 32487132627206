import { Component, Input } from '@angular/core';

@Component({
  selector: 'featured-banner',
  templateUrl: 'banner.html',
  styleUrls: ['./banner.scss']
})
export class BannerComponent {
  @Input('albums') albums: any;
  @Input('featured') featured: boolean = true;

  constructor() { }

  cleanUrl(url) {
    let clean = encodeURI(url);
    return clean; 
  }

  isAlbumRecent(published_date) {
    const d = new Date();
    let p = new Date(published_date);
    p.setDate(p.getDate());
    d.setDate(d.getDate()-2); // check if less than 2 days
    if(p >= d) {
        return true;
    } else {
        return false;
    }
  }

}
