import { Component, OnInit,  } from '@angular/core';
import { ModalController, IonNav } from '@ionic/angular';
import { Platform, NavParams } from '@ionic/angular';
import { ColoredBrowserTabs } from '@vuulm/plugins/colored-browser-tabs/ngx';

// Router
import { Router, ActivatedRoute } from '@angular/router';

// Services
import { AlbumService, PlaylistService, UserDetailService, SeoService, PodcastService } from '@vuulm/core';
import { ContentContentfulService } from '../../services/content';

// Pages
import { ProfileBioComponent } from './profile-bio/profile-bio.component';
import { DetailComponent } from '../albums/detail/detail.component';
import { PlaylistsDetailComponent } from '../playlists/detail/detail.component';
import { ProfileAlbumsComponent } from './albums/albums.component';
@Component({
  selector: 'app-landing-profile',
  template: '<ion-nav [root]="rootPage" [swipeGesture]="true"></ion-nav>'
})
export class LandingProfileComponent {
  public rootPage = ProfileComponent;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    public profile: any;
    public topAlbums: any;
    public albums: any[] = [];
    public pageNumber = 1;
    public playlists: any;
    public userPlaylists: any;
    public relatedAlbums: any;
    public latestRelease: any;
    public pageContentRelated: any;
    public podcasts: any;
    public albumStats = {
      played: '0',
      monthly: 0,
      pageviews: 0
    };
    constructor(
      public navParams : NavParams,
      public ionNavLink: IonNav,
      private route: ActivatedRoute,
      public platform: Platform,
      private userDetailService: UserDetailService,
      private albumService: AlbumService,
      private playlistService: PlaylistService,
      public modal: ModalController,
      private router: Router,
      private browserTab: ColoredBrowserTabs,
      private seo: SeoService,
      private content: ContentContentfulService,
      private podcastService: PodcastService
    ) {}

    ngOnInit() {
      if (this.navParams.get('userid')) {
        const userId = this.navParams.get('userid');
        this.getProfile(userId);
      } else {
        this.route.params.subscribe((params) => {
          const userId = params.userid;
          this.getProfile(userId);
        });
      }
    }

    goToAlbum(entityId, type= 'album') {
      if (type === 'playlist') {
        this.ionNavLink.push(PlaylistsDetailComponent, { playlistid: entityId});
      } else {
        this.ionNavLink.push(DetailComponent, { albumid: entityId});
      }
    }

    goToUserAlbums(type = 'albums') {
      this.ionNavLink.push(ProfileAlbumsComponent, { pageType: type, artistName: this.latestRelease.name, userid: this.profile._id});
      // this.router.navigate([`/artists/${this.profile._id}/albums`, { pageType: type, artistName: this.latestRelease.name}]);
    }

    getProfile(userid) {
      if (userid) {
        this.userDetailService
          .get(userid)
          .subscribe((r: any) => {
              if (r) {
                  this.profile = r;
                  this.getTopAlbums();
                  this.getAlbums();

                  // if (this.profile.verified) {
                  this.getUserFeaturedOnPlaylists();
                  // }
                  this.getUserAlbumStats();
                  this.generateSeoTags(this.profile);
                  this.getRelatedEntries(this.profile);
                  this.getPodcasts(this.profile);
              }
          });
      }
    }

    getUserAlbumStats() {
      this.userDetailService.getUserAlbumStats(this.profile._id)
        .subscribe((r: any) => {
          if (r && r[0]) {
            const plays = r[0];
            this.albumStats.played = plays.totalPlays || 0;
            this.albumStats.monthly = plays?.monthly || 0;
          }
        });
    }

    getTopAlbums() {
      this.albumService.trending({uid: this.profile._id, count: 5})
          .subscribe((r: any) => {
              if (r) {
                  this.topAlbums = r;
              }
          });
    }

    getPodcasts(profile) {
      this.podcastService.get({uid: profile._id})
        .subscribe((r: any) => {
          if (r) {
            this.podcasts = r;
          }
        });
    }

    getAlbums() {
      this.albumService
          .get({uid: this.profile._id, count: 10,  enablePaging: 1, pageNumber: this.pageNumber})
          .subscribe((r: any) => {
              if (r) {
                  this.albums = r;
                  this.latestRelease = r[0];

                  this.getRelatedAlbums();
              }
          });
    }

    getUserFeaturedOnPlaylists() {
      this.playlistService.get({uid: this.profile._id})
        .subscribe((r: any) => {
          if (r) this.userPlaylists = r;
        });
      this.playlistService.getFeaturedPlaylistsByUserId(this.profile._id)
          .subscribe((r: any) => {
              if (r) {
                this.playlists = r;
              }
          });
    }

    getRelatedAlbums() {
      if (this.latestRelease) {
        const artistName = this.latestRelease?.name;
        this.albumService.get({tag: `${artistName}`, count: 5 })
            .subscribe((r: any) => {
                if (r) {
                  this.relatedAlbums = r.filter(x => x._id !== this.latestRelease._id);
                }
            });
      }
    }

    getRelatedEntries(profile) {
      this.content.getBlogPost({'fields.profileIds[in]': profile._id})
      .subscribe((data) => {
        if (data) {
          this.pageContentRelated = data;
        }
      });
    }

    goToUrl(url) {
      if (this.platform.is('cordova')) {
        this.browserTab.openTab(`${url}`, '#000000').subscribe();
    } else {
        window.open(`${url}`);
    }
  }

  generateSeoTags(user) {
    if(!this.platform.is('cordova')) {
      const config = {
        keywords: `${user.username}, music, vuulm, hip-hop music, mixtapes, albums`
      };
      this.seo.generateProfileTags(config, user);
    }
  }

  async presentModal() {
    const modal = await this.modal.create({
      component: ProfileBioComponent,
      animated: true,
      initialBreakpoint: 1,
      breakpoints: [0, 0, 1],
      componentProps: {
        profile: this.profile,
      }
    });
    return await modal.present();
  }

  trimName(name) {
    if (name) {
      return name.charAt(0).toUpperCase();
    } else {
      return 'V';
    }
  }
}
