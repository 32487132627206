<ion-content fullscreen></ion-content>
<ion-footer>
    <div *ngIf="getAlbumDetails()" class="album-list">
        <div class="artwork">
            <img src="{{getAlbumDetails().artwork}}?resize=85,85" class="cover">
        </div>
        <div class="info">
            <div *ngIf="getAlbumDetails().featured" class="featured">
                <span class="label">Featured</span>
            </div>
            <strong class="title">{{getAlbumDetails().title}}</strong><br/>
            <small class="name muted">{{ getAlbumDetails().name | ellipsis:24 }}</small>
            <div class="stats">
                <div *ngIf="getAlbumDetails().stats && getAlbumDetails().stats.streams"><ion-icon name="play" class="muted"></ion-icon> {{getAlbumDetails().stats.streams | numberAbbr}}</div>
                <div *ngIf="getAlbumDetails().stats && getAlbumDetails().stats.streams"><ion-icon name="heart" class="muted"></ion-icon> {{getAlbumDetails().stats.favorites | numberAbbr}}</div>
                <div *ngIf="getAlbumDetails().stats && getAlbumDetails().stats.streams"><ion-icon name="arrow-redo" class="muted"></ion-icon> {{getAlbumDetails().stats.reposts | numberAbbr}}</div>
            </div>
        </div>
    </div>

    <ion-grid class="actions">
        <ion-row *ngIf="showAlbumHistory" (click)="removeFromHistory(this.trackId)">
            <ion-col size="2">
                <ion-icon  name="close-circle-outline" class="text-red"></ion-icon>
            </ion-col>
            <ion-col size="10">Remove from history</ion-col>
        </ion-row>
        <ng-container *ngIf="showFavorite">
            <ion-row *ngIf="isFavorite(this.trackId)" (click)="removeFavorite(this.trackId)">
                <ion-col size="2">
                    <ion-icon  name="heart" class="text-red"></ion-icon>
                </ion-col>
                <ion-col size="10">Remove from favorites</ion-col>
            </ion-row>
            <ion-row *ngIf="!isFavorite(this.trackId)" (click)="createFavorite()">
                <ion-col size="2">
                    <ion-icon name="heart-outline"></ion-icon>
                </ion-col>
                <ion-col size="10">Add to favorites</ion-col>
            </ion-row>
        </ng-container>
        <ion-row *ngIf="showPlaylist" (click)="addToPlaylist()">
            <ion-col size="2"><ion-icon name="add"></ion-icon></ion-col>
            <ion-col size="10">Add to playlist</ion-col>
        </ion-row>
        <ion-row (click)="share(this.album)">
            <ion-col size="2"><ion-icon name="arrow-redo"></ion-icon></ion-col>
            <ion-col size="10">Share</ion-col>
        </ion-row>
        <ion-row (click)="goToArtist()">
            <ion-col size="2"><ion-icon name="person"></ion-icon></ion-col>
            <ion-col size="10">Go to artist</ion-col>
        </ion-row>
        <ion-row (click)="goToAlbum()" >
            <ion-col size="2"><ion-icon name="play"></ion-icon></ion-col>
            <ion-col size="10">Go to album</ion-col>
        </ion-row>
    </ion-grid>

    <ion-row (click)="dismiss()">
        <ion-col size="12" class="ion-text-center">
            <ion-icon class="closePanel" name="arrow-down"></ion-icon>
        </ion-col>
    </ion-row>
    <br/>
</ion-footer>
