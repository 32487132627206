import { Component, Input, OnInit } from '@angular/core';
// NGRX
import { Store } from '@ngrx/store';

import { AppState, AlbumService, UserDetailService } from '@vuulm/core';

@Component({
  selector: 'app-post-album',
  templateUrl: './post-album.component.html',
  styleUrls: ['./post-album.component.scss']
})
export class PostAlbumComponent implements OnInit {

  @Input() albumId: any;
  public album: any;
  public audioSrc: any;

  constructor(
    private albumService: AlbumService,
    private userDetailService: UserDetailService,
    readonly store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.getAlbumDetails(this.albumId);
  }

  async getAlbumDetails(albumId: string) {
    await this.albumService.getById(albumId).subscribe((r: any) => {
      this.album = r[0];
    });
  }

  playSong(album, trackid, type = 'album') {
    this.audioSrc = {
      queue: album,
      currentSong: {
        albumId: album._id,
        trackId: trackid,
        type: `${type}`
      }
    };

    if (type !== 'offline') {
    }

    this.play();
  }

  play() {
    this.store.dispatch({ type: 'player/QUEUE', payload: this.audioSrc });
  }

  setBackgroundImage() {
    if (!this.album && this.album.artwork) {
      return false;
    }

    const artwork = this.album.artwork;

    const style = {
      'background-image': `linear-gradient(0deg, rgb(20, 20, 21), rgba(0, 0, 0, 0.2) 80%), url(${encodeURI(artwork)})`,
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
    };

    return style;
  }

  cleanAlbumArtwork(url) {
    return `${encodeURI(url)}?resize=300,300`
  }
}
