<ng-container *ngIf="album">
    <div class="square" [class.blur]="album.explicit">
        <ng-container *ngIf="!offlineLibrary">
            <img *ngIf="!album.artwork" src="https://via.placeholder.com/150/1B1B1C/030303" />
            <img *ngIf="album.artwork" src="{{album.artwork}}?resize=300,300"/>
        </ng-container>

        <ng-container *ngIf="offlineLibrary">
            <img *ngIf="album.artwork" [src]="getImageUrl(album.artwork)" (click)="goToAlbum(album._id, 'offline')"/>
        </ng-container>

        <ion-row>
            <ion-col size="9">
                <strong class="title">{{album.title | ellipsis:15 }}</strong><br/>
                <small class="muted" *ngIf="album.name">{{ album.name | ellipsis:15 }}</small>
            </ion-col>
            <ion-col size="3" class="ion-text-right">
                <ng-container [ngTemplateOutlet]="actions"></ng-container>
            </ion-col>
        </ion-row>
    </div>
</ng-container>