import { Component, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';

// NGRX
import { Store } from '@ngrx/store';

// Services
import { AppState, UserFollowingService } from '@vuulm/core';
import { FcmProvider } from '../../../providers/fcm/fcm';
// Pages
import { LoginComponent } from '../../login/login.component';

@Component({
  selector: 'btn-follow',
  templateUrl: 'follow.html',
  styleUrls: ['./follow.scss'],
  providers: [ UserFollowingService ]
})
export class FollowButtonComponent {
  @Input('user') user: any;

  public isLoggedIn: boolean = false;
  public userFollowingList: any;
  constructor(
    private modal: ModalController,
    private toastCtrl: ToastController,
    private userFollowingService: UserFollowingService,
    private fcmProvider: FcmProvider,
    readonly store: Store<AppState>
  ) {
    this.store.select(state => state.auth)
    .subscribe((session: any) => {
        if (session.jwt !== null && session.uid !== null) {
            this.isLoggedIn = true;
        } else {
            this.isLoggedIn = false;
        }
    });

    // check if user followings exists
    // get followings from store
    this.store.select(state => state)
      .subscribe((state: any) => {
        if (state.following.list) {
          this.userFollowingList = state.following.list;
        }
      });
  }

  subscribe() {
    if (this.isLoggedIn) {
      this.userFollowingService.subscribe(this.user._id)
        .subscribe((r: any) => {
          this.store.dispatch({type: 'user/FOLLOW', payload: r});

          // subscribe user to push notifications
          // this.fcmProvider.subscribeToUser(this.user._id);
        }, (error: any) => {
          this.showToaster(error.error.message);
        });
    } else {
      this.loginModal();
    }
  }

  unsubscribe() {
    if (this.isLoggedIn) {
      this.userFollowingService.unsubscribe(this.user._id)
        .subscribe((r: any) => {
          this.store.dispatch({type: 'user/FOLLOW', payload: r});

          // unsubscribe user to push notifications
          // this.fcmProvider.unsubscribeFromUser(this.user._id);
        }, (error: any) => {
          this.showToaster(error.error.message);
        });
    } else {
      this.loginModal();
    }
  }

  isFollowing() {
    if (this.userFollowingList && this.userFollowingList.length > 0) {
      const fav = this.userFollowingList.filter(t => t._id === this.user._id);
      if (fav.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  async loginModal() {
    const modal = await this.modal.create({
      component: LoginComponent
    });
    return await modal.present();
  }

  async showToaster(message) {
     // Success!
     const toast = await this.toastCtrl.create({
      message: `${message}`,
      duration: 3000,
      color: 'primary'
    });

     toast.present();
  }
}
