import { Injectable } from '@angular/core';
import { Router, CanActivateChild } from '@angular/router';

import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

import { map } from 'rxjs/operators';
@Injectable()
export class OfflineGuard implements CanActivateChild {
    constructor(
        private router: Router,
        readonly store: Store<AppState>,
    ) { }

    canActivateChild() {
        return this.store.select(state => state.network)
        .pipe(map((status) => {
            if (status.networkStatus) {
                this.router.navigate([`/offline`]);
                return false;
            } else {
                return true;
            }
        }));
    }
}
