import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'player',
  templateUrl: 'player-controls.component.html',
  styleUrls: ['./player-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PlayerControlsComponent implements OnInit {
    @Input() album: any; // album model 
    @Input() trackId: string; // current track id
    @Input() albumPlayerType: string; // album, playlist

    constructor() {}

    ngOnInit() {}


}
