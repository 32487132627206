<ion-header [style.--custombg]="artworkColors">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button style="font-size: 12px;margin-left: 10px"></ion-back-button>
      </ion-buttons>
      <ion-title *ngIf="podcastDetails"> {{ podcastDetails.title }}</ion-title>
      <ion-buttons slot="end"></ion-buttons>
    </ion-toolbar>
  </ion-header>
    
<ion-content class="ion-padding" [style.--custombg]="artworkColors">
    <ng-container *ngIf="podcastDetails">
        <album-header [album]="podcastDetails" [type]="'podcast'" [showHeader]="true" [user]="profile" (navigateTo)="goToArtist($event)"></album-header>

        <div class="album-details">
            <small class="label">Details</small>
            <div class="info">
                <div class="muted" [innerHtml]="podcastDetails.description"></div>
            </div>
        </div>

        <div class="podcast-tracklist">
            <ng-container *ngFor="let track of podcastDetails.tracks; let i = index">
                <ion-grid class="tracklist-row">
                    <ion-row>
                        <ion-col size="2">
                            <div class="artwork">
                                <img src="{{podcastDetails.artwork}}?resize=85,85" class="cover">
                            </div>
                        </ion-col>
                        <ion-col size="8" class="ion-text-wrap" (click)="playSong(podcastDetails, track._id)">
                            <strong class="track-title">{{track.title | ellipsis: 55}}</strong>
                            <ng-container *ngIf="track.datePublished">
                                <em class="track-date">{{track.datePublished}}</em>
                            </ng-container>
                        </ion-col>
                        <ion-col size="2" class="ion-text-right">
                            <ion-button *ngIf="trackPlaying !== track._id" (click)="playSong(podcastDetails, track._id)" color="light" fill="clear"><ion-icon name="play"></ion-icon></ion-button>
                            <ion-button *ngIf="trackPlaying == track._id" color="primary" fill="clear"><ion-icon name="volume-high"></ion-icon></ion-button>
                        </ion-col>
                    </ion-row>
                    <ion-row (click)="presentModal(track._id)">
                        <ng-container *ngIf="track.description">
                            <div class="track-index" [innerHtml]="track.description | ellipsis:125"></div>
                        </ng-container>
                    </ion-row>
                </ion-grid>
            </ng-container>
        </div>

    </ng-container>
</ion-content>