// Ref: https://github.com/saimon24/ionic-spotify-ui/blob/main/src/app/directives/image-fade.directive.ts

import { Directive, HostListener, Input, Renderer2 } from '@angular/core';
import { DomController } from '@ionic/angular';
@Directive({
  selector: '[appImageFade]',
  host: { '(ionScroll)' : 'onContentScroll($event)'}
})
export class ImageFadeDirective {
  @Input('appImageFade') cover: any;
  @Input('appImageHeight') main: any;
 
  constructor(
    private renderer: Renderer2,
    private domCtrl: DomController,
  ) { }
  @HostListener('window:resize', ['$event']) onResize(event: Event) {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.main, 'margin-top', `${this.cover.clientHeight}px`);
    });
  }

  @HostListener('ionScroll', ['$event']) onContentScroll($event: any) {
    const scrollTop: number = $event.detail.scrollTop;
    let newOpacity = Math.max(100 - (scrollTop/2), 0.2)

    let newPadding = 10 + (scrollTop/10);
    if (newPadding > 100) {
      newPadding = 100;
    }

    this.domCtrl.write(() => {
      this.renderer.setStyle(this.cover, 'opacity', `${newOpacity}%`);
      this.renderer.setStyle(this.cover, 'padding-left', `${newPadding}%`);
      this.renderer.setStyle(this.cover, 'padding-right', `${newPadding}%`);
      this.renderer.setStyle(this.main, 'margin-top', `${this.cover.clientHeight}px`);
    });
  }
}
