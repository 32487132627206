import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-empty',
  templateUrl: 'empty.html',
  styleUrls: ['./empty.scss']
})
export class EmptyComponent {
    @Input() icon = 'headset';
    @Input() title: string;
    @Input() description ? = null;
    @Input() buttonText ? = null;
    @Input() route ? = null;
    constructor(private router: Router) { }

    goToUrl(route) {
        if (!route) {
          return false;
        }
        this.router.navigate([`${route}`]);
    }

}
