<ion-header [style.--custombg]="artworkColors">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button style="font-size: 12px;margin-left: 10px"></ion-back-button>
      </ion-buttons>
      <ion-title>Playlist</ion-title>
      <ion-buttons slot="end"></ion-buttons>
    </ion-toolbar>
  </ion-header>
    
<ion-content class="ion-padding" fullscreen [style.--custombg]="artworkColors">
    <ng-container *ngIf="playlist">
        <album-header [album]="playlist" [type]="'playlist'" [showHeader]="showHeader" (navigateTo)="goToArtist($event)"></album-header>
        <tracklisting [album]="playlist" [type]="'playlist'" [view]="'detail'" [user]="profile"></tracklisting>    
        <div class="item-title">You may also like
          <ion-nav-link router-direction="forward" routerLink="/playlists">
            <ion-button class="action" color="dark" size="small">View all</ion-button>
          </ion-nav-link>
        </div>
        <app-content-playlists [playlists]="playlistCollections"></app-content-playlists>
    </ng-container>
</ion-content>
    
            