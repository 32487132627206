<ng-container *ngIf="view === 'list'; else details">
    <div class="album-tracklist">
        <ng-container *ngFor="let track of album.tracks; let i = index">
            <ion-grid>
                <ion-row [class.small]="size == 'small'">
                    <ion-col size="1">
                        <span *ngIf="trackPlaying !== track._id" class="track-index">{{i + 1}}</span>
                        <ion-icon *ngIf="trackPlaying == track._id" class="playing" name="volume-high"></ion-icon>
                    </ion-col>
                    <ion-col size="9" class="ion-text-wrap" (click)="playSong(album, track._id, type)">
                        <span class="track-title">{{track.title}}</span>
                        <ng-container *ngIf="track.artistName">
                            <span class="track-index">{{track.artistName}}</span>
                        </ng-container>
                        <ng-container *ngIf="!track.artistName">
                            <span class="track-index">{{album.name}}</span>
                        </ng-container>
                    </ion-col>
                    <ion-col size="2" class="ion-text-right">
                        <album-details [album]="album" [type]="type" [trackId]="track._id"></album-details>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ng-container>
    </div>
</ng-container>

<ng-template #details>
    <div class="album-details">
        <small class="label">Details</small>
        <div class="info">
            <span class="muted" *ngIf="type">{{getPlayerType(album, type) | titlecase}} by {{user?.username | titlecase}}</span>
            <span class="muted" *ngIf="album.published_date"> &middot; {{album.published_date | date: 'yyyy' }}</span>
            <span class="muted" *ngIf="album.tracks"> &middot; {{album.tracks?.length }} songs</span>
            <span class="muted" *ngIf="album.stats && album.stats.streams && type !== 'offline'"> &middot; {{album.stats.streams | numberAbbr }} plays</span>
            <span class="muted"> &middot; {{getTotalDuration() | totalDuration }}</span>
        </div>
    </div>
    <div class="playlist-details">
        <div class="artists" [innerHTML]="formatPlaylistArtists(album.tracks)"></div>
    </div>
</ng-template>
