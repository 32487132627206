import { Injectable } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../services/storage/storage.service';

// Store
import { Store } from '@ngrx/store';

// Services
import { AppState } from '@vuulm/core';

import { map } from 'rxjs/internal/operators/map';
import { lastValueFrom } from 'rxjs';

declare var cordova;
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private clientId = 'ol58usRDxCjoPSOpksbIB1lv3y5BtlpC'; // default web id
  constructor(
    private http: HttpClient,
    private platform: Platform,
    private storage: StorageService,
    readonly store: Store<AppState>,
    public modal: ModalController,
    private router: Router
  ) {
    this.platform.ready().then(async () => {
      if (this.platform.is('ios')) { 
        this.clientId = 'RK63tMu0qoBXe2dY5kM44i23wBcl0HiG';
      }
      if (this.platform.is('android')) { 
        this.clientId = 'cXBMzWMZup8XDoQoz3ptu42H7SzZZeUM';
      }
    });
  }


  login() {
    this.platform.ready().then(async () => {
      if (this.platform.is('cordova')) {
          const url = `https://vuulm.auth0.com/authorize?audience=https://vuulm.auth0.com/api/v2/&response_type=token&scope=openid&client_id=${this.clientId}&redirect_uri=https://www.vuulm.com/auth0/callback`;
          const w = await cordova.InAppBrowser.open(url, '_blank', 'location=no,toolbarposition=top,closebuttoncaption=X,presentationstyle=formsheet');
          w.addEventListener('loadstart', this.profile);

          // check to make sure they are auth, and close the window
          this.store.select(state => state.auth)
            .subscribe(auth => {
              if (auth && auth.jwt && auth.uid) {
                w.close();
              }
            });
      } else {
          const web = `https://vuulm.auth0.com/authorize?audience=https://vuulm.auth0.com/api/v2/&response_type=token&scope=openid&client_id=${this.clientId}&redirect_uri=https://www.vuulm.com`;
          window.open(`${web}`, '_self');
      }
    });
  }

  profile = (event) => {
    const url = event.url;
    const accessToken = (new URLSearchParams((new URL(url)).hash.slice(1))).get('access_token');

    if (accessToken) {
        this.getProfile(accessToken).then((response: any) => {
          if (response && response['https://vuulm.com']) {
              const userId = response['https://vuulm.com']; // user id will be in this claim
              this.setSessionStore(accessToken, userId);
          } else if (response && response.sub.includes('auth0|')) {
              const uId = response.sub.split('|')[1];
              this.setSessionStore(accessToken, uId);
          } else {
            const uId = response.sub.split('|')[1];
            this.setSessionStore(accessToken, uId);
          }
        });
    }
    this.modal.dismiss();
    
  };

  /**
   * callback for web
   */
  callback(url) {
    const accessToken = (new URLSearchParams((new URL(url)).hash.slice(1))).get('access_token');
    if (accessToken) {
      this.getProfile(accessToken).then((response: any) => {
        if (response && response['https://vuulm.com']) {
            const userId = response['https://vuulm.com']; // user id will be in this claim
            this.setSessionStore(accessToken, userId);
        } else if (response && response.sub.includes('auth0|')) {
            const uId = response.sub.split('|')[1];
            this.setSessionStore(accessToken, uId);
        } else {
          const uId = response.sub.split('|')[1];
          this.setSessionStore(accessToken, uId);
        }
      });
    }
  }

  async getProfile(token) {
    const headers = {
        'Authorization' :  `Bearer ${token}`,
        'content-type': 'application/json'
    };
      return await lastValueFrom(
          this.http.get(`https://vuulm.auth0.com/userinfo`,{ headers: headers}).pipe(
          map((res) => {
              return res;
          })
      ));        
  }

  setSessionStore(jwt?, uid?) {
    this.platform.ready().then(() => {
      this.storage.set('auth0Vuu', { jwt: jwt, uid: uid });
    });
    
    this.store.dispatch({
      type: 'auth/LOGIN',
      payload: { jwt: jwt, uid: uid }
    });
  }

  async logout() {
    this.platform.ready().then(async () => {
      this.storage.remove('auth0Vuu');

      if (this.platform.is('cordova')) {
        const url = `https://vuulm.auth0.com/logout?client_id=${this.clientId}&returnTo=https://www.vuulm.com/auth0/callback`;
        const w = await cordova.InAppBrowser.open(url, '_blank', 'hidden=yes');
        w.close();
      } else {
        window.open(`https://vuulm.auth0.com/logout?client_id=${this.clientId}&returnTo=https://www.vuulm.com`, '_self');
      }
    
      this.store.dispatch({type: 'auth/LOGOUT', payload: null});
      this.store.dispatch({ type: 'user/PROFILE', payload: null});
      this.store.dispatch({type: 'user/FAVORITES', payload: null});
      this.store.dispatch({type: 'user/FOLLOW', payload: null});
      this.router.navigate(['/']);
    });
  }
}