<ion-row class="control-buttons">
    <ion-col size="2" class="ion-text-center cntrl large">
        <ion-button (click)="repeatTrack()" color="medium" fill="clear" icon-only><ion-icon name="repeat" mode="md"></ion-icon></ion-button>
    </ion-col>

    <ion-col size="2" class="ion-text-right cntrl large">
        <ion-button (click)="previous()" color="light" fill="clear" icon-only><ion-icon name="play-skip-back-sharp"></ion-icon></ion-button>
    </ion-col>

    <ion-col size="4" class="ion-text-center cntrl play">
        <play-button class="button-adjust"></play-button>
    </ion-col>

    <ion-col size="2" class="ion-text-left cntrl large">
        <ion-button (click)="next()" color="light" fill="clear" icon-only><ion-icon name="play-skip-forward-sharp"></ion-icon></ion-button>
    </ion-col>

    <ion-col size="2" class="ion-text-center cntrl large">
        <ion-button *ngIf="!isShuffle" (click)="enableShuffle(true)" color="medium" fill="clear" icon-only><ion-icon name="shuffle" mode="md"></ion-icon></ion-button>
        <ion-button *ngIf="isShuffle" (click)="enableShuffle(false)" color="primary" fill="clear" icon-only><ion-icon name="shuffle" mode="md"></ion-icon></ion-button>
    </ion-col>
</ion-row>