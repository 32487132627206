import { Component, OnInit, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ColoredBrowserTabs } from '@vuulm/plugins/colored-browser-tabs/ngx';

@Component({
  selector: 'app-album-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class AlbumLinksComponent implements OnInit {

  @Input() album: any;
  public albumDetails: any;
  constructor(
    private modalController: ModalController,
    private platform: Platform,
    private browserTab: ColoredBrowserTabs
  ) { }

  ngOnInit(): void {
  }

  getAlbumDetails() {
    return this.album;
  }

  goToUrl() {
    const url = this.cleanUrl(this.album.purchase_link);
    if (this.platform.is('cordova')) {
      this.browserTab.openTab(`${url}`, '#000000').subscribe();
    } else {
        window.open(`${url}`);
    }
}

  cleanUrl(url) {
    const clean = encodeURI(url);
    return clean;
  }

  dismiss() {
    this.modalController.dismiss({
        dismissed: true
    }).then(() => { });
}

}
