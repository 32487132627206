import { NgModule, ModuleWithProviders } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { PipeModule } from '@vuulm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';

import { SharedActionsModule } from './actions/actions.module';
import { SharedCommonModule } from './common/common.module';
import { SharedAlbumsModule } from './album/albums.module';
import { SharedLoginModule } from './login/login.module';
import { SharedContentModule } from './content';
import { SharedPlayerModule } from './player';
@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    PipeModule,
    FormsModule, ReactiveFormsModule,
    SwiperModule,
    SharedActionsModule,
    SharedCommonModule,
    SharedAlbumsModule,
    SharedLoginModule,
    SharedContentModule,
    SharedPlayerModule
  ],
  declarations: [],
  entryComponents: [],
  exports: [
    PipeModule,
    FormsModule, ReactiveFormsModule,
    SwiperModule,
    SharedActionsModule,
    SharedCommonModule,
    SharedAlbumsModule,
    SharedLoginModule,
    SharedContentModule,
    SharedPlayerModule
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}

