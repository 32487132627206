import { Component, Input } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

@Component({
  selector: 'share-album',
  templateUrl: 'share.html'
})
export class ShareComponent {
  @Input() album: any;
  @Input() type?: any = 'album';
  constructor(
    private toastCtrl: ToastController,
    private socialSharing: SocialSharing
  ) { }

  share(album, type?) {
    const options = {
      message: `${album.title} - ${album.name} on Vuulm #newmusic #vuulm`,
      subject: `Now playing ${album.title} ${album.name}`,
      files: [encodeURI(album.artwork)],
      url: encodeURI(`https://www.vuulm.com/albums/${album.permalink}`)
    };

    if (type == 'playlist') {
      options.message = `${album.title} Playlist on Vuulm #vuulm #playlist`,
      options.url = encodeURI(`https://www.vuulm.com/playlists/${album.permalink}`);
      delete options.files, options.subject;
    }

    this.socialSharing.shareWithOptions(options)
        .then(() => {
          setTimeout(() => {
             this.showToaster();
          }, 500);
        }).catch(() => {});
  }

  async showToaster() {
     // Success!
     const toast = await this.toastCtrl.create({
      message: '🎉 All done!',
      duration: 3000,
      color: 'success'
    });
    
    toast.present();
  }
}
