<ion-header>
    <ion-toolbar>
        <ion-title>Add to playlist</ion-title>
        <ion-buttons slot="end">
          <ion-button fill="clear" (click)="showPrompt()">
            <ion-icon name="add"></ion-icon>
          </ion-button>
        </ion-buttons>
    </ion-toolbar>
  </ion-header>
   
    
<ion-content fullscreen>
    <ng-container *ngIf="playlists && playlists.length > 0">
        <ion-list lines="none">
            <ion-item-sliding *ngFor="let p of playlists; let i = index">
                <ion-item (click)="addToPlaylist(p._id)">
                    <ion-grid>
                        <ion-row>
                            <ion-col size="2">
                                <img *ngIf="!p.artwork" src="https://via.placeholder.com/150/1B1B1C/FFFFFF?text={{ trimName(p.title) }}" />
                                <img *ngIf="p.artwork" src="{{p.artwork}}?resize=300,300" />
                            </ion-col>
                            <ion-col size="10" class="ion-text-wrap">
                                <strong>{{p.title}}</strong><br/>
                                <small>{{p.tracks.length}} songs</small>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-item>
            </ion-item-sliding>
        </ion-list>
    </ng-container>

    <ng-container *ngIf="playlists && playlists.length === 0">
        <div class="container-empty">
            <div class="icon">
                <ion-icon name="list"></ion-icon>
            </div>
            <br/>
            <span><strong>You don't have any playlists yet</strong></span>
            <span class="muted">Playlists you've created will show up here</span>
            <br/><br/>
            <ion-button expand="block" (click)="showPrompt()"><ion-icon name="add"></ion-icon> Create playlist</ion-button>
        </div>
    </ng-container>

</ion-content>
<ion-footer>
    <ion-toolbar class="ion-text-center">
        <span class="text-white" (click)="dismiss()">Go Back</span>
    </ion-toolbar>
</ion-footer>