import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform, NavController } from '@ionic/angular';

// Store
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';

// components
import { LandingAlbumDetailComponent } from '../pages/albums/detail/detail.component';
import { BlogPostComponent } from '../pages/blog/post/post.component';
import { ProfileComponent } from '../pages/profile/profile.component';
import { PlaylistsDetailComponent } from '../pages/playlists/detail/detail.component';
import { LandingPodcastDetailComponent } from '../pages/podcasts/detail/detail.component';
@Injectable({
  providedIn: 'root'
})
export class DeepLinkingService { 
    constructor(
        private platform: Platform,
        private router: Router,
        private deeplinks: Deeplinks,
        public navCtrl: NavController,
    ) { }

    /**
     * Listen for deep links
     */
    init() {
        this.platform.ready().then(async () => {
            if (this.platform.is('cordova')) {
                // check deeplinks
                await this.deeplinks.route({
                    '/albums/:albumid': LandingAlbumDetailComponent,
                    '/blog/article/:articleid': BlogPostComponent,
                    '/artists/:userid': ProfileComponent,
                    '/playlists/:playlistid': PlaylistsDetailComponent,
                    '/podcasts/:podcastid': LandingPodcastDetailComponent
                })
                .subscribe({
                    next: async(match) => {
                        // Note: need to set timeout to wait for ng app to load and initalize before redirecting
                        setTimeout(async () => {
                            // console.log('Deeplink matched: ', match);
                            const url = `${match.$link.path}${(match.$link?.queryString) ? '?' + match.$link.queryString : '' }`;
                            await this.router.navigateByUrl(url)
                        }, 500);
                    },
                    // error: (nomatch) => 
                    // console.error("Deeplink didn't match", JSON.stringify(nomatch))
                });
            }
        });
    }
}