import { Action } from '@ngrx/store';

export const NETWORK_STATUS = 'network/STATUS';


export class NetworkStatusOnline implements Action {
    readonly type = NETWORK_STATUS;

    constructor(public payload: any) {}
}


// Export actions
export type Actions = NetworkStatusOnline;
