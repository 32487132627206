import { Injectable } from '@angular/core';
import { AppState, UserDetailService } from '@vuulm/core';
// Store
import { Store } from '@ngrx/store';

declare var cordova: any

/*
  Generated class for the FcmProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class FcmProvider {

  constructor(
    private userDetailService: UserDetailService,
    readonly store: Store<AppState>,
  ) {
  }

  async onNotification() {
    const fcm = cordova.plugins.firebase.messaging;
    await fcm.onBackgroundMessage().then(data => {
      // console.log('fcm', data);
      if (data.wasTapped) {
        console.log('Received in background');
      } else {
        console.log('Received in foreground');
      }
    });
  }

  requestPermission(platform?) {
    const fcm = cordova.plugins.firebase.messaging;
    fcm.requestPermission()
      .then((data) => { 
        // console.log('fcm:', data)
      });
    this.getToken(platform);
  }

  async subscribeToTopic() {
    const fcm = cordova.plugins.firebase.messaging;
    await fcm.subscribe('marketing').then((data: any) => {
      if (data) {
        // console.log('user is subscribed');
      }
    });
  }

  async subscribeToUser(userId: string) {
    const fcm = cordova.plugins.firebase.messaging;
    await fcm.subscribe(`${userId}`).then((data) => {
      console.log('user is subscribed');
    });
  }

  async unsubscribeFromUser(userId: string) {
    const fcm = cordova.plugins.firebase.messaging;
    await fcm.unsubscribe(`${userId}`).then((data) => {
      console.log('user is unsubscribed');
    });
  }

  async getToken(platform) {
    const fcm = cordova.plugins.firebase.messaging;
    await fcm.getToken().then(token => {
      console.log('received token', token);
      this.updateNotification(token, platform);
      return token;
    });
  }

  async unsubscribeFromTopic() {
    const fcm = cordova.plugins.firebase.messaging;
    await fcm.unsubscribe('marketing');
  }

  async clearNotifications() {
    const fcm = cordova.plugins.firebase.messaging;
    await fcm.clearNotifications();
  }

  async setUserId(user) {
    // await fcm.setUserId(user._id).then(data => {
    //   console.log('user info set');
    // });
  }

  async setUserProperty(user) {
    // await fcm.setUserProperty('username', `${user.username}`)
    //   .then((data) => {
    //     console.log('user property set');
    //   });
  }

  private updateNotification(token, platform) {
    this.store.select(state => state.auth)
      .subscribe(auth => {
        if (auth && auth.jwt && auth.uid) {
            this.updateDevices(token, platform);
        }
      });
  }


  updateDevices(token, platform) {
    this.userDetailService.addDeviceNotification(token, platform)
      .subscribe();
  }

}
