import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { IonRouterOutlet } from '@ionic/angular';

// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

// Providers
import { AudioProvider } from '../../../providers/audio/audio';

// Components
import { PlayerComponent } from '../player.component';

// Router
import { Router } from '@angular/router';

// Services
import { UserDownloadService } from '../../../services/users/download.service';


declare var window: any;
@Component({
  selector: 'app-player-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class PlayerCardComponent implements OnInit {

  public album: any;
  public trackId: string;
  public trackInfo: any;
  public playerType: string = 'playlist' || 'list';
  public showPlayer: boolean;
  public isPlaying: boolean;
  public isLoading: boolean;
  public currentTrackTitle: string;
  public currentTrackName: string;
  public elapsed: number;
  public lastPosition;
  public state = 'bottom';

    constructor(
        readonly store: Store<AppState>,
        private audioProvider: AudioProvider,
        private modal: ModalController,
        private router: Router,
        private routerOutlet: IonRouterOutlet,
        private sanitizer: DomSanitizer,
        private userDownloadService: UserDownloadService
    ) {}

    ngOnInit() {
      this.store.select(state => state.player)
        .subscribe((response: any) => {
          if (response && response.queue !== null) {
              this.album = response.queue;
              this.trackId = response.currentSong.trackId;
              this.playerType = response.currentSong.type;
              this.showPlayer = true;
              this.setPlayerCardInfo();
          }
      });

      this.audioProvider.getPlayerStatus()
        .subscribe((status) => {
            if (status === 'playing') {
                this.isPlaying = true;
                this.isLoading = false;
            } else {
                this.isPlaying = false;
                this.isLoading = false;
            }

            if (status === 'loading') {
                this.isLoading = true;
            } else {
                this.isLoading = false;
            }
        });

      this.audioProvider.getPercentElapsed()
      .subscribe((time: any) => {
        this.elapsed = time;
      });
    }

    play() {
      this.audioProvider.play();
    }

    pause() {
      this.audioProvider.pauseTrack();
    }

    next() {
      this.audioProvider.nextTrack();
    }

    setPlayerCardInfo() {
      if (this.album) {
        const trackName = this.album.tracks.find(x => x._id === this.trackId);
        this.currentTrackTitle = trackName?.title || null;
        this.currentTrackName = (trackName.artistName) ? trackName.artistName : this.album.name;
      }
    }

    getPlayerTypeArtwork() {
      if (this.playerType === 'playlist') {
        const artwork = this.album.tracks.find(x => x._id === this.trackId)?.artwork || null;
        return (artwork) ? this.cleanUrl(artwork) : '';
      } else {
        return (this.album.artwork) ? this.cleanUrl(this.album.artwork) : '';
      }
    }

    goToAlbum() {
      if (this.playerType === 'playlist') {
        this.router.navigate([`/playlists/${this.album._id}`]);
      } 
      else if (this.playerType === 'podcast') {
        this.router.navigate([`/podcasts/${this.album._id}`]);
      }
      else if (this.playerType === 'offline') {
        this.router.navigate([`/account/downloads/${this.album._id}`]);
      } else {
        this.router.navigate([`/albums/${this.album._id}`]);
      }
    }

    cleanUrl(url) {
      if (url.includes('https') || url.includes('http')) {
        const clean = encodeURI(url);
        return `${clean}?resize=85,85`;
      } else {
        const filePath = this.userDownloadService.getFilePath();
        return this.sanitizer.bypassSecurityTrustResourceUrl(
          window.Ionic.WebView.convertFileSrc(filePath + url)
        );
      }
    }

    async presentModal() {
      const modal = await this.modal.create({
        component: PlayerComponent,
        animated: true,
        initialBreakpoint: 1,
        breakpoints: [0, 1, 1],
        handle: true,
        swipeToClose: true
      });
      return await modal.present();
    }

}
