import { Component, OnInit, Input } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

// Providers
import { AudioProvider } from '../../../providers/audio/audio';

import { VideoDialogComponent } from '../../common/video-dialog/video-dialog';

@Component({
  selector: 'app-content-videos',
  templateUrl: './videos.html',
  styleUrls: ['./videos.scss']
})
export class ContentVideosComponent implements OnInit {
    @Input() videos: any;
    constructor(
      private audioProvider: AudioProvider,
      private loading: LoadingController,
      public platform: Platform,
      public modal: ModalController
    ) {}
    ngOnInit() {}

    getVideoThumb(videoId) {
      return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    }

    openBrowserUrl(videoId, videoInfo) {
      this.showLoading();
      this.audioProvider.getPlayerStatus()
      .subscribe((status) => {
          if (status === 'playing') {
              this.pause();
          }

          if (status === 'loading') {
            this.pause();
          }
      });
      this.showVideoDialog(videoId, videoInfo);
    }

    pause() {
      this.audioProvider.pauseTrack();
    }

    async showVideoDialog(videoId, videoInfo) {
      const modal = await this.modal.create({
        component: VideoDialogComponent,
        componentProps: {
          videoId: `${videoId}`,
          // tslint:disable-next-line: object-literal-shorthand
          videoInfo: videoInfo
        }
      });
      modal.present();
    }

    async showLoading() {
      const loadingElement = await this.loading.create({
        message: 'Loading',
        spinner: 'crescent',
        duration: 2000
      });
      return await loadingElement.present();
  }
}
