import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

// Ionic Native Controls
import { Platform } from '@ionic/angular';
import { Media, MediaObject } from '@awesome-cordova-plugins/media/ngx';

import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

declare var window: any;
/**
 * Audio Provider -
 * audio provider is a service that maintains the
 * state of an audio file needed for the native device and components
 */
@Injectable({
    providedIn: 'root'
  })
export class AudioBaseProvider {
    public trackId: string;
    public album: any;
    public albumId: string;
    public albumPlayerType: string;
    public currentTrackIndex: number;
    public shuffle = false;
    public repeat = false;
    public audioFile: MediaObject = null;
    public timeElapsed: BehaviorSubject<string> = new BehaviorSubject('00:00');
    public timeRemaining: BehaviorSubject<string> = new BehaviorSubject('00:00');
    public percentElapsed: BehaviorSubject<number> = new BehaviorSubject(0);
    public percentLoaded: BehaviorSubject<number> = new BehaviorSubject(0);
    public playerStatus: BehaviorSubject<string> = new BehaviorSubject('paused');
    public _actionIsPaused: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        public platform: Platform,
        public media: Media,
        readonly store: Store<AppState>,
    ) {
        // Listen to updates from the player service
        this.store.select(state => state.shuffle)
            .subscribe((response: any) => {
                this.shuffle = response.shuffle;
            });

        this.store.select(state => state.player)
            .subscribe((response: any) => {
                if (response && response.queue !== null) {
                    this.album = response.queue;
                    this.albumPlayerType = response.currentSong.type;
                    this.trackId = response.currentSong.trackId;
                    this.currentTrackIndex = this.album.tracks.findIndex(t => t._id === this.trackId);
                }
        });

    }

    /**
     * prevTrack() - find previous track by current
     * index and update Store in player queue
     */
    public prevTrack() {
        const prevTrackId = --this.currentTrackIndex;
        if (this.album.tracks[prevTrackId]) {
          const audioSrc = {
            album: this.album,
            albumId: this.album._id,
            trackId: this.album.tracks[prevTrackId]._id,
            index: prevTrackId,
            type: this.albumPlayerType,
            shuffle: this.shuffle
          };
          this.updateStorePlayerQueue(audioSrc);
        } else {
            this.resetQueue();
        }
    }

    /**
     * nextTrack() - find next track by current
     * index and update Store in player queue
     */
    public nextTrack() {
        let nextTrackId = ++this.currentTrackIndex;
        if (this.shuffle) {
            const trackCount = this.album.tracks.length;
            nextTrackId = this.getRandomIndex(1, trackCount);
        }
        if (this.album.tracks[nextTrackId]) {
          const audioSrc = {
            album: this.album,
            albumId: this.album._id,
            trackId: this.album.tracks[nextTrackId]._id,
            index: nextTrackId,
            type: this.albumPlayerType,
            shuffle: this.shuffle
          };

          // check if the next track is available
          if (this.currentTrackIndex <= this.album.tracks.length) {
            this.updateStorePlayerQueue(audioSrc);
          }
        } else {
            this.resetQueue();
        }
    }


    /**
     * resetQueue - when playing list is at the end, go back to the first song in the list
     */
    resetQueue() {
        const audioSrc = {
            album: this.album,
            albumId: this.album._id,
            trackId: this.album.tracks[0]._id,
            index: 1,
            type: this.albumPlayerType,
            shuffle: this.shuffle
        };
        this.updateStorePlayerQueue(audioSrc);
    }

    setPlayerStatus = (evt) => {
        switch (evt.type) {
            case 'playing':
                this.playerStatus.next('playing');
                break;
            case 'pause':
                this.playerStatus.next('paused');
                break;
            case 'waiting':
                this.playerStatus.next('loading');
                break;
            case 'ended':
                this.playerStatus.next('ended');
                break;
            default:
                this.playerStatus.next('paused');
                break;
        }
    }


    setNativePlayerStatus(status: number) {
        switch (status) {
            case 2:
                this.playerStatus.next('playing');
                break;
            case 3:
                this.playerStatus.next('paused');
                break;
            case 0:
                this.playerStatus.next('loading');
                break;
            case 4:
                this.playerStatus.next('ended');
                break;
            default:
                this.playerStatus.next('paused');
                break;
        }
    }

    /**
     * didUserPauseAudio - if user action clicked paused 
     * @returns bool
     */
    public didUserPauseAudio(): Observable<boolean> {
        return this._actionIsPaused.asObservable();
    }

    /**
     * getPlayerStatus
     * This method returns an Observable whose value is the current status of the player.
     * This is useful inside your component to key off certain values, for example:
     *   - Show pause button when player status is 'playing'
     *   - Show play button when player status is 'paused'
     *   - Show loading indicator when player status is 'loading'
     * See the setPlayer method for values.
     */
    public getPlayerStatus(): Observable<string> {
        return this.playerStatus.asObservable();
    }

    /**
     * setTimeRemaining()
     * This method takes the track's "duration" and "currentTime" properties to calculate the remaing time the track has
     * left to play. It is called from the calculateTime method.
     * @param d - duration
     * @param t - time
     */
    public setTimeRemaining(d: number, t: number): void {
        let remaining;
        // tslint:disable-next-line: prefer-const
        let timeLeft = d - t,
            // tslint:disable-next-line: prefer-const
            seconds = Math.floor(timeLeft % 60) || 0,
            // tslint:disable-next-line: prefer-const
            remainingSeconds = seconds < 10 ? '0' + seconds : seconds,
            // tslint:disable-next-line: prefer-const
            minutes = Math.floor((timeLeft / 60) % 60) || 0,
            // tslint:disable-next-line: prefer-const
            remainingMinutes = minutes < 10 ? '0' + minutes : minutes,
            // tslint:disable-next-line: prefer-const
            hours = Math.floor(((timeLeft / 60) / 60) % 60) || 0;

        // remaining = (hours === 0)
        if (hours === 0) {
            remaining = '-' + remainingMinutes + ':' + remainingSeconds;
        } else {
            remaining = '-' + hours + ':' + remainingMinutes + ':' + remainingSeconds;
        }
        this.timeRemaining.next(remaining);
    }


    /**
     * setPercentLoaded() - This method takes the track's "duration" and "currentTime" properties to calculate the percent of time elapsed.
     * This is valuable for setting the position of a range input. It is called from the calculatePercentLoaded method.
     * @param p - number
     */
    public setPercentLoaded(p): void {
        this.percentLoaded.next(parseInt(p, 10) || 0);
    }

    /**
     * shuffle - shuffle album list
     */
    public _shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    public getRandomIndex(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

    /**
     * This method takes the track's "duration" and "currentTime" properties to calculate the percent of time elapsed.
     * This is valuable for setting the position of a range input. It is called from the calculateTime method.
     * @param d - duration
     * @param ct - current time
     */
    public setPercentElapsed(d: number, ct: number): void {
        this.percentElapsed.next((Math.floor((100 / d) * ct)) || 0);
    }

    /**
     * This method returns an Observable whose value is the track's percent loaded
     */
    public getTimeElapsed(): Observable<string> {
        return this.timeElapsed.asObservable();
    }

    /**
     * This method returns an Observable whose value is the track's time remaining
     */
    public getTimeRemaining(): Observable<string> {
        return this.timeRemaining.asObservable();
    }

    /**
     * getMediaUrl() - formats the url of the current media file
     * @param trackUrl - url of track
     */
    public getMediaUrl(trackUrl, type = 'album') {
        if (type === 'offline') {
            return trackUrl.replace(/^file:\/\//, '');
        } else {
            return encodeURI(trackUrl);
        }
    }

    /**
     * getPercentElapsed()- This method returns an Observable whose value is the track's percent elapsed
     */
    public getPercentElapsed(): Observable<number> {
        return this.percentElapsed.asObservable();
    }

    /**
     * getAlbumName - get the album name
     * @param album - album object
     * @param trackId - the track id
     */
    public getAlbumName(album, trackId) {
        const trackName = album.tracks.find(x => x._id === trackId);
        return (trackName.artistName) ? trackName.artistName : this.album.name;
    }

    /**
     * setTimeElapsed()
     * This formats the audio's elapsed time into a human readable format, could be refactored into a Pipe.
     * It takes the audio track's "currentTime" property as an argument. It is called from the, calulateTime method.
     * @param ct - current time
     */
    public setTimeElapsed(ct: number): void {
        const seconds     = Math.floor(ct % 60);
        const   displaySecs = (seconds < 10) ? '0' + seconds : seconds;
        const minutes     = Math.floor((ct / 60) % 60);
        const displayMins = (minutes < 10) ? '0' + minutes : minutes;

        this.timeElapsed.next(displayMins + ':' + displaySecs);
    }

    /**
     * updateStorePlayerQueue() - update current queue inside store
     * @param queue - audio src obj
     */
    updateStorePlayerQueue(queue) {
        const q = {
          queue: queue.album,
          currentSong: {
            albumId: queue.albumId,
            trackId: queue.trackId,
            type: queue.type || 'album'
          },
          shuffle: queue.shuffle
        };
        this.store.dispatch({type: 'player/QUEUE', payload: q});
    }

}
