import { Action } from '@ngrx/store';

export const DOWNLOADS = 'downloads/ALBUMS';


export class OfflineManager implements Action {
    readonly type = DOWNLOADS;

    constructor(public payload: any) {}
}


// Export actions
export type Actions = OfflineManager;
