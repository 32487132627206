<ion-row *ngIf="this.album">
    <ion-col size="7">
        <div class="album-details">
            <div [class.marquee-wrapper]="this.getTrackTitle(album, trackId).length > 21">
                <div class="content">
                    <span class="title tag">{{ this.getTrackTitle(album, trackId) }}</span>
                    <span class="title tag" *ngIf="this.getTrackTitle(album, trackId).length > 21">{{ this.getTrackTitle(album, trackId) }}</span>
                </div>
            </div>
            <small class="name">{{ this.getAlbumName(album) }}</small>
        </div>
    </ion-col>
    <ion-col size="5" class="actions ion-text-right">
        <add-to-favorite [album]="album" [trackId]="trackId"></add-to-favorite>
        <add-to-playlist [album]="album" [trackId]="trackId"></add-to-playlist>
        <share-album [album]="album" [type]="albumPlayerType"></share-album>
        <ion-icon *ngIf="false" (click)="goToPurchaseLink(album)" name="cart"></ion-icon>
    </ion-col>
</ion-row>