import { Component, OnInit, Input } from '@angular/core';

// Routing
import { Router } from '@angular/router';
@Component({
  selector: 'app-content-blog-posts',
  templateUrl: './blog-posts.html',
  styleUrls: ['./blog-posts.scss']
})
export class ContentBlogPostsComponent implements OnInit {
    @Input() posts: any[] = [];

    constructor(private router: Router) {}
    ngOnInit() {}

    cleanUrl(url) {
        const clean = encodeURI(url);
        return clean;
    }

    goToPage(slug) {
        this.router.navigate([`/blog/${slug}`]);
    }

}
