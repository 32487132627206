<ion-header no-border [style.--custombg]="artworkColors">
  <ion-toolbar>
    <ion-buttons slot="start"><ion-back-button></ion-back-button></ion-buttons>
    <ion-title *ngIf="albumDetails">
      <ion-icon *ngIf="albumDetails.status == 'private'" name="lock-closed"></ion-icon>
      {{ albumDetails.title }}
      <ng-container  *ngIf="albumDetails.status == 'private'">
        <br/>
        <small>PRIVATE</small>
      </ng-container>
    </ion-title>
    <ion-buttons slot="end">
      <add-to-favorite class="actions" *ngIf="albumDetails" [album]="albumDetails" [favorite_type]="'album'"></add-to-favorite>
      <album-details class="actions" [album]="albumDetails" type="'album'" [showPlaylist]="false" [showFavorite]="false"></album-details>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content fullsreen scrollEvents="true" scrollX="false" [appImageFade]="cover" [appImageHeight]="main" [style.--custombg]="artworkColors">
  <ng-container *ngIf="loading">
    <div class="loading">
      <ion-spinner name="dots" color="primary"></ion-spinner>
    </div>
  </ng-container>
  <div #cover class="image-box">
    <img *ngIf="albumDetails?.artwork" (load)="imageLoaded($event)" class="album-artwork" src="{{albumDetails?.artwork}}?resize=500,500&quality=100"/>
  </div>
  <div #main class="main ion-padding" [style.margin-top]="adjustedHeight">
    <ng-container *ngIf="!loading && albumDetails && albumFound.status; else default">
      <album-header [album]="albumDetails" [showHeader]="false" [type]="'album'" [user]="profile" (navigateTo)="goToPage($event)"></album-header>
      <tracklisting *ngIf="albumDetails.tracks.length > 1" [album]="albumDetails" [type]="'album'"></tracklisting>
        <ng-container *ngIf="userAlbums && userAlbums.length > 0">
          <div class="item-title">More music 
            <ion-button (click)="goToProfile()" class="action" color="dark" size="small">View all</ion-button></div>
          <scroll-albums [albums]="userAlbums" [type]="'album'" [pushToStack]="true"></scroll-albums>
          <br/>
        </ng-container>
        <ng-container *ngIf="relatedAlbums && relatedAlbums.length > 0">
          <div class="item-title">Related to {{ (profile?.displayName) ? profile.displayName : profile?.username }} 
            <ion-button *ngIf="relatedAlbums.length > 5" (click)="goToUserRelatedAlbums()" class="action" color="dark" size="small">View all</ion-button>
          </div>
          <scroll-albums [albums]="relatedAlbums" [type]="'album'" [pushToStack]="true"></scroll-albums>
          <br/>
        </ng-container>
        <ng-container *ngIf="pageContentRelated && pageContentRelated.length > 0">
          <div class="item-title">Editorials</div>
          <app-content-blog-posts [posts]="pageContentRelated"></app-content-blog-posts>
        </ng-container>
    </ng-container>

    <ng-template #default>
      <app-empty [icon]="albumFound.icon" 
          [title]="albumFound.title"
          [description]="albumFound.description"
          [buttonText]="'Search'"
          [route]="'/search'">
      </app-empty>
    </ng-template>
  </div>
</ion-content>