<ion-slides #slides loop="false" *ngIf="album" class="swiper-container ion-no-padding" [options]="slideOpts" style="width: 100%;"  (ionSlideDidChange)="swipePlayTrack()">
    <ng-container *ngIf="albumPlayerType == 'album'">
        <ion-slide *ngFor="let tracks of album.tracks" class="ion-no-padding">
            <img *ngIf="album && album.artwork" class="artwork" src="{{cleanUrl(album.artwork)}}"/>
        </ion-slide>
    </ng-container>
    <ng-container *ngIf="albumPlayerType == 'podcast'">
        <ion-slide *ngFor="let tracks of album.tracks" class="ion-no-padding">
            <img *ngIf="album && album.artwork" class="artwork" src="{{cleanUrl(album.artwork)}}"/>
        </ion-slide>
    </ng-container>
    <ng-container *ngIf="albumPlayerType == 'playlist'">
        <ion-slide *ngFor="let tracks of album.tracks">
            <img *ngIf="album && tracks.artwork" class="artwork" src="{{cleanUrl(tracks.artwork)}}"/>
        </ion-slide>
    </ng-container>
    <ng-container *ngIf="albumPlayerType == 'offline'">
        <ion-slide *ngFor="let tracks of album.tracks">
            <img *ngIf="album && album.artwork" class="artwork" [src]="getImageUrl(album.artwork)"/>
        </ion-slide>
    </ng-container>
</ion-slides>