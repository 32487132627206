<ng-container *ngIf="albums">
    <div class="featured-content" [style.backgroundImage]="'url(' + cleanUrl(albums.artwork) + '?resize=500,500&quality=90)'">
        <div class="album-content">
            <div class="album-text">
                <span *ngIf="featured" class="new">Featured</span><br/><br/>
                    <div>
                        <strong class="title">{{albums.name}}</strong><br/>
                        <span class="artist-name" *ngIf="albums.title">{{albums.title}}</span>
                    </div>
            </div>
        </div>
    </div>
</ng-container>