import { Injectable } from '@angular/core';
import { ApiPlatformService } from '../platform.service';

@Injectable()
export class EmailService {
   constructor(
      private api: ApiPlatformService
   ) { }

   dmca(body) {
      return this.api.post('/support/report', body);
   }

   support(body) {
      return this.api.post('/support/general', body);
   }
}
