export const APPCONFIG = {
    apiEndpoint: 'https://api-vuulm.herokuapp.com',
    storageConfig: {
        name: 'vuulm.db',
        location: 'default'
    },
    ionicConfig: {
        tabsPlacement: 'bottom',
        pageTransition: 'ios-transition',
        backButtonText: '',
        tabsHideOnSubPages: false
    },
    firebaseConfig: {
        projectId: 'vuulm-1517524045543',
        apiKey: 'AIzaSyAVTJhSeJN19putNOU7OO7uBw9EpJ225Ng',
        authDomain: 'vuulm-1517524045543.firebaseapp.com',
        databaseURL: 'vuulm-1517524045543.firebaseio.com',
        storageBucket: 'vuulm-1517524045543.appspot.com',
        messagingSenderId: '1022957794790',
       // timestampsInSnapshots: true
    },
    sentry: {
        key: 'https://d484cbf0858b4e34a2d4b5ee614d258d@o362341.ingest.sentry.io/4178359',
        environment: 'prod'
    },
    onsignal: {
        ios: '4df9653d-c22f-4b07-bffc-1d9940a86d6e',
        android: '299945417702'
    },
    applovin: {
        sdkKey: 'gQ2sBesoIM8WKh_qG0Lf0dmbow4wJgX_BXgqiMUxg8gTWpkBKkgcafm6CsLxAHQzt3zFPkU9fHW7Ks-wcE2NpS',
        interstitial: {
            ios: 'f3a8334dd762c922',
            android: 'abec4d119c4ca2ee'
        }
    },
    mopub: {
        ios: {
            interstitial: '7b7208778a08471c9284994c1905786c', // LIVE
            banner: '07f0633507da4d7cb71a7ac85b9557d3'
            // interstitial: '4f117153f5c24fa6a3a92b818a5eb630', // testing
            // banner: '0ac59b0996d947309c33f59d6676399f' // testing
        },
        android: {
            interstitial: '86883d31058948d5a4bde00105a40a8f', // LIVE
            banner: 'e4140246133e428b861c38860410f762' // LIVE
        }
    },
    firebaseServiceAccount: {
        type: 'service_account',
        project_id: 'vuulm-5f497',
        private_key_id: '314590a59bd9c07773c0c2dfc98097609c436331',
        private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDY7VGQnsA1Q5gO\n59X+FH90hjkV5KHxzAyi4WoiyjAcyZ8FwzkylBRVGZsS/U6rfKXNN/ieCdLqnGqX\n1N/Rfand8vq9QkQv761P9OVia8lM2fFy7rVsHDq3DIZE2h1NR9/Fmi3DrXi8h0f/\nvoOI+MtZMOO2uLlPrHb12FjZF6DH3JjXO73bvvoQNV5A/YK1QO1k20hqGIjHPC+7\nUZh3ktm63bkH+7l9mDB8QKl71D/Y8JH36+GomSf85QjhNP7mJcWdd0z8YCRsLd+F\nH9jcmnHkE2JXFV2Ifykvi9m1iOkT07dtWOMEdsUnigY0jW0eO7uhlA6o+yrCmvEf\ntftpz2Y1AgMBAAECggEAFFLHnYECCPt4N41I4zEQb95TYtW0xm6TkH8F7pR6xXeu\nSl/r7+0nqoFnofPRdweKkRt5U3UgKZLV+bub7IiVbZHiI/EdalBOMHuFMh9fELAq\nitYKcUKDzbqCqdi1fhQJxv2W5ApQkXkrKa6zrBWDF6wY6XmNAwXpb0cBq/UmvxKj\npvcssd6rHV4g6SicX+sg0+kRRoYQYnu5XYlTGMoYYgEkBuXEMv7ZICcRIJvIPqX5\nFjZmtJsPamfQfQ97FyEZ8dVUFWNH6UOYi4NSxh2Wc/esUytNKWU1G/ssikWkYoc+\nhgI1n7kJChlHd+6MVFFvpnk5cFcvni4QBAc1ahQ50QKBgQDsrMQ9F0QW6HPQHe0K\nhWRYSnwBybNvnqbzzI+y/91XIKQVJHodMeH/0P+mdZih5neeMINKrJvcpUcalFpb\ns+TiOhsZuhmY1g5Q3xc2fGDTiexr1Y611f5gqpPoGJIMbvrgT2QXGnR3OhOFRfzN\nVDpJxzVFWCoI6gUw0ChFGQvh0wKBgQDqo8LmSkKknPJ9/U9Hoh+YT9P4F6qwZp5p\nvkfKXKRIj3zuxOlh5IR8bTSd+NpZ9+Iyz+95AZYPPfczpGmOvatpr8YFyGOtQJaP\nL6D4tJkoNd+SJ9EF5XAAVu0/monYzQ4qHm0Oiyt3Uu6u6U+SKSkjeO/ibvopII/S\nkaD/eeOK1wKBgD4mmFnalDzXyiHq2eabZOBynuHxT7eOGBWJsGq3Gi7ZGX2b2J12\nDf0ZqyjRVMWfOeZGoxOnBD8LAAMQ1jGiqgZmb9tvrW0fOR8vlm7udpbWb0laCWHi\n0iwcXz7hxOiS9DqXXpdakR8GFz42RM6+wZfCQlD1eARjP/2zi/+pNh+VAoGAOE6K\nI9doPLaJHqDeGJOYP2XQuTV8uY42r6uA8NfgeKUuVuIcG2jKPk26lbo1M+ffeVAa\n6I/v4cJ+Av+OcTmk29zZiWO4P8hDGSNV3rkAE/0xpJI3f9G6WeqCToQYVlWG86aP\nhzDwglb9fi3w8WM9QwzvjAgTsjDlMqPN3eDzMMsCgYByK36ALJBjn9TitKhCFIHm\nJyqCoO8mUNQXlCXYs5aJ2i8/Gt4BD9Wpb1m3zbLnf3BM0zgiXgc3al/RdM/xVBk0\nSAcZkoyDFUQknO+vxjyidIvSIiA/819/BmnuPWSmBAOgyKrfh48ho9Uo7EcdpDYo\nVXBOiQNduphi65TLwEKfGA==\n-----END PRIVATE KEY-----\n',
        client_email: 'firebase-adminsdk-p7ac6@vuulm-5f497.iam.gserviceaccount.com',
        client_id: '109006408728444220810',
        auth_uri: 'https://accounts.google.com/o/oauth2/auth',
        token_uri: 'https://oauth2.googleapis.com/token',
        auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
        client_x509_cert_url: 'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-p7ac6%40vuulm-5f497.iam.gserviceaccount.com'
    },
    googlePlusClientConfig: {
        webClientId: '299945417702-njqu3tm8v040r51ricmjuq239mo3s7ri.apps.googleusercontent.com',
        offline: true,
        scopes: 'profile email'
    },
    appRateLocation: {
        displayAppName: 'Vuulm',
        usesUntilPrompt: 8,
        storeAppURL: {
            ios: 'id1387484665',
            android: 'market://details?id=vuulm.mobile.app'
        },
        promptAgainForEachNewVersion: false,
        customLocale: {
            title: 'Do you enjoy %@?',
            message: 'If you enjoy using %@, would you mind taking a moment to rate it?',
            cancelButtonLabel: 'No, Thanks',
            laterButtonLabel: 'Remind Me Later',
            rateButtonLabel: 'Rate It Now'
          },
        callbacks: {},
        simpleMode: true
    },
    appsFlyer: {
        devKey: '53Zph2A25LEVZmZTjk5wj3', // your AppsFlyer devKey
        isDebug: false,
        appId: '1387484665', // your ios appID
        waitForATTUserAuthorization: 10, // time for the sdk to wait before launch - IOS 14 ONLY!
    }
};
