import { Component, OnInit } from '@angular/core';
import { IonNav, NavParams, ModalController } from '@ionic/angular';

// Routing
import { ActivatedRoute } from '@angular/router';
import {  PodcastService, SeoService, UserDetailService } from '@vuulm/core';

// Components
import { ProfileComponent } from '../../profile/profile.component';
import { PodcastsInfoComponent } from '../info/info.component';

// Store
import { Store } from '@ngrx/store';
import { AppState } from '@vuulm/core';

@Component({
  selector: 'app-landing-podcast-detail',
  template: '<ion-nav [root]="rootPage" [swipeGesture]="true"></ion-nav>'
})
export class LandingPodcastDetailComponent {
  public rootPage = PodcastDetailComponent;
}


@Component({
  selector: 'app-podcast-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class PodcastDetailComponent implements OnInit {

  public artworkColors: any = "rgba(0, 0, 0, 30%)";
  public podcastDetails: any;
  public profile: any;
  public trackPlaying: any;

  constructor(
    private route: ActivatedRoute,
    private podcastService: PodcastService,
    private ionNavLink: IonNav,
    private userDetailService: UserDetailService,
    public navParams: NavParams,
    readonly store: Store<AppState>,
    public modal: ModalController,
  ) { 
    this.store.select(state => state.player)
      .subscribe((response: any) => {
          if (response) {
            this.trackPlaying = response.currentSong.trackId;
          }
      });
  }

  ngOnInit(): void {
    if (this.navParams.get('podcastid')) {
      const podcastId = this.navParams.get('podcastid');
      this.getPodcastDetails(podcastId);
    } else {
      this.route.params.subscribe((params) => {
        // tslint:disable-next-line: no-string-literal
        const podcastId = params['podcastid'];
        this.getPodcastDetails(podcastId);
      });
    }
  }

  getPodcastDetails(podcastId) {
    this.podcastService.getEpisodes(podcastId)
      .subscribe((podcast) => {
        if (podcast) {
          this.podcastDetails = podcast;
          this.getArtworkColors(this.podcastDetails._id);
          this.getUserDetails(this.podcastDetails.uid);
        }
      })
  }

  playSong(album, trackid, type = 'podcast') {
    const audioSrc = {
      queue: album,
      currentSong: {
        albumId: album._id,
        trackId: trackid,
        type: `${type}`
      }
    };
    this.play(audioSrc);
  }

  play(audioSrc) {
    this.store.dispatch({type: 'player/QUEUE', payload: audioSrc});
  }

  /**
     * getArtworkColors - get color scheme for album
     * @param albumId
     */
  async getArtworkColors(podcastId) {
    await this.podcastService.colors(podcastId)
      .subscribe((r: any) => {
        if (r) {
          this.artworkColors = `rgba(${r.value[0]}, ${r.value[1]}, ${r.value[2]}, 10%)`;
        }
      })
  }

  goToArtist(value) {
    this.ionNavLink.push(ProfileComponent, { userid: value.userid});
  }

  /**
   * getUserDetails() - get user information
   * @param userid 
   * @returns 
   */
  getUserDetails(userid) {
    if (!userid) { 
      return false;
    }
    this.userDetailService
      .get(userid)
      .subscribe((r: any) => {
          if (r) {
              this.profile = r;
          }
      });
  }

  async presentModal(episodeId) {
    const modal = await this.modal.create({
      component: PodcastsInfoComponent,
      animated: true,
      initialBreakpoint: 1,
      breakpoints: [0, 0, 1],
      componentProps: {
        episode: this.podcastDetails.tracks.filter(x => x._id == episodeId)[0],
      }
    });
    return await modal.present();
  }
}
