import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { PipeModule } from '@vuulm/core';

// Components
import { AlbumDetailSheetComponent } from './album-details/album-details';
import { AlbumDetailActionSheetComponent } from './album-details/album-actionsheet/album-actionsheet';
import { AddPlaylistPage } from './add-playlist/add-playlist.component';
import { AddToFavoriteComponent } from './add-to-favorite/favorite';
import { AddToPlaylistComponent } from './add-to-playlist/add-to-playlist';
import { BackComponent } from './back/back';
import { FollowButtonComponent } from './follow/follow';
import { ShareComponent } from './share/share';
import { DownloadButtonComponent } from './download/download';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    PipeModule,
  ],
  declarations: [
    AddPlaylistPage,
    AddToFavoriteComponent,
    AddToPlaylistComponent,
    AlbumDetailSheetComponent,
    AlbumDetailActionSheetComponent,
    BackComponent,
    FollowButtonComponent,
    ShareComponent,
    DownloadButtonComponent
  ],
  entryComponents: [
    AddPlaylistPage,
    AlbumDetailActionSheetComponent,
    AlbumDetailSheetComponent
   ],
  exports: [
    PipeModule,
    AddPlaylistPage,
    AlbumDetailSheetComponent,
    AlbumDetailActionSheetComponent,
    AddToFavoriteComponent,
    AddToPlaylistComponent,
    BackComponent,
    FollowButtonComponent,
    ShareComponent,
    DownloadButtonComponent
  ],
  providers: [ ]
})

export class SharedActionsModule {	}
