import { Action } from '@ngrx/store';

// Actions
import * as OfflineActions from '../actions/offline.action';


// Define initial state
const initialState = {};

export function offlineManagerReducer(state = initialState, action: OfflineActions.Actions) {
    switch (action.type) {
        case OfflineActions.DOWNLOADS:
            return {
                ...state,
                downloads: action.payload
            };

        default:
            return state;

    }
}
