<ion-header translucent="true">
    <ion-toolbar>
        <ion-title>Episode Info</ion-title>
        <ion-buttons slot="end" (click)="dismiss()">
            <ion-icon name="close" class="close-btn"></ion-icon>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="title"><h2>{{episode.title}}</h2></div>
    <div class="info" [innerHtml]="episode.description"></div>
    <div class="date" *ngIf="episode.datePublished">
        <em>{{ episode.datePublished }}</em>
    </div>
</ion-content>
<ion-footer>&nbsp;</ion-footer>