import { Component } from '@angular/core';
import { NavParams, ModalController, AlertController, ToastController } from '@ionic/angular';

// Services
import { UserPlaylistsService } from '@vuulm/core';

@Component({
  selector: 'add-playlist-page',
  templateUrl: 'add-playlist.component.html',
  styleUrls: ['./add-playlist.component.scss'],
  providers: [ UserPlaylistsService ]
})
export class AddPlaylistPage {
  public playlists;
  public albumId;
  public trackId;

  constructor(
    public modal: ModalController,
    private userPlaylists: UserPlaylistsService,
    public alertCtrl: AlertController,
    private toastCtrl: ToastController,
    public navParams: NavParams,
  ) {
    this.trackId = navParams.get('trackId');
    this.albumId = navParams.get('albumId');
    this.getPlaylists();
  }

  getPlaylists() {
    this.userPlaylists.get()
      .subscribe((r: any) => {
        if (r) {
          this.playlists = r;
        }
      });
  }

  async showPrompt() {
    const prompt = await this.alertCtrl.create({
      header: 'Create playlist',
      message: 'Enter a name for this new playlist',
      inputs: [
        {
          name: 'title',
          placeholder: 'Title'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Save',
          handler: data => {
            this.createPlaylist(data);
          }
        }
      ]
    });
    prompt.present();
  }

  addToPlaylist(id) {
    const album = {
      albumId: this.albumId,
      trackId: this.trackId
    };
    this.userPlaylists.tracks(id, album)
      .subscribe((r: any) => {
        // show a toast
        this.showToaster('Saved! This is now added to your playlist');
        this.dismiss();
      }, (error: any) => {
        this.showToaster('Sorry, there was an error. Please try again.');
      });
  }

  createPlaylist(data) {
    const createPlaylist = {
      title: data.title
    };
    this.userPlaylists.post(createPlaylist)
      .subscribe((r: any) => {
        this.getPlaylists();
      }, (error: any) => {
        this.showToaster('Sorry, there was an error. Please try again.');
      });
  }

  trimName(title) {
    if (title) {
      return title.charAt(0).toUpperCase();
    } else {
      return 'P';
    }
  }

  async showToaster(message) {
    const toast = await this.toastCtrl.create({
      message: `${message}`,
      duration: 3000
    });
    toast.present();
  }

  dismiss() {
    this.modal.dismiss({
      dismissed: true
    });
  }
}
