import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColoredBrowserTabs } from '@vuulm/plugins/colored-browser-tabs/ngx';

// Components
import { LoginComponent } from './login.component';
@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule, ReactiveFormsModule,
  ],
  declarations: [
    LoginComponent
  ],
  entryComponents: [
    LoginComponent
   ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    LoginComponent
  ],
  providers: [
    ColoredBrowserTabs
  ]
})

export class SharedLoginModule {	}
